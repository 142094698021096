// src/url/index.ts
var TokenType = /* @__PURE__ */ ((TokenType2) => {
  TokenType2["Pattern"] = "PATTERN";
  TokenType2["Param"] = "PARAM";
  return TokenType2;
})(TokenType || {});
var { Pattern, Param } = TokenType;
var tokensToRegexPattern = (tokens, options = {}) => {
  const { deliminator = "/", encode = (x) => x } = options;
  const segments = tokens.map(
    (token) => token.type === Pattern ? encode(token.value) : "([^/]+)"
  );
  return ["^", ...segments].join(deliminator);
};
var createPatternToken = (value) => ({
  type: Pattern,
  value
});
var createParamToken = (value) => ({
  type: Param,
  value
});
var parse = (path, options = {}) => {
  const {
    pathDeliminator = "/",
    paramDeliminator = ":",
    groupDeliminatorStart = "(",
    groupDeliminatorEnd = ")",
    urlParamPattern = "[a-zA-Z][a-zA-Z0-9_]*"
  } = options;
  const pathSegments = path.slice(1).split(pathDeliminator);
  const urlParamRegex = new RegExp(
    ["^", paramDeliminator, urlParamPattern, "$"].join("")
  );
  return pathSegments.map((segment) => {
    const hasGroup = segment.includes(groupDeliminatorStart);
    const hasParam = segment.includes(paramDeliminator);
    if (hasParam && !urlParamRegex.test(segment)) {
      throw new Error("Invalid URL Parameters defined");
    }
    if (hasGroup && ["?", "\\"].some((p) => segment.includes(p))) {
      throw new Error("Invalid Group URL defined");
    }
    const pattern = hasGroup ? segment.replace(groupDeliminatorStart, "(?:").replace(groupDeliminatorEnd, ")") : segment;
    return hasParam ? createParamToken(segment.slice(1)) : createPatternToken(pattern);
  });
};
var createPathMatch = (pathname) => (path, options = {}) => {
  const { exact = false } = options;
  const tokens = parse(path);
  const paramTokens = tokens.filter((token) => token.type === Param);
  const pathPattern = [
    tokensToRegexPattern(tokens),
    exact ? "$" : ""
  ].join("");
  const pathRegex = new RegExp(pathPattern);
  const result = pathRegex.exec(pathname);
  const params = paramTokens.length && result?.length ? paramTokens.reduce((params2, token, idx) => {
    const param = result[idx + 1];
    return {
      ...params2,
      [token.value]: param ? decodeURIComponent(param) : param
    };
  }, {}) : {};
  const matched = Boolean(result);
  return { params, matched, path: pathPattern, pathname };
};
var dedupSlash = (path) => path.replace(/\/{2,}/, "/");
var deTrailingSlash = (path) => path.replace(/(.+)\/$/, "$1");
var joinPath = (path, base) => deTrailingSlash(dedupSlash([base, path].join("/")));
var buildUrl = (href, {
  state,
  title = document.title,
  base = "",
  location = window.location
} = {}) => {
  const url = new URL(href, location.href);
  if (base) {
    url.pathname = joinPath(url.pathname, base);
  }
  const urlProps = {
    // Remove ":" from URL.protocol
    protocol: url.protocol.slice(0, -1),
    host: url.hostname,
    // Convert the port into number if there is port
    port: url.port ? parseInt(url.port, 10) : void 0,
    path: url.pathname,
    // Remove "?" from URL.search
    query: url.search.slice(1),
    // Remove "#" from URL.hash
    fragment: url.hash.slice(1),
    state: { title, ...state }
  };
  const port = urlProps.port ? `:${urlProps.port}` : "";
  const toOrigin = () => `${urlProps.protocol}://${urlProps.host}${port}`;
  const isInternal = () => location.origin === toOrigin();
  const isExternal = () => !isInternal();
  const toString = () => url.href;
  const query = urlProps.query ? `?${urlProps.query}` : urlProps.query;
  const fragment = urlProps.fragment ? `#${urlProps.fragment}` : urlProps.fragment;
  const toInternalHref = () => [urlProps.path, query, fragment].join("");
  return {
    ...urlProps,
    toOrigin,
    isInternal,
    isExternal,
    toString,
    toInternalHref
  };
};
var isUrl = (url) => !!(typeof url === "object" && url && "host" in url);
var toUrl = (url, options) => isUrl(url) ? url : buildUrl(url, options);
var isInternalUrl = (url) => {
  return toUrl(url).isInternal();
};

// src/history.ts
var createHistory = (onRequestToChangeUrl, history = window.history, location = window.location) => {
  const back = () => {
    history.back();
  };
  const forward = () => {
    history.forward();
  };
  const refresh = () => {
    history.go();
  };
  const push = (urlOrLink, options) => {
    const url = toUrl(urlOrLink, options);
    onRequestToChangeUrl(url);
    if (url.isInternal()) {
      history.pushState(url.state, "", url.toInternalHref());
    } else {
      const href = url.toString();
      location.href = href;
    }
  };
  const replace = (urlOrLink, options) => {
    const url = toUrl(urlOrLink, options);
    onRequestToChangeUrl(url);
    if (url.isInternal()) {
      history.replaceState(url.state, "", url.toInternalHref());
    } else {
      const href = url.toString();
      location.replace(href);
    }
  };
  const replaceState = (key, value) => {
    const newState = history.state?.[key] !== void 0 ? Object.keys(history.state).reduce((prev, next) => {
      if (next === key) {
        return {
          ...prev,
          [key]: value
        };
      }
      return prev;
    }, {}) : history.state;
    const url = buildUrl(location.href, { state: newState });
    replace(url);
  };
  return {
    get state() {
      return history.state;
    },
    back,
    forward,
    refresh,
    push,
    replace,
    replaceState
  };
};

// src/hooks/router.ts
import React, { useMemo, useLayoutEffect, useState } from "react";

// src/matcher.ts
import { isEmpty as isEmpty2 } from "@pexip/utils";

// src/utils.ts
import { isEmpty } from "@pexip/utils";
var depthFirstSearch = (nodes, discover) => {
  if (isEmpty(nodes)) {
    return;
  }
  for (const node of nodes) {
    const found = discover(node);
    if (found) {
      return node;
    }
    if (!isEmpty(node.children)) {
      const found2 = depthFirstSearch(node.children, discover);
      if (found2) {
        return found2;
      }
    }
  }
};
function formatPath(data, ...interpolations) {
  const out = [];
  for (let i = 0; i < data.length; i++) {
    out.push(data[i], encodeURIComponent(interpolations[i] ?? ""));
  }
  return out.join("");
}

// src/matcher.ts
var completeRoutePaths = (routes, basePath = "") => {
  return routes.map((route) => {
    if (route.fallback) {
      return route;
    }
    const path = basePath && route.path ? joinPath(route.path, basePath) : route.path;
    return {
      ...route,
      path,
      children: route.children && completeRoutePaths(route.children, path)
    };
  });
};
var matchRoutes = (routes, url) => {
  const parsedUrl = toUrl(url);
  const matchPath = createPathMatch(deTrailingSlash(parsedUrl.path));
  const fallbackRoute = routes.find((route) => route.fallback);
  const matchedRoutes = routes.flatMap((route) => {
    if (route.fallback) {
      return [];
    }
    const { params, matched } = matchPath(route.path, {
      exact: Boolean(route.exact)
    });
    if (!matched) {
      return [];
    }
    const isExact = matchPath(route.path, { exact: true }).matched;
    const match = {
      url: parsedUrl,
      params,
      exact: isExact,
      path: route.path,
      fallback: false
    };
    if (isExact || !route.children || !route.children.length) {
      return [{ route, match }];
    }
    return [
      {
        route,
        match,
        children: matchRoutes(route.children, url)
      }
    ];
  });
  if (matchedRoutes.length || !fallbackRoute) {
    return matchedRoutes;
  }
  return [
    {
      route: fallbackRoute,
      match: {
        url: parsedUrl,
        fallback: true,
        params: {},
        exact: false,
        path: fallbackRoute.path
      }
    }
  ];
};
var createRouteElementCreator = (createElement) => {
  const createElements = (routes) => {
    if (!routes.length) {
      return [];
    }
    const matchedElements = routes.map((route) => {
      if (!route.children?.length) {
        return createElement(route);
      }
      return createElement(route, createElements(route.children));
    });
    return matchedElements;
  };
  return createElements;
};
var findExactMatched = (matchedRoutes) => {
  return depthFirstSearch(
    matchedRoutes,
    (route) => route.match.exact
  );
};
var getMatchedSubroutes = (matchedRoutes, getSubroutes) => {
  if (isEmpty2(matchedRoutes)) {
    return [];
  }
  return matchedRoutes.flatMap((matched) => {
    const subs = getSubroutes(matched.route.path);
    return [
      ...subs,
      ...getMatchedSubroutes(matched.children ?? [], getSubroutes)
    ];
  });
};
var matchRoutesWithSubroutes = (getSubroutes) => (
  /**
   * Matching main routes based on the provided url
   *
   * @param mainRoutes - A list of routes to look for
   * @param url - An url to match
   */
  (mainRoutes, url) => {
    const mainMatched = matchRoutes(mainRoutes, url);
    if (findExactMatched(mainMatched)) {
      return mainMatched;
    }
    const subroutes = getMatchedSubroutes(mainMatched, getSubroutes);
    if (isEmpty2(subroutes)) {
      return mainMatched;
    }
    const someMatched = subroutes.some((routes) => {
      const matched = matchRoutes(routes, url);
      return !!findExactMatched(matched);
    });
    const fallbackRoute = mainRoutes.find((route) => route.fallback);
    if (someMatched || !fallbackRoute) {
      return mainMatched;
    }
    return matchRoutes([fallbackRoute], url);
  }
);

// src/hooks/router.ts
var createRouteElements = createRouteElementCreator((route, children) => {
  return React.createElement(
    route.route.node,
    { key: route.route.path, match: route.match },
    children
  );
});
var createRouterHooks = (router) => {
  const props = {
    routesList: [],
    subroutesMap: {}
  };
  const useRouter = () => {
    useLayoutEffect(() => {
      const handlePopstate = (event) => {
        const href = router.currentHref;
        const url = buildUrl(href, {
          state: event.state
        });
        router.urlChangedSignal.emit(url);
      };
      addEventListener("popstate", handlePopstate);
      return () => {
        removeEventListener("popstate", handlePopstate);
      };
    }, []);
    useLayoutEffect(() => {
      const handleLoad = () => {
        const href = router.currentHref;
        const url = buildUrl(href, {
          state: router.history.state
        });
        router.urlChangedSignal.emit(url);
      };
      addEventListener("load", handleLoad);
      return () => {
        removeEventListener("load", handleLoad);
      };
    }, []);
  };
  const createRoutesHook = (routes, match = matchRoutes) => {
    return (currentUrl = buildUrl(router.currentHref, {
      state: router.history.state
    })) => {
      const [url, setUrl] = useState(currentUrl);
      useLayoutEffect(() => {
        const handleUrlRequest = (urlRequest) => {
          if (isInternalUrl(urlRequest)) {
            setUrl(urlRequest);
          }
        };
        const removeUrlChangedSignal = router.urlChangedSignal.add(setUrl);
        const removeUrlRequestSignal = router.urlRequestSignal.add(handleUrlRequest);
        return () => {
          removeUrlChangedSignal();
          removeUrlRequestSignal();
        };
      }, [setUrl]);
      const matchedRoutes = useMemo(() => match(routes, url), [url]);
      const elements = useMemo(
        () => createRouteElements(matchedRoutes),
        [matchedRoutes]
      );
      return elements;
    };
  };
  const createMainRoutesHook = (partialRoutes) => {
    const routes = completeRoutePaths(partialRoutes, router.baseUri);
    props.routesList.push(routes);
    const match = matchRoutesWithSubroutes(
      (parentPath) => props.subroutesMap[parentPath] ?? []
    );
    return createRoutesHook(routes, match);
  };
  const createSubRoutesHook = (partialSubRoutes, parentPath) => {
    const keyPath = joinPath(parentPath, router.baseUri);
    const subroutes = completeRoutePaths(partialSubRoutes, keyPath);
    if (!props.subroutesMap[keyPath]) {
      props.subroutesMap[keyPath] = [subroutes];
    } else {
      props.subroutesMap[keyPath]?.push(subroutes);
    }
    return createRoutesHook(subroutes);
  };
  return {
    useRouter,
    createRoutesHook: createMainRoutesHook,
    createSubRoutesHook
  };
};

// src/router.ts
import { createSignal } from "@pexip/signal";

// src/location.ts
var getCurrentHref = (noTrailingSlash = true, location = window.location) => noTrailingSlash ? deTrailingSlash(location.href) : location.href;

// src/router.ts
var createRouter = ({
  getCurrentHref: getCurrentHref2 = getCurrentHref,
  baseUri = "/",
  ...options
} = {}) => {
  const urlRequestSignal = options.urlRequestSignal ?? createSignal({
    name: "router:urlRequest"
  });
  const urlChangedSignal = options.urlChangedSignal ?? createSignal({
    name: "router:urlChanged"
  });
  const history = createHistory(urlRequestSignal.emit);
  return {
    get currentHref() {
      return getCurrentHref2();
    },
    get baseUri() {
      return baseUri;
    },
    urlChangedSignal,
    urlRequestSignal,
    history
  };
};
export {
  TokenType,
  buildUrl,
  createHistory,
  createPathMatch,
  createRouter,
  createRouterHooks,
  deTrailingSlash,
  dedupSlash,
  formatPath,
  isInternalUrl,
  isUrl,
  joinPath,
  parse,
  toUrl,
  tokensToRegexPattern
};

import cx from 'classnames';
import React from 'react';

import {InMeetingDraggable, isPhone} from '@pexip/media-components';
import {useWideContainerObserver} from '@pexip/hooks';

import {StreamStatus} from '../../types';

import type {MeetingVideoProps} from './MeetingVideo.view';
import {MeetingVideo} from './MeetingVideo.view';

import styles from './MeetingVideo.module.scss';

export const DraggableMeetingVideo: React.FC<
    Omit<MeetingVideoProps, 'isContainerWide'> & {
        isSidePanelVisible: boolean;
        floatRoot?: React.RefObject<HTMLDivElement>;
    }
> = ({isSidePanelVisible, floatRoot, ...props}) => {
    const {containerRef, isContainerWide} = useWideContainerObserver();

    const isPip = props.status === StreamStatus.Pip;
    const isBrowserPip = props.status === StreamStatus.BrowserPip;
    const isDeemphasized = props.status === StreamStatus.Deemphasized;
    const isEmphasized = props.status === StreamStatus.Emphasized;

    return (
        <InMeetingDraggable
            isDisabled={!isPip}
            isSidePanelVisible={isSidePanelVisible}
            isSidePanelTransformEnabled={isPip}
            floatRoot={floatRoot}
            center={true}
            className={cx(styles.container, {
                [styles.pip]: isPip,
                [styles.emphasized]: isEmphasized,
                [styles.deemphasized]: isDeemphasized,
                [styles.browserPip]: isBrowserPip,
                ['m-0']: isPhone && !isPip,
            })}
            ref={containerRef}
        >
            <MeetingVideo {...props} isContainerWide={isContainerWide} />
        </InMeetingDraggable>
    );
};

export type DraggableMeetingVideoProps = React.ComponentProps<
    typeof DraggableMeetingVideo
>;

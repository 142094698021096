// src/ConfigManager.ts
import { createSignal } from "@pexip/signal";

// src/types.ts
var isString = (t) => typeof t === "string";
var isBoolean = (t) => typeof t === "boolean";
var isNumber = (t) => typeof t === "number";
var isArray = (t) => Array.isArray(t) && t.every(isString);
var isValue = (t) => [isString, isBoolean, isNumber, isArray].some((fn) => fn(t));
var isRecord = (t) => typeof t === "object" && t !== null && !(t instanceof Map) && !(t instanceof Set) && !Array.isArray(t) && Object.values(t).every(isValue);
var isValid = (t) => [isValue, isRecord].some((fn) => fn(t));
var getTypeOf = (t) => {
  if (t instanceof Map) {
    return "Map";
  }
  if (t instanceof Set) {
    return "Set";
  }
  if (t instanceof Function) {
    return "Function";
  }
  if (t instanceof Object) {
    return getTypeOf(Object.values(t).filter((v) => !isValid(v))[0]);
  }
  return typeof t;
};

// src/localStorage.ts
var SUPPORTED = ((key, value) => {
  try {
    localStorage.setItem(key, value);
    if (localStorage.getItem(key) !== value) {
      throw new Error("local storage not supported");
    }
    localStorage.removeItem(key);
    return true;
  } catch (_error) {
    return false;
  }
})("test-key", "test-value");
var fallback = {};
var setItem = (key, value) => {
  if (SUPPORTED) {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      return false;
    }
  } else {
    fallback[key] = value;
  }
  return true;
};
var getItem = (key) => {
  if (SUPPORTED) {
    return localStorage.getItem(key);
  } else {
    return fallback[key];
  }
};
var deleteItem = (key) => {
  if (SUPPORTED) {
    localStorage.removeItem(key);
  } else {
    delete fallback[key];
  }
};

// src/mergeSortArray.ts
var merger = (acc, cur) => {
  if (isArray(cur)) {
    return acc.concat(cur);
  }
  if (isString(cur)) {
    return acc.concat([cur]);
  }
  return acc;
};
var uniqueArray = (...args) => [
  ...new Set(args.reduce(merger, []))
];
var sorter = (a, b) => a.localeCompare(b);
var mergeSortArray = (...args) => uniqueArray(...args).sort(sorter);

// ../../shared/baseLogger.ts
var LogLevels = ((LogLevels2) => {
  LogLevels2[LogLevels2["trace"] = 10] = "trace";
  LogLevels2[LogLevels2["debug"] = 20] = "debug";
  LogLevels2[LogLevels2["info"] = 30] = "info";
  LogLevels2[LogLevels2["warn"] = 40] = "warn";
  LogLevels2[LogLevels2["error"] = 50] = "error";
  LogLevels2[LogLevels2["fatal"] = 60] = "fatal";
  LogLevels2[LogLevels2["silent"] = Number.MAX_SAFE_INTEGER] = "silent";
  return LogLevels2;
})(LogLevels || {});
function createConsoleLogger() {
  return Object.freeze({
    /* eslint-disable no-console -- set logger to console */
    fatal: (meta, message) => console.error(message, meta),
    error: (meta, message) => console.error(message, meta),
    warn: (meta, message) => console.warn(message, meta),
    info: (meta, message) => console.info(message, meta),
    debug: (meta, message) => console.debug(message, meta),
    trace() {
    },
    // Noop
    silent() {
    },
    // Noop
    redact() {
    }
    // Noop
    /* eslint-enable no-console -- set logger to console */
  });
}

// src/logger.ts
var logger = createConsoleLogger();
function setLogger(newLogger) {
  logger = newLogger;
}

// src/extractor.ts
var selector = (select, defaultValue) => (...args) => {
  for (const arg of args) {
    if (typeof arg === select) {
      return arg;
    }
  }
  return defaultValue;
};
var paramToBoolean = (param) => isString(param) ? param === "true" || param === "" : null;
var paramToNumber = (param) => isString(param) ? !isNaN(Number(param)) && !isNaN(parseFloat(param)) ? Number(param) : null : null;
var boolOrNull = (input) => isBoolean(input) ? input : null;
var strOrNull = (input) => isString(input) ? input : null;
var numberOrNull = (input) => isNumber(input) ? input : null;
var recordOrNull = (input) => isRecord(input) ? input : null;
var extractor = (config) => ({
  key,
  local,
  override,
  params
}) => {
  const value = config[key];
  if (isArray(value)) {
    return mergeSortArray(
      value,
      isArray(local) ? local : null,
      isArray(override) ? override : null,
      params
    );
  }
  if (isBoolean(value)) {
    return selector("boolean", value)(
      paramToBoolean(params),
      boolOrNull(override),
      boolOrNull(local)
    );
  }
  if (isString(value)) {
    return selector("string", value)(
      strOrNull(params),
      strOrNull(override),
      strOrNull(local)
    );
  }
  if (isNumber(value)) {
    return selector("number", value)(
      paramToNumber(params),
      numberOrNull(override),
      numberOrNull(local)
    );
  }
  if (isRecord(value)) {
    let param = null;
    try {
      param = isString(params) ? JSON.parse(params) : null;
    } catch (error) {
      logger.error({ error });
    }
    return {
      ...value,
      ...recordOrNull(local),
      ...override,
      ...recordOrNull(param)
    };
  }
  return value;
};

// src/ConfigManager.ts
var clone = (config) => Object.entries(config).reduce((acc, [key, value]) => {
  if (isArray(value)) {
    return { ...acc, [key]: [...value] };
  }
  if (isRecord(value)) {
    return { ...acc, [key]: { ...value } };
  }
  if (isString(value) || isBoolean(value) || isNumber(value)) {
    return { ...acc, [key]: value };
  }
  return acc;
}, {});
var ConfigManager = class {
  constructor(config, namespace) {
    this.config = config;
    this.namespace = namespace;
    this.currentConfig = clone(config);
    for (const key in config) {
      if (!Object.hasOwnProperty.call(config, key) || !isValid(config[key])) {
        throw new TypeError(
          `Invalid value for "${key}", must be of 'Valid | Record<string, Valid> where Valid = string | string[] | boolean | number', got "${getTypeOf(
            config[key]
          )}"`
        );
      }
    }
  }
  loaded = false;
  currentConfig;
  signals = {};
  get(key) {
    this.isLoaded();
    return this.currentConfig[key];
  }
  set({
    key,
    value,
    persist = false,
    emit = true
  }) {
    this.isLoaded();
    this.currentConfig[key] = value;
    if (emit) {
      this.signals[key]?.emit(value);
    }
    if (persist) {
      setItem(this.keyToNamespacedString(key), JSON.stringify(value));
    }
  }
  delete(key) {
    this.isLoaded();
    this.currentConfig[key] = this.config[key];
    deleteItem(this.keyToNamespacedString(key));
  }
  /**
   * Config should be overwritten by "higher" priority levels, arrays and records should be merged.
   *
   * 1. Config - the one for the constructor which sets default values and types
   * 2. LocalStorage - Values we have persisted for the user for use on next visit
   * 3. overrides - passed in, but could be conceived to come from CLI arguments or a file on disk in a desktop app
   * 4. URL - Values from searchParams
   *
   * We check 2-4 for valid values before updating the local mutable config
   */
  load(overrides, loadConfigFromQuery = true) {
    if (this.loaded) {
      throw new Error("config has already been loaded");
    }
    this.loaded = true;
    const extractValue = extractor(this.config);
    const { searchParams } = new URL(window.location.href);
    Object.keys(this.config).forEach((key) => {
      const value = extractValue({
        key,
        local: this.selectFromLocalStorage(key),
        override: overrides[key],
        params: loadConfigFromQuery ? isArray(this.config[key]) ? searchParams.getAll(String(key)) : searchParams.get(String(key)) : null
      });
      this.set({ key, value });
    });
    return clone(this.currentConfig);
  }
  subscribe(key, subscriber) {
    let signal = this.signals[key];
    if (!signal) {
      signal = createSignal({
        name: `config:${String(key)}`
      });
      this.signals[key] = signal;
    }
    return signal.add(subscriber);
  }
  /**
   * This fn is based on assumption that if value is not in the localstorage means that user didnt override the defaults.
   * This fns only really work on the runtime as if user change values after without saving it to local storage we will get
   * false positives.
   */
  isDefaultValue(key) {
    return this.selectFromLocalStorage(key) === null;
  }
  isLoaded() {
    if (!this.loaded) {
      throw new Error(
        "Do not use config before loading, to ensure localeStorage and overrides are set"
      );
    }
  }
  keyToNamespacedString(key) {
    return `${this.namespace}:${String(key)}`;
  }
  selectFromLocalStorage(key) {
    const local = getItem(this.keyToNamespacedString(key));
    if (isString(local)) {
      try {
        const parsed = JSON.parse(local);
        if (isValid(parsed)) {
          return parsed;
        }
      } catch (error) {
        logger.error({ error });
      }
    }
    return local;
  }
};

// src/configHook.ts
import React, { useCallback } from "react";
var isCallable = (t) => typeof t === "function";
var configHook = (config) => (key) => {
  const [value, setValue] = React.useState(config.get(key));
  React.useEffect(
    () => config.subscribe(key, (value2) => {
      setValue(value2);
    }),
    [key]
  );
  const handleSetValue = useCallback(
    (input, persist) => {
      config.set({
        key,
        value: isCallable(input) ? input(config.get(key)) : input,
        persist
      });
    },
    [key]
  );
  return [value, handleSetValue];
};
export {
  ConfigManager,
  configHook,
  setLogger
};

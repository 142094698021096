import {useEffect, useState} from 'react';

import type {Participant} from '@pexip/infinity';

import {infinityClientSignals} from '../signals/InfinityClient.signals';
import {infinityService} from '../services/InfinityClient.service';

export const useMe = () => {
    const [me, setMe] = useState<Participant | undefined>(infinityService.me);
    useEffect(() => infinityClientSignals.onMe.add(setMe), []);

    return me;
};

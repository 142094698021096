import type {MeetingPanelType, MeetingPanelsState} from '../types';

export enum PanelAnimationTypes {
    NONE = 'none',
    CHAT_IN = 'chat-in',
    CHAT_OUT = 'chat-out',
    PARTICIPANTS_IN = 'participants-in',
    PARTICIPANTS_OUT = 'participants-out',
}

export const calculatePanelAnimation = (state: {
    currentChat: boolean;
    currentParticipants: boolean;
    nextChat: boolean;
    nextParticipants: boolean;
}) => {
    const {currentChat, currentParticipants, nextChat, nextParticipants} =
        state;

    const shouldOpenChat = () =>
        !currentChat && !currentParticipants && nextChat;
    const shouldOpenParticipants = () =>
        !currentChat && !currentParticipants && nextParticipants;

    const shouldCloseChat = () => currentChat && !nextChat && !nextParticipants;
    const shouldCloseParticipants = () =>
        currentParticipants && !nextChat && !nextParticipants;

    const shouldToggleChatIntoParticipants = () =>
        !currentChat && currentParticipants && nextChat;
    const shouldToggleParticipantsIntoChat = () =>
        currentChat && !currentParticipants && nextParticipants;

    if (shouldOpenChat()) {
        return PanelAnimationTypes.CHAT_IN;
    }

    if (shouldToggleChatIntoParticipants()) {
        return PanelAnimationTypes.NONE;
    }

    if (shouldOpenParticipants()) {
        return PanelAnimationTypes.PARTICIPANTS_IN;
    }

    if (shouldToggleParticipantsIntoChat()) {
        return PanelAnimationTypes.NONE;
    }

    if (shouldCloseChat()) {
        return PanelAnimationTypes.CHAT_OUT;
    }

    if (shouldCloseParticipants()) {
        return PanelAnimationTypes.PARTICIPANTS_OUT;
    }

    return PanelAnimationTypes.NONE;
};

const calculateNextState = (
    panel: MeetingPanelType,
    openChatPanel: boolean,
    openParticipantPanel: boolean,
) => (panel === 'chat' ? !openChatPanel : !openParticipantPanel);

/**
 * @returns `open` to indicate if one of the panels is open
 */
export const togglePanel =
    (panel: MeetingPanelType) =>
    (panelsState: MeetingPanelsState): [boolean, PanelAnimationTypes] => {
        const isOpen = calculateNextState(
            panel,
            panelsState.openChatPanel,
            panelsState.openParticipantPanel,
        );

        const isChat = panel === 'chat';
        const isChatToShow = isChat && isOpen;
        const isParticipantsToShow = !isChat && isOpen;

        const animationType = calculatePanelAnimation({
            currentChat: panelsState.openChatPanel,
            currentParticipants: panelsState.openParticipantPanel,
            nextChat: isChatToShow,
            nextParticipants: isParticipantsToShow,
        });

        return [isOpen, animationType];
    };

import React, {useEffect, useRef, useState} from 'react';
import cx from 'classnames';

import styles from './Draggable.module.scss';

export interface FloatRootOverflow {
    overflow: boolean;
    top?: boolean;
    bottom?: boolean;
    left?: boolean;
    right?: boolean;
}
export type AlignWithFloatRootTrigger = React.MutableRefObject<
    (() => void) | null
>;

export const Draggable = React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<'div'> & {
        isDisabled?: boolean;
        floatRoot?: React.RefObject<HTMLDivElement>;
        beforeReposition?: (element: HTMLDivElement) => void;
        onReposition?: (
            element: HTMLDivElement,
            floatRootOverflow: FloatRootOverflow,
        ) => void;
        shouldCaptureClick?: boolean;
        alignWithFloatRootTrigger?: AlignWithFloatRootTrigger;
    }
>(
    (
        {
            isDisabled = false,
            floatRoot = undefined,
            beforeReposition,
            onReposition,
            shouldCaptureClick = true,
            alignWithFloatRootTrigger,
            children,
            className,
            ...props
        },
        ref,
    ) => {
        let componentRef = useRef<HTMLDivElement>(null);
        componentRef = (ref as React.RefObject<HTMLDivElement>) ?? componentRef;

        const [isDragging, setIsDragging] = useState(false);
        const wasMoved = useRef(false);

        useEffect(() => {
            if (isDisabled || !componentRef.current) {
                return;
            }
            const component = componentRef.current;
            let componentRect = component.getBoundingClientRect();

            let startPosX = 0,
                startPosY = 0,
                transformX = 0,
                transformY = 0,
                parentRect: DOMRect | undefined,
                parentLeft = 0,
                parentTop = 0;

            const cleanupDocumentListeners = () => {
                document.removeEventListener('pointermove', onPointerMove);
                document.removeEventListener('pointerup', onPointerUp);
            };

            const setInsetAsPercentages = () => {
                component.style.left = `${
                    ((component.offsetLeft + transformX) / window.innerWidth) *
                    100
                }%`;

                component.style.top = `${
                    ((component.offsetTop + transformY) / window.innerHeight) *
                    100
                }%`;
            };

            component.addEventListener('pointerdown', onPointerDown);
            function onPointerDown(event: PointerEvent) {
                event.preventDefault();

                if (event.button !== 0) {
                    // only drag on left click
                    return;
                }

                setIsDragging(true);

                componentRect = component.getBoundingClientRect();
                const offsetParent = component.offsetParent;
                parentRect = offsetParent?.getBoundingClientRect();
                parentLeft = parentRect?.left ?? 0;
                parentTop = parentRect?.top ?? 0;

                if (beforeReposition) {
                    beforeReposition(component);
                    // get new rect in case beforeReposition had side effects
                    componentRect = component.getBoundingClientRect();
                }
                // take control of the component
                component.style.left = `${componentRect.left - parentLeft}px`;
                component.style.top = `${componentRect.top - parentTop}px`;
                component.style.removeProperty('right');
                component.style.removeProperty('bottom');
                component.style.transform = 'none';

                startPosX = event.clientX;
                startPosY = event.clientY;

                document.addEventListener('pointermove', onPointerMove);
                document.addEventListener('pointerup', onPointerUp);
            }
            function onPointerMove(event: PointerEvent) {
                transformX = event.clientX - startPosX;
                transformY = event.clientY - startPosY;

                component.style.transform = `translate(${transformX}px, ${transformY}px)`;
            }

            const alignWithFloatRoot = () => {
                let top = false,
                    bottom = false,
                    left = false,
                    right = false,
                    floatRootOverflow: FloatRootOverflow = {overflow: false};

                if (floatRoot?.current) {
                    componentRect = component.getBoundingClientRect();
                    const floatRootRect =
                        floatRoot.current.getBoundingClientRect();
                    // handle left
                    if (componentRect.left < floatRootRect.left) {
                        component.style.left = `${
                            floatRoot.current.offsetLeft - parentLeft
                        }px`;
                        left = true;
                    }
                    // handle right
                    if (componentRect.right > floatRootRect.right) {
                        component.style.left = `${
                            floatRootRect.right -
                            component.offsetWidth -
                            parentLeft
                        }px`;
                        right = true;
                    }
                    // handle top
                    if (componentRect.top < floatRootRect.top) {
                        component.style.top = `${
                            floatRoot.current.offsetTop - parentTop
                        }px`;
                        top = true;
                    }
                    //handle bottom
                    if (componentRect.bottom > floatRootRect.bottom) {
                        component.style.top = `${
                            floatRootRect.bottom -
                            component.offsetHeight -
                            parentTop
                        }px`;
                        bottom = true;
                    }

                    floatRootOverflow = {
                        overflow: top || bottom || left || right,
                        top,
                        bottom,
                        left,
                        right,
                    };
                    if (floatRootOverflow.overflow) {
                        setInsetAsPercentages();
                    }
                }
                return floatRootOverflow;
            };
            if (alignWithFloatRootTrigger) {
                alignWithFloatRootTrigger.current = () => {
                    const overflow = alignWithFloatRoot();
                    if (onReposition) {
                        onReposition(component, overflow);
                    }
                };
            }
            function alignWithNearestWindowXWall() {
                if (
                    componentRect.left + componentRect.width / 2 >
                    window.innerWidth / 2
                ) {
                    component.style.right = `${
                        ((window.innerWidth - componentRect.right) /
                            window.innerWidth) *
                        100
                    }%`;
                    component.style.left = 'auto';
                }
            }
            function alignWithNearestWindowYWall() {
                if (
                    componentRect.top + componentRect.height / 2 >
                    window.innerHeight / 2
                ) {
                    component.style.bottom = `${
                        ((window.innerHeight - componentRect.bottom) /
                            window.innerHeight) *
                        100
                    }%`;
                    component.style.top = 'auto';
                }
            }
            function onPointerUp() {
                setInsetAsPercentages();
                component.style.removeProperty('transform');
                if (transformX || transformY) {
                    wasMoved.current = true;
                }
                (transformX = 0), (transformY = 0);
                cleanupDocumentListeners();

                const overflow = alignWithFloatRoot();
                if (!overflow.overflow) {
                    alignWithNearestWindowXWall();
                    alignWithNearestWindowYWall();
                }
                setIsDragging(false);
                if (onReposition) {
                    onReposition(component, overflow);
                }
            }
            return () => {
                cleanupDocumentListeners();
                component.removeEventListener('pointerdown', onPointerDown);
            };
        }, [
            isDisabled,
            floatRoot,
            beforeReposition,
            onReposition,
            alignWithFloatRootTrigger,
        ]);

        return (
            <div
                ref={componentRef}
                className={cx(
                    {
                        [styles.draggable]: !isDisabled,
                        [styles.dragging]: isDragging,
                    },
                    className,
                )}
                onClickCapture={
                    shouldCaptureClick
                        ? e => {
                              // shortcut the click event for clickable elements within the draggable wrapper when movement occurs
                              if (wasMoved.current) {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  wasMoved.current = false;
                              }
                          }
                        : undefined
                }
                {...props}
            >
                {children}
            </div>
        );
    },
);

Draggable.displayName = 'Draggable';

export type DraggableProps = React.ComponentProps<typeof Draggable>;

// src/index.ts
import Pino from "pino";
var REDACTED_REPLACEMENT = "[REDACTED]";
function escapeRegexp(segment) {
  return segment.replace(/"/g, "\\$&").replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
function redactString(value, regex, replaceTo) {
  return value.replace(regex, replaceTo);
}
function defaultReplacer(_key, value) {
  if (value instanceof HTMLElement) {
    const innerHTML = value.innerHTML;
    if (innerHTML !== "") {
      return value.outerHTML.replace(innerHTML, "{...}");
    } else {
      return value.outerHTML;
    }
  } else if (value instanceof Function) {
    return `[Function ${value.name}]`;
  } else if (value instanceof MediaStream) {
    return {
      __type: "MediaStream",
      id: value.id,
      active: value.active
    };
  } else if (value instanceof MediaStreamTrack) {
    return {
      __type: "MediaStreamTrack",
      kind: value.kind,
      id: value.id,
      enabled: value.enabled,
      muted: value.muted,
      readyState: value.readyState
    };
  } else if (value instanceof Error) {
    return {
      name: value.toString(),
      message: value.message
    };
  } else if (value instanceof AnalyserNode) {
    return {
      __type: "AnalyserNode",
      frequencyBinCount: value.frequencyBinCount,
      fftSize: value.fftSize,
      minDecibels: value.minDecibels,
      maxDecibels: value.maxDecibels,
      smoothingTimeConstant: value.smoothingTimeConstant
    };
  } else if (value instanceof AudioContext) {
    return {
      __type: "AudioContext",
      audioWorklet: value.audioWorklet,
      state: value.state,
      sampleRate: value.sampleRate
    };
  } else if (value instanceof MediaStreamAudioSourceNode) {
    return {
      __type: "MediaStreamAudioSourceNode",
      mediaStream: value.mediaStream
    };
  } else if (value instanceof MediaStreamAudioDestinationNode) {
    return {
      __type: "MediaStreamAudioDestinationNode",
      stream: value.stream
    };
  } else if (value instanceof ChannelMergerNode) {
    return { __type: "ChannelMergerNode" };
  } else if (value instanceof GainNode) {
    return { __type: "GainNode", gain: value.gain };
  } else if (value instanceof MediaElementAudioSourceNode) {
    return {
      __type: "MediaElementAudioSourceNode",
      mediaElement: value.mediaElement
    };
  } else if ("AudioWorkletNode" in window && value instanceof AudioWorkletNode) {
    return {
      __type: "AudioWorkletNode",
      parameters: value.parameters
    };
  } else if (value instanceof ChannelSplitterNode) {
    return {
      __type: "ChannelSplitterNode"
    };
  } else if (value instanceof DelayNode) {
    return {
      __type: "DelayNode",
      delayTime: value.delayTime
    };
  }
  return value;
}
var createRedactor = () => {
  const sensitiveValues = /* @__PURE__ */ new Set();
  let redactionRegex = void 0;
  function redact(value) {
    if (!redactionRegex) {
      return value;
    }
    return redactString(value, redactionRegex, REDACTED_REPLACEMENT);
  }
  function add(value) {
    if (value === "") {
      throw new Error("Tried to add empty string for redaction");
    }
    if (/[\\\n]/.exec(value)) {
      throw new Error(
        "Trying to add value for redaction that is likely to break the log"
      );
    }
    if (["null", "undefined", "true", "false", "[Object object]"].includes(
      String(value)
    )) {
      throw new Error(
        `Tried to add ${value} for redaction, which is almost certainly an error`
      );
    }
    if (sensitiveValues.has(value)) {
      return;
    }
    sensitiveValues.add(value);
    redactionRegex = new RegExp(
      Array.from(sensitiveValues).map(escapeRegexp).join("|"),
      "g"
    );
  }
  function reset() {
    sensitiveValues.clear();
    redactionRegex = void 0;
  }
  return {
    redact,
    add,
    reset
  };
};
var createLogger = ({
  minLevel = process.env.NODE_ENV === "test" ? "silent" : "debug",
  replacer = defaultReplacer,
  fileName = "pexip",
  fileExt = "log"
} = {}) => {
  const logEvents = [];
  const redactor = createRedactor();
  function getLogs() {
    return new Blob(
      logEvents,
      // Technically application/ld+json
      { type: "text/plain; charset=utf-8" }
    );
  }
  function getLogFile() {
    return logEvents.join("");
  }
  function getLogFileName(name = fileName, ext = fileExt) {
    return `${name}-${(/* @__PURE__ */ new Date()).toISOString().replace(/:/g, "-")}.${ext}`;
  }
  function downloadLog(fileName2 = getLogFileName()) {
    const blobUrl = URL.createObjectURL(getLogs());
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = fileName2;
    link.click();
    setTimeout(() => URL.revokeObjectURL(blobUrl), 500);
  }
  const pinoStore = Pino({
    browser: {
      write: (evt) => {
        if (typeof evt !== "object" || !evt) {
          return;
        }
        try {
          const logEvent = JSON.stringify(evt, replacer);
          logEvents.push(redactor.redact(logEvent));
          logEvents.push("\n");
        } catch {
          const logEvent = JSON.stringify(
            Object.fromEntries(
              Object.entries(evt).map(([k, v]) => [
                k,
                typeof v === "object" ? `${v}` : v
              ])
            )
          );
          logEvents.push(redactor.redact(logEvent));
          logEvents.push("\n");
        }
      }
    },
    level: minLevel
  });
  const pino = Pino({
    browser: {
      // asObject: true,
      transmit: {
        send: (level, event) => {
          const [first, ...rest] = event.messages;
          event.bindings.reduce(
            (ch, bi) => ch.child(bi),
            pinoStore
          )[level](first, ...rest);
        }
      }
    },
    level: minLevel
  });
  const redact = (value) => redactor.add(value);
  return {
    get logEvents() {
      return logEvents;
    },
    getLogs,
    getLogFileName,
    getLogFile,
    downloadLog,
    redact,
    fatal: (meta, message) => pino.fatal(meta, message),
    error: (meta, message) => pino.error(meta, message),
    warn: (meta, message) => pino.warn(meta, message),
    info: (meta, message) => pino.info(meta, message),
    debug: (meta, message) => pino.debug(meta, message),
    trace: (meta, message) => pino.trace(meta, message),
    silent: (meta, message) => pino.silent(meta, message),
    child: (...args) => {
      const child = pino.child(...args);
      return {
        fatal: (meta, message) => child.fatal(meta, message),
        error: (meta, message) => child.error(meta, message),
        warn: (meta, message) => child.warn(meta, message),
        info: (meta, message) => child.info(meta, message),
        debug: (meta, message) => child.debug(meta, message),
        trace: (meta, message) => child.trace(meta, message),
        silent: (meta, message) => child.silent(meta, message),
        redact
      };
    }
  };
};
export {
  createLogger,
  createRedactor,
  defaultReplacer,
  redactString
};

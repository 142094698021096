import type {BrowserOptions} from '@sentry/react';

import {logger} from './logger';

const el = document.querySelector('#ic-config');

export interface ApplicationConfig {
    sentry?: BrowserOptions;
    node?: string;
}

export const applicationConfig = (() => {
    try {
        return el ? (JSON.parse(el.innerHTML) as ApplicationConfig) : {};
    } catch (error: unknown) {
        logger.error(error, 'failed to parse applicationConfig');
        return {};
    }
})();

import React from 'react';
import cx from 'classnames';

import {Box, ThemeProvider} from '@pexip/components';

import styles from './ResponsiveBox.module.scss';

export const ResponsiveBox: React.FC<
    React.PropsWithChildren<{className?: string; style?: React.CSSProperties}>
> = ({children, className, ...props}) => (
    <ThemeProvider colorScheme="light">
        <Box
            padding="medium"
            className={cx(styles.responsiveBoxWrapper, className)}
            {...props}
        >
            {children}
        </Box>
    </ThemeProvider>
);

// src/types.ts
var RecoveryTimeout = /* @__PURE__ */ ((RecoveryTimeout2) => {
  RecoveryTimeout2[RecoveryTimeout2["ConnectionState"] = 5e3] = "ConnectionState";
  RecoveryTimeout2[RecoveryTimeout2["IceConnectionState"] = 2e3] = "IceConnectionState";
  return RecoveryTimeout2;
})(RecoveryTimeout || {});

// src/utils.ts
import { createSignal } from "@pexip/signal";

// ../../shared/baseLogger.ts
var LogLevels = ((LogLevels2) => {
  LogLevels2[LogLevels2["trace"] = 10] = "trace";
  LogLevels2[LogLevels2["debug"] = 20] = "debug";
  LogLevels2[LogLevels2["info"] = 30] = "info";
  LogLevels2[LogLevels2["warn"] = 40] = "warn";
  LogLevels2[LogLevels2["error"] = 50] = "error";
  LogLevels2[LogLevels2["fatal"] = 60] = "fatal";
  LogLevels2[LogLevels2["silent"] = Number.MAX_SAFE_INTEGER] = "silent";
  return LogLevels2;
})(LogLevels || {});
function createConsoleLogger() {
  return Object.freeze({
    /* eslint-disable no-console -- set logger to console */
    fatal: (meta, message) => console.error(message, meta),
    error: (meta, message) => console.error(message, meta),
    warn: (meta, message) => console.warn(message, meta),
    info: (meta, message) => console.info(message, meta),
    debug: (meta, message) => console.debug(message, meta),
    trace() {
    },
    // Noop
    silent() {
    },
    // Noop
    redact() {
    }
    // Noop
    /* eslint-enable no-console -- set logger to console */
  });
}

// src/logger.ts
var logger = createConsoleLogger();
function setLogger(newLogger) {
  logger = newLogger;
}

// src/utils.ts
var createRefsLog = (getRefs) => {
  const info = (obj) => {
    const refs = getRefs();
    return { ...refs, ...obj };
  };
  return {
    debug: (msg, obj) => logger.debug(info(obj), msg),
    info: (msg, obj) => logger.info(info(obj), msg),
    error: (msg, obj) => logger.error(info(obj), msg),
    warn: (msg, obj) => logger.warn(info(obj), msg)
  };
};
var getPeerConnectionStates = (pc) => ({
  get connectionState() {
    return pc.connectionState;
  },
  get iceConnectionState() {
    return pc.iceConnectionState;
  },
  get iceGatheringState() {
    return pc.iceGatheringState;
  },
  get signalingState() {
    return pc.signalingState;
  }
});
var getStatesAndProps = (pc) => {
  return {
    ...getPeerConnectionStates(pc),
    offerOptions: pc.offerOptions,
    answerOptions: pc.answerOptions
  };
};
var logReferences = (refs) => ({
  module: refs.module,
  references: refs
});
var createGetRefs = (pc) => () => ({
  ...logReferences(pc.references),
  ...getStatesAndProps(pc)
});
var isSameStream = (stream1, stream2) => {
  return stream1 && stream2 && stream1.id === stream2.id;
};
var wirePeerConnectionEventHandler = ({
  key,
  pc,
  signal
}) => {
  const log = createRefsLog(createGetRefs(pc));
  switch (key) {
    case "onConnectionStateChange":
      pc.onConnectionStateChange = (event) => {
        log.info("onConnectionStateChange emitted", {
          event
        });
        signal.emit(pc.connectionState);
      };
      break;
    case "onDataChannel":
      pc.onDataChannel = (event) => {
        log.info("onDataChannel emitted", {
          event
        });
        signal.emit(event.channel);
      };
      break;
    case "onIceCandidate":
      pc.onIceCandidate = (event) => {
        log.info("onIceCandidate emitted", {
          event
        });
        signal.emit(event.candidate);
      };
      break;
    case "onIceCandidateError":
      pc.onIceCandidateError = (event) => {
        log.info("onIceCandidateError emitted", {
          event
        });
        signal.emit(event);
      };
      break;
    case "onIceConnectionStateChange":
      pc.onIceConnectionStateChange = (event) => {
        log.info("onIceConnectionStateChange emitted", {
          event
        });
        signal.emit(pc.iceConnectionState);
      };
      break;
    case "onIceGatheringStateChange":
      pc.onIceGatheringStateChange = (event) => {
        log.info("onIceGatheringStateChange emitted", {
          event
        });
        signal.emit(pc.iceGatheringState);
      };
      break;
    case "onNegotiationNeeded":
      pc.onNegotiationNeeded = () => {
        log.info("onNegotiationNeeded emitted");
        signal.emit();
      };
      break;
    case "onSignalingStateChange":
      pc.onSignalingStateChange = (event) => {
        log.info("onSignalingStateChange emitted", {
          event
        });
        signal.emit(pc.signalingState);
      };
      break;
    case "onTrack":
      pc.onTrack = (event) => {
        log.info("onTrack emitted", {
          event
        });
        signal.emit(event);
      };
      break;
    case "onRemoteStreams":
      pc.onRemoteStreams = (streams) => {
        log.info("onRemoteStreams emitted", {
          remoteStreams: streams
        });
        signal.emit(streams);
      };
      break;
    case "onRemoteContentStreams":
      pc.onRemoteContentStreams = (streams) => {
        log.info("onRemoteContentStreams emitted", {
          remoteStreams: streams
        });
        signal.emit(streams);
      };
      break;
    case "onTransceiverChange":
      pc.onTransceiverChange = () => {
        log.info("onTransceiverChanged");
        signal.emit();
      };
      break;
    case "onSecureCheckCode":
      pc.onSecureCheckCode = (secureCheckCode) => {
        log.info("onSecureCheckCode emitted", { secureCheckCode });
        signal.emit(secureCheckCode);
      };
      break;
  }
};
var wirePeerConnectionEvents = (pc, signals) => {
  Object.keys(signals).forEach((eventKey) => {
    const signalKey = eventKey;
    const signal = signals[signalKey];
    if (signal) {
      wirePeerConnectionEventHandler({
        key: signalKey,
        pc,
        signal
      });
    }
  });
};
var createPCSignal = (name, crucial = true) => createSignal({
  name: `call:peerConnection:${name}`,
  allowEmittingWithoutObserver: !crucial
});
var REQUIRED_SIGNAL_KEYS = [
  "onOfferRequired",
  "onReceiveAnswer",
  "onReceiveOffer",
  "onOffer",
  "onOfferIgnored",
  "onAnswer",
  "onError"
];
var createPCSignals = (more, scope = "") => {
  const signalScope = scope && [scope, ":"].join("");
  return [...REQUIRED_SIGNAL_KEYS, ...more].reduce(
    (signals, key) => ({
      ...signals,
      [key]: createPCSignal(`${signalScope}${key}`)
    }),
    {}
  );
};
var withSignals = (peer) => (
  /**
   * Map signals
   *
   * @param signals - The provided signals to map to the PC events
   */
  ({
    onReceiveAnswer,
    onReceiveIceCandidate,
    onReceiveOffer,
    onOffer,
    onOfferIgnored,
    onAnswer,
    onError,
    onNegotiationNeeded = createPCSignal("onNegotiationNeeded"),
    onIceCandidateError = createPCSignal(
      "onIceCandidateError"
    ),
    ...pcEventSignals
  }) => {
    pcEventSignals && wirePeerConnectionEvents(peer, {
      ...pcEventSignals,
      onNegotiationNeeded,
      onIceCandidateError
    });
    const props = {
      politePeer: false,
      receivingFirstOffer: true,
      makingOffer: false,
      ignoreOffer: false
    };
    const log = createRefsLog(() => ({
      ...createGetRefs(peer)(),
      ...props
    }));
    const emitError = (msg, context) => {
      log.error(msg, { ...context, ...getPeerConnectionStates(peer) });
      if (context?.error instanceof Error) {
        onError.emit(context.error);
      }
    };
    const emitLocalDescription = (sdp) => {
      const localDescription = sdp ?? peer.pendingLocalDescription;
      switch (localDescription?.type) {
        case "offer":
          onOffer.emit(localDescription);
          break;
        case "answer":
          onAnswer.emit(localDescription);
          break;
        default:
          log.error(
            'Attempt to emit localDescription other than "answer" and "offer"',
            { localDescription }
          );
          throw new Error("Unknown SDP type");
      }
    };
    const signalSubscriptions = [
      onNegotiationNeeded.add(async () => {
        log.info("handle onNegotiationNeeded signal");
        try {
          props.makingOffer = true;
          const offer = await peer.createOffer();
          log.info("emit offer", { offer });
          emitLocalDescription(offer);
        } catch (error) {
          if (error instanceof Error) {
            if (error.message !== "Ignore") {
              emitError("createOffer", { error });
            }
          }
        } finally {
          props.makingOffer = false;
        }
      }),
      onIceCandidateError.add((error) => {
        emitError("onIceCandidateError", {
          error: new Error(error.errorText),
          event: error
        });
      }),
      onReceiveIceCandidate?.add(async (candidate) => {
        log.info("handle onReceiveIceCandidate signal", { candidate });
        try {
          await peer.receiveIceCandidate(candidate);
        } catch (error) {
          if (!props.ignoreOffer) {
            emitError("receiveIceCandidate", { error, candidate });
          }
        }
      }),
      onReceiveOffer.add(async (offer) => {
        try {
          const offerCollisionDetected = props.makingOffer || peer.signalingState !== "stable";
          if (props.receivingFirstOffer && offerCollisionDetected) {
            props.politePeer = true;
          }
          props.ignoreOffer = !props.politePeer && offerCollisionDetected;
          log.info("handle receiveOffer signal", {
            politePeer: props.politePeer,
            offer,
            offerCollisionDetected,
            ignoreOffer: props.ignoreOffer
          });
          if (props.ignoreOffer) {
            log.info("ignore offer", {
              politePeer: props.politePeer,
              offer,
              offerCollisionDetected,
              ignoreOffer: props.ignoreOffer
            });
            onOfferIgnored.emit();
            return;
          }
          await peer.receiveOffer(offer);
          const answer = await peer.createAnswer();
          emitLocalDescription(answer);
        } catch (error) {
          emitError("receiveOffer/createAnswer", { error, offer });
        } finally {
          props.receivingFirstOffer = false;
        }
      }),
      onReceiveAnswer.add(async (answer) => {
        log.info("handle receiveAnswer signal", { answer });
        props.receivingFirstOffer = false;
        try {
          await peer.receiveAnswer(answer);
        } catch (error) {
          emitError("receiveAnswer", { error, answer });
        }
      })
    ].flatMap((a) => a ? [a] : []);
    return signalSubscriptions;
  }
);
var getCreateLoopbackConnectionFn = (rtcConnection = new RTCPeerConnection(), rtcLoopbackConnection = new RTCPeerConnection(), loopbackStream = new MediaStream()) => async (stream) => {
  rtcConnection.onicecandidate = (e) => e.candidate && rtcLoopbackConnection.addIceCandidate(
    new RTCIceCandidate(e.candidate)
  );
  rtcLoopbackConnection.onicecandidate = (e) => e.candidate && rtcConnection.addIceCandidate(new RTCIceCandidate(e.candidate));
  rtcLoopbackConnection.ontrack = (e) => {
    if (e.streams[0]) {
      return e.streams[0].getTracks().forEach((track) => loopbackStream.addTrack(track));
    }
  };
  stream.getTracks().forEach(function(track) {
    rtcConnection.addTrack(track, stream);
  });
  const offer = await rtcConnection.createOffer();
  await rtcConnection.setLocalDescription(offer);
  await rtcLoopbackConnection.setRemoteDescription(offer);
  const answer = await rtcLoopbackConnection.createAnswer();
  await rtcLoopbackConnection.setLocalDescription(answer);
  await rtcConnection.setRemoteDescription(answer);
  return loopbackStream;
};
var isMediaType = (mediaType) => mediaType === "audio" || mediaType === "video";

// src/sdpManager.ts
import * as sdpTransform from "sdp-transform";
var TWCCExtensionUrl = "http://www.ietf.org/id/draft-holmer-rmcat-transport-wide-cc-extensions";
var SdpTransformManager = class {
  constructor(sdp, enrichOptions) {
    this.sdp = sdp;
    this.setSdp(sdp, enrichOptions);
  }
  setSdp(sdp, enrichOptions) {
    this.sdp = sdp;
    if (enrichOptions) {
      this.enrichSdp(enrichOptions);
    }
  }
  getSdp() {
    return this.sdp;
  }
  getFingerprints() {
    if (!this.sdp?.sdp) {
      return [];
    }
    const transformSdp = sdpTransform.parse(this.sdp.sdp);
    return [
      transformSdp.fingerprint,
      ...transformSdp.media.map((m) => m.fingerprint)
    ].flatMap((fingerprint) => fingerprint ? [fingerprint] : []);
  }
  enrichSdp(options) {
    let modifiedSdp = this.sdp;
    if (this.sdp?.sdp) {
      let transformSdp = sdpTransform.parse(this.sdp.sdp);
      if (options.contentSlides) {
        transformSdp = this.addVideoSlidesContentLine(
          transformSdp,
          options.contentSlides
        );
      }
      if (options.videoAS) {
        transformSdp = this.addBandwidthLine(
          transformSdp,
          options.videoAS,
          options.videoTIAS
        );
      }
      if (options.allowCodecSdpMunging && !options.allowVP9) {
        transformSdp = this.stripCodecs(transformSdp, ["VP9" /* VP9 */]);
      }
      if (this.shouldAddSupportForHighQualityStream(options)) {
        transformSdp = this.addSupportForHighQualityStream(
          transformSdp,
          options.allow4kPreso
        );
      }
      if (options.allowCodecSdpMunging) {
        transformSdp = this.chooseVideoPTs(transformSdp);
      }
      modifiedSdp = {
        sdp: sdpTransform.write(transformSdp),
        type: this.sdp.type
      };
    }
    this.sdp = modifiedSdp;
  }
  isTWCCsupported = () => this.sdp.sdp?.includes(TWCCExtensionUrl);
  addMsidToMline = (transceiver, msid) => {
    if (!this.sdp.sdp) {
      return;
    }
    let modifiedSdp = this.sdp;
    const transformSdp = sdpTransform.parse(this.sdp.sdp);
    const mLine = transformSdp.media.find((mline) => {
      if (transceiver.mid) {
        return String(mline.mid) === transceiver.mid && mline.type === (transceiver.sender.track?.kind ?? transceiver.receiver.track.kind);
      }
    });
    if (!mLine) {
      return;
    }
    const msids = mLine.msid?.split(" ");
    if (msids?.[0]) {
      msids[0] = msid;
    }
    mLine.msid = msids?.join(" ");
    modifiedSdp = {
      sdp: sdpTransform.write(transformSdp),
      type: this.sdp.type
    };
    this.sdp = modifiedSdp;
  };
  shouldAddSupportForHighQualityStream(options) {
    return options.contentSlides || options?.allow1080p && options.videoAS && options.videoAS >= 2564;
  }
  addVideoSlidesContentLine(sdp, contentSlides) {
    if (!contentSlides) {
      return sdp;
    }
    const mid = typeof contentSlides === "string" ? contentSlides : "";
    const videoLine = this.getVideoLine(sdp.media, mid);
    if (videoLine) {
      videoLine.content = "slides";
    }
    return sdp;
  }
  addBandwidthLine(sdp, videoAS, videoTIAS) {
    const videoLines = this.getVideoLines(sdp.media);
    videoLines.forEach((videoLine) => {
      if (!videoLine.bandwidth) {
        videoLine.bandwidth = [];
      }
      const TIAS = videoLine.bandwidth.find(({ type }) => type === "TIAS");
      const limit = Number(TIAS?.limit);
      if (limit && videoTIAS && limit < videoTIAS) {
        return;
      }
      videoLine.bandwidth.push({
        type: "AS",
        limit: videoAS
      });
      if (videoTIAS) {
        if (TIAS) {
          TIAS.limit = videoTIAS;
        } else {
          videoLine.bandwidth.push({
            type: "TIAS",
            limit: videoTIAS
          });
        }
      }
    });
    return sdp;
  }
  addSupportForHighQualityStream(sdp, allow4kPreso = false) {
    const videoLines = this.getVideoLines(sdp.media);
    videoLines.forEach((videoLine) => {
      const codecs = this.getCodecs(videoLine.rtp);
      videoLine.fmtp = videoLine.fmtp.map((fmtp) => {
        if (fmtp.config.includes("max-fs")) {
          return fmtp;
        }
        const codec = codecs[fmtp.payload];
        const is4kPreso = isPreso(videoLine) && allow4kPreso;
        if (codec === "VP8" /* VP8 */ || codec === "VP9" /* VP9 */) {
          fmtp.config += this.getVPXConfigOverrides(is4kPreso);
        } else if (codec === "H264" /* H264 */) {
          fmtp.config += this.getH264ConfigOverrides(is4kPreso);
        }
        return fmtp;
      });
    });
    return sdp;
  }
  getVPXConfigOverrides(is4kEnabled = false) {
    return `;max-fs=${is4kEnabled ? "36864" : "8160"};max-fr=30`;
  }
  getH264ConfigOverrides(is4kEnabled = false) {
    return is4kEnabled ? ";max-br=32768;max-mbps=2073600;max-fs=36864;max-smbps=2073600;max-fps=6000;max-fr=30" : ";max-br=3732;max-mbps=245760;max-fs=8192;max-smbps=245760;max-fps=3000;max-fr=30";
  }
  /**
   * This method makes sure we pick set of supported codecs with the right order
   */
  chooseVideoPTs(sdp) {
    const videoLines = this.getVideoLines(sdp.media);
    videoLines.forEach((videoLine) => {
      const codecs = this.getCodecs(videoLine.rtp);
      let payloadTypes = [];
      for (const fmtp of videoLine.fmtp) {
        if (codecs[fmtp.payload] !== "H264" /* H264 */) {
          continue;
        }
        const params = fmtp.config.split(";");
        const [, profile] = params.find((param) => param.includes("profile-level-id"))?.split("=") ?? [];
        const profileIdc = profile?.substring(0, 2);
        const profileIop = profile?.substring(2, 4) ?? "";
        if (profileIdc !== "42") {
          continue;
        }
        if (parseInt("0x" + profileIop) & 64 && // CB otherwise B
        fmtp.config.includes("packetization-mode=1")) {
          payloadTypes = [fmtp.payload];
          break;
        }
        payloadTypes.push(fmtp.payload);
      }
      videoLine.rtp = videoLine.rtp.filter(({ codec, payload }) => {
        if (codec === "H264" /* H264 */) {
          return payloadTypes.includes(payload);
        }
        const fmtp = videoLine.fmtp.find(
          (line) => line.payload === payload
        );
        return this.shouldStripH264Line(
          fmtp?.config ?? "",
          payloadTypes,
          codecs
        );
      });
      videoLine.fmtp = videoLine.fmtp.filter(({ payload, config }) => {
        if (codecs[payload] === "H264" /* H264 */ && !payloadTypes.includes(payload)) {
          return false;
        }
        return this.shouldStripH264Line(config, payloadTypes, codecs);
      });
      if (videoLine.rtcpFb) {
        videoLine.rtcpFb = videoLine.rtcpFb.filter(
          ({ payload }) => codecs[payload] !== "H264" /* H264 */ || codecs[payload] === "H264" /* H264 */ && payloadTypes.includes(payload)
        );
      }
      if (videoLine.payloads) {
        videoLine.payloads = [
          ...payloadTypes,
          ...videoLine.rtp.flatMap(
            ({ payload }) => !payloadTypes.includes(Number(payload)) ? [payload] : []
          )
        ].join(" ");
      }
    });
    return sdp;
  }
  shouldStripH264Line(config, payloadTypes, codecs) {
    const [name, value] = config.split("=");
    if (name === "apt" && value && codecs[value] === "H264" /* H264 */ && !payloadTypes.includes(Number(value))) {
      return false;
    }
    return true;
  }
  stripCodecs(sdp, disableCodecs) {
    const videoLines = this.getVideoLines(sdp.media);
    for (const videoLine of videoLines) {
      if (videoLine) {
        const removePayloads = videoLine.rtp.filter(({ codec }) => disableCodecs.includes(codec)).map(({ payload }) => payload);
        if (removePayloads.length > 0) {
          const rtxApts = removePayloads.map((item) => `apt=${item}`);
          const rtxPayloads = videoLine.fmtp.filter(
            (item) => rtxApts.includes(item.config)
          );
          removePayloads.push(
            ...rtxPayloads.map((item) => item.payload)
          );
        }
        if (videoLine.payloads) {
          for (const payload of removePayloads) {
            videoLine.payloads = videoLine.payloads.replace(
              `${payload} `,
              ""
            );
          }
        }
        videoLine.rtp = videoLine.rtp.filter(
          (rtp) => !removePayloads.includes(rtp.payload)
        );
        videoLine.fmtp = videoLine.fmtp.filter(
          (fmtp) => !removePayloads.includes(fmtp.payload)
        );
        if (videoLine.rtcpFb) {
          videoLine.rtcpFb = videoLine.rtcpFb.filter(
            (rtcpFb) => !removePayloads.includes(rtcpFb.payload)
          );
        }
      }
    }
    return sdp;
  }
  getVideoLines(media) {
    return media.filter((line) => line.type === "video");
  }
  getVideoLine(media, mid) {
    if (mid) {
      return media.find(
        (line) => line.type === "video" && String(line.mid) === mid
      );
    }
    const videos = media.filter((line) => line.type === "video");
    return videos[videos.length - 1];
  }
  getCodecs(rtp) {
    return rtp.reduce((acc, { codec, payload }) => {
      acc[payload] = codec;
      return acc;
    }, {});
  }
};
var hasICECandidates = (sdp) => {
  if (!sdp) {
    return false;
  }
  const transformedSDP = sdpTransform.parse(sdp);
  return transformedSDP.media.some(
    (m) => m.candidates && m.candidates.length > 0
  );
};
var getMediaLines = (sdp) => {
  if (!sdp) {
    return [];
  }
  return sdpTransform.parse(sdp).media;
};
var isPreso = (media) => media.content === "slides";

// src/constants.ts
var TRANSCEIVER_MEDIA_TYPES = ["audio", "video"];
var TRANSCEIVER_CONTENT_TYPES = ["main", "preso"];

// src/peerConnections.ts
var hasSetStreams = typeof RTCRtpSender !== "undefined" && "setStreams" in RTCRtpSender.prototype;
var changeTransceiverDirection = (currentDirection, intendedDirection) => {
  logger.debug(
    { currentDirection, intendedDirection },
    "changeTransceiverDirection"
  );
  if (intendedDirection !== "send" && intendedDirection !== "recv") {
    return intendedDirection;
  }
  switch (currentDirection) {
    case "inactive": {
      return intendedDirection === "send" ? "sendonly" : "recvonly";
    }
    case "recvonly": {
      return intendedDirection === "send" ? "sendrecv" : "recvonly";
    }
    case "sendonly": {
      return intendedDirection === "send" ? "sendonly" : "sendrecv";
    }
    case "sendrecv":
    default:
      return currentDirection;
  }
};
var getDirection = (remoteDirection, configDirection = "inactive") => {
  switch (remoteDirection) {
    case "sendonly":
      return configDirection.includes("recv") ? configDirection : "recvonly";
    case "recvonly":
      return configDirection.includes("send") ? configDirection : "sendonly";
    case "sendrecv":
      return "sendrecv";
    default:
      return configDirection;
  }
};
function createPeerConnection(options = {}, peerConnection) {
  const props = {
    bandwidth: options.bandwidth ?? 0,
    offerOptions: options.offerOptions,
    answerOptions: options.answerOptions,
    references: {
      module: "PeerConnection",
      createdAt: (/* @__PURE__ */ new Date()).toISOString()
    },
    allowCodecSdpMunging: Boolean(options.allowCodecSdpMunging),
    // Only used when `restartIce` is not available
    iceRestartNeeded: false,
    initialConnectionDone: false,
    makingOffer: false,
    ignoreNegotiationNeeded: false,
    pendingOffer: void 0,
    transceiversDirection: options.transceiversDirection,
    allowVP9: options.allowVP9 ?? true,
    allow1080p: options.allow1080p,
    allow4kPreso: options.allow4kPreso,
    currentRemoteDescription: void 0,
    localFingerprints: [],
    remoteFingerprints: []
  };
  const mainTranseivers = new Proxy({}, {
    set: (target, p, value) => {
      const didUpdate = Reflect.set(target, p, value);
      if (didUpdate) {
        eventHandlers.onTransceiverChange?.();
      }
      return didUpdate;
    }
  });
  const presoTranseivers = new Proxy({}, {
    set: (target, p, value) => {
      const didUpdate = Reflect.set(target, p, value);
      if (didUpdate) {
        eventHandlers.onTransceiverChange?.();
      }
      return didUpdate;
    }
  });
  const transceivers = {
    main: mainTranseivers,
    preso: presoTranseivers
  };
  let localMainStream;
  let localPresentationStream;
  let remoteStreams = [];
  let remoteMediaLines = [];
  const timers = {};
  const clearTimer = (timerKey, onDone) => {
    if (timers[timerKey]) {
      clearTimeout(timers[timerKey]);
      timers[timerKey] = void 0;
      onDone?.();
    }
  };
  const eventHandlers = {};
  const peer = peerConnection ?? new RTCPeerConnection(options.rtcConfig);
  if (typeof peer.peerIdentity === "object") {
    peer.peerIdentity.catch((error) => {
      logger.error({ error });
      peer.close();
    });
  }
  const log = createRefsLog(() => ({
    ...logReferences(props.references),
    ...getPeerConnectionStates(peer),
    props
  }));
  const associateStream = ({
    transceiver,
    stream,
    sdp,
    sdpOptions
  }) => {
    const sdpManager = sdp instanceof SdpTransformManager ? sdp : new SdpTransformManager(sdp, sdpOptions);
    sdpManager.addMsidToMline(transceiver, stream.id);
  };
  const associateStreamWithSDP = (contentType, sdp, sdpOptions) => {
    const stream = contentType === "main" ? localMainStream : localPresentationStream;
    if (!stream) {
      return;
    }
    Object.values(transceivers[contentType] ?? []).forEach((transceiver) => {
      logger.debug(
        {
          sdp,
          options: sdpOptions,
          contentType,
          stream,
          currentLocalDescription: peer.currentLocalDescription,
          transceivers: peer.getTransceivers(),
          transceiver
        },
        "Manual associate stream with SDP"
      );
      associateStream({
        transceiver,
        stream,
        sdp,
        sdpOptions
      });
    });
  };
  const associateStreamWithSetStreams = (stream, contentType) => {
    if (!hasSetStreams || !stream) {
      return;
    }
    Object.values(transceivers[contentType] ?? []).forEach((transceiver) => {
      log.debug("associate stream with setStreams", {
        transceivers: peer.getTransceivers(),
        transceiver,
        stream,
        contentType,
        track: transceiver.sender.track
      });
      transceiver.sender.setStreams(stream);
    });
  };
  const changeDirection = (contentType, mediaType, direction) => {
    logger.debug({ contentType, mediaType, direction }, "changeDirection");
    const transceiver = transceivers[contentType][mediaType];
    const nextDirection = changeTransceiverDirection(
      props.transceiversDirection?.[contentType]?.[mediaType] ?? "inactive",
      direction
    );
    const transDirection = props.transceiversDirection?.[contentType];
    if (transDirection?.[mediaType] && transDirection[mediaType] !== nextDirection) {
      transDirection[mediaType] = nextDirection;
    }
    if (!transceiver) {
      return;
    }
    if (transDirection?.[mediaType] && transceiver.currentDirection === nextDirection) {
      logger.debug(
        { currentDirection: transceiver.currentDirection, nextDirection },
        "no need to change direction"
      );
      return;
    }
    if (transceiver.currentDirection !== nextDirection) {
      transceiver.direction = nextDirection;
    }
  };
  const syncSenderTrack = (stream, contentType = "main") => (
    /**
     *
     * @param sender - the established sender if available
     * @param track - the track to sync
     */
    async (transceiver, track, intentDirection = "send") => {
      if (transceiver?.sender.track === track) {
        return;
      }
      const context = {
        contentType,
        stream,
        transceiver,
        transceivers,
        peerTransceivers: peer.getTransceivers(),
        track,
        localPresentationStream,
        peer
      };
      if (track) {
        if (transceiver) {
          log.debug("replaceTrack", context);
          await transceiver.sender.replaceTrack(track);
          associateStreamWithSetStreams(stream, contentType);
          changeDirection(
            contentType,
            track.kind,
            intentDirection
          );
          return;
        }
        log.debug("addTransceiver/addTrack", context);
        const transceiverType = track.kind;
        const direction = changeTransceiverDirection(
          props.transceiversDirection?.[contentType]?.[transceiverType] ?? "sendrecv",
          intentDirection
        );
        transceivers[contentType][transceiverType] = peer.addTransceiver(track, {
          streams: stream ? [stream] : [],
          direction
        });
        return;
      }
      if (transceiver) {
        log.debug("removeTrack", context);
        await transceiver.sender.replaceTrack(null);
      }
    }
  );
  const warmup = (direction = "inactive") => {
    logger.info("Warmup connection");
    TRANSCEIVER_CONTENT_TYPES.forEach(
      (contentType) => TRANSCEIVER_MEDIA_TYPES.forEach((mediaType) => {
        if (props.transceiversDirection?.[contentType]?.[mediaType] && !transceivers[contentType][mediaType]) {
          transceivers[contentType][mediaType] = peer.addTransceiver(
            mediaType,
            { direction }
          );
        }
      })
    );
  };
  const setLocalStream = async (mediaStream, contentType = "main", intentDirection = "send") => {
    log.info("call setLocalStream", { mediaStream, contentType });
    const syncTrack = syncSenderTrack(mediaStream, contentType);
    if (!mediaStream) {
      if (contentType === "main") {
        localMainStream = void 0;
      }
      if (contentType === "preso") {
        localPresentationStream = void 0;
      }
      for (const transceiver of Object.values(
        transceivers[contentType] ?? []
      )) {
        await syncTrack(transceiver, void 0, intentDirection);
      }
    } else if (mediaStream.getTracks().some((track) => track.readyState === "live")) {
      if (contentType === "main") {
        localMainStream = mediaStream;
      }
      if (contentType === "preso") {
        localPresentationStream = mediaStream;
      }
      if (!props.initialConnectionDone) {
        return;
      }
      for (const mediaType of TRANSCEIVER_MEDIA_TYPES) {
        if (props.transceiversDirection?.[contentType]?.[mediaType]) {
          const track = mediaStream.getTracks().find((track2) => track2.kind === mediaType);
          await syncTrack(
            transceivers[contentType][mediaType],
            track,
            intentDirection
          );
        }
      }
    } else {
      log.warn(
        "[setLocalStream] No track or no track is live from the stream",
        { stream: mediaStream, contentType }
      );
    }
  };
  const negotiate = () => {
    clearTimer("negotiate");
    if (eventHandlers.negotiationNeeded) {
      if (!props.ignoreNegotiationNeeded && !props.makingOffer && peer.signalingState === "stable") {
        log.info("call negotiationneeded handler");
        eventHandlers.negotiationNeeded();
      } else {
        timers.negotiate = setTimeout(negotiate);
        log.info("defer negotiation");
      }
    } else {
      log.warn(
        "[negotiate] try to negotiate but no negotiationneeded handler"
      );
    }
  };
  const fallbackRestartIce = () => {
    log.info("[restartIce fallback] trigger negotiationneeded manually");
    props.offerOptions = { ...props.offerOptions, iceRestart: true };
    props.iceRestartNeeded = false;
    negotiate();
  };
  const restartIce = () => {
    if (!eventHandlers.onIceCandidate) {
      return;
    }
    clearTimer("connectionState", () => {
      log.debug("clears connectionStateTimer due to iceRestart");
    });
    if (peer.restartIce !== void 0) {
      log.info("restartIce");
      peer.restartIce();
    } else {
      if (peer.signalingState === "stable") {
        log.info("restartIce fallback");
        fallbackRestartIce();
      } else {
        props.iceRestartNeeded = true;
        log.info("restartIce fallback deferred");
      }
    }
  };
  const calculateSecureCheckCode = async () => {
    log.info("calculateSecureCheckCode");
    if (props.localFingerprints.length && props.remoteFingerprints.length) {
      const local = props.localFingerprints.map(({ type, hash }) => type + hash).sort().join("");
      const remote = props.remoteFingerprints.map(({ type, hash }) => type + hash).sort().join("");
      const msg = [local, remote].sort().join("");
      const msgBuffer = new TextEncoder().encode(msg);
      const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
      eventHandlers.onSecureCheckCode?.(hashHex);
    }
  };
  const syncTransceivers = async (mediaLines) => {
    clearTimer("syncTransceivers");
    const currentTransceivers = peer.getTransceivers();
    log.info("Sync transceivers", {
      currentTransceivers,
      transceivers,
      mediaLines,
      localMainStream
    });
    currentTransceivers.forEach((trans) => {
      if (!trans.mid) {
        return;
      }
      const media = mediaLines.find(
        (media2) => String(media2.mid) === trans.mid
      );
      const mediaType = media?.type;
      if (!media || !isMediaType(mediaType)) {
        return;
      }
      const contentType = isPreso(media) ? "preso" : "main";
      const currentTrans = transceivers[contentType][mediaType];
      const transDirection = props.transceiversDirection?.[contentType];
      if (transDirection) {
        transDirection[mediaType] = getDirection(
          media.direction,
          transDirection[mediaType]
        );
      }
      if (currentTrans?.mid !== trans.mid) {
        transceivers[contentType][mediaType] = trans;
      }
    });
    try {
      props.ignoreNegotiationNeeded = true;
      for (const contentType of TRANSCEIVER_CONTENT_TYPES) {
        for (const mediaType of TRANSCEIVER_MEDIA_TYPES) {
          const preferredDirection = props.transceiversDirection?.[contentType]?.[mediaType];
          if (preferredDirection) {
            const transceiver = transceivers[contentType][mediaType];
            const stream = contentType === "main" ? localMainStream : localPresentationStream;
            if (!transceiver) {
              const [track] = stream?.getTracks().filter((t) => t.kind === mediaType) ?? [];
              transceivers[contentType][mediaType] = peer.addTransceiver(track ?? mediaType, {
                direction: preferredDirection,
                streams: [stream].flatMap(
                  (s) => s ? [s] : []
                )
              });
              log.debug("Sync missing transceiver", {
                transceiver,
                contentType,
                mediaType,
                direction: preferredDirection,
                stream,
                track
              });
            } else {
              if (stream) {
                const track = stream.getTracks().find((track2) => track2.kind === mediaType);
                const syncTrack = syncSenderTrack(
                  stream,
                  contentType
                );
                await syncTrack(transceiver, track);
              }
              if (transceiver.direction !== preferredDirection) {
                log.debug(
                  `Sync transceiver direction ${transceiver.direction} => ${preferredDirection}`,
                  {
                    transceiver,
                    contentType,
                    mediaType
                  }
                );
                transceiver.direction = preferredDirection;
              }
            }
          }
        }
      }
    } finally {
      props.ignoreNegotiationNeeded = false;
      props.initialConnectionDone = true;
    }
  };
  const queueAndSyncTransceivers = () => {
    clearTimer("syncTransceivers");
    timers.syncTransceivers = setTimeout(() => {
      void syncTransceivers(remoteMediaLines);
    }, 0);
  };
  peer.oniceconnectionstatechange = (event) => {
    log.info("oniceconnectionstatechange handler", { event });
    clearTimer("iceConnectionState", () => {
      log.debug("clears the restart ice timer", { event });
    });
    if (peer.iceConnectionState === "failed") {
      log.debug("restarts ice because it went to failed", { event });
      restartIce();
    } else if (peer.iceConnectionState === "disconnected") {
      log.debug("scheduls ice restart because it went to disconnected", {
        event
      });
      timers.iceConnectionState = window.setTimeout(
        restartIce,
        2e3 /* IceConnectionState */
      );
    }
    if (eventHandlers.iceConnectionStateChange) {
      eventHandlers.iceConnectionStateChange(event);
    }
  };
  peer.onsignalingstatechange = (event) => {
    log.info("onsignalingstatechange handler", { event });
    if (peer.signalingState === "stable") {
      if (props.iceRestartNeeded && eventHandlers.onIceCandidate) {
        fallbackRestartIce();
      }
    }
    if (eventHandlers.signalingStateChange) {
      eventHandlers.signalingStateChange(event);
    }
  };
  peer.onicecandidate = (event) => {
    if (!eventHandlers.onIceCandidate) {
      return;
    }
    log.info("onicecandidate handler", { event });
    eventHandlers.onIceCandidate(event);
  };
  peer.onnegotiationneeded = () => {
    log.info("onnegotiationneeded handler", { eventHandlers });
    if (eventHandlers.negotiationNeeded) {
      negotiate();
    }
  };
  peer.ontrack = (event) => {
    log.info("ontrack", {
      event,
      remoteStreams,
      remoteMediaLines,
      transceivers
    });
    const mline = remoteMediaLines.find(
      (mediaLine) => String(mediaLine.mid) === event.transceiver.mid
    );
    if (!mline) {
      logger.warn(
        {
          remoteMediaLines,
          event
        },
        "Unable to find respective media line"
      );
      eventHandlers.onTrack?.(event);
      return;
    }
    const contentType = isPreso(mline) ? "preso" : "main";
    const handler = contentType === "preso" ? eventHandlers?.onRemoteContentStreams : eventHandlers?.onRemoteStreams;
    event.streams.forEach((stream) => {
      if (!remoteStreams.includes(stream)) {
        remoteStreams.push(stream);
        handler?.([stream]);
      }
    });
    if (!event.streams.length) {
      const stream = new MediaStream([event.track]);
      remoteStreams.push(stream);
      handler?.([stream]);
    }
    eventHandlers.onTrack?.(event);
  };
  return {
    get peer() {
      return peer;
    },
    get iceGatheringState() {
      return peer.iceGatheringState;
    },
    get iceConnectionState() {
      return peer.iceConnectionState;
    },
    get signalingState() {
      return peer.signalingState;
    },
    get connectionState() {
      return peer.connectionState;
    },
    get localStream() {
      return localMainStream;
    },
    get remoteStreams() {
      return remoteStreams;
    },
    get senders() {
      return peer.getSenders();
    },
    get receivers() {
      return peer.getReceivers();
    },
    getTransceiver(type, contentType = "main") {
      return transceivers[contentType][type];
    },
    get hasICECandidates() {
      return hasICECandidates(
        peer.pendingLocalDescription?.sdp ?? peer.currentLocalDescription?.sdp
      );
    },
    get currentLocalDescription() {
      if (peer.currentLocalDescription !== void 0) {
        return peer.currentLocalDescription;
      }
      return peer.localDescription;
    },
    get pendingLocalDescription() {
      if (peer.pendingLocalDescription !== void 0) {
        return peer.pendingLocalDescription ?? void 0;
      }
      return peer.signalingState === "stable" || peer.localDescription === null ? void 0 : peer.localDescription;
    },
    get currentRemoteDescription() {
      if (peer.currentRemoteDescription !== void 0) {
        return peer.currentRemoteDescription;
      }
      return peer.remoteDescription;
    },
    get pendingRemoteDescription() {
      if (peer.pendingRemoteDescription !== void 0) {
        return peer.pendingRemoteDescription;
      }
      return peer.signalingState === "stable" ? null : peer.remoteDescription;
    },
    get bandwidth() {
      return props.bandwidth;
    },
    get references() {
      return props.references;
    },
    get offerOptions() {
      return props.offerOptions;
    },
    set offerOptions(newOptions) {
      props.offerOptions = { ...props.offerOptions, ...newOptions };
    },
    get answerOptions() {
      return props.answerOptions;
    },
    set answerOptions(newOptions) {
      log.info("set answerOptions", {
        newOptions,
        answerOptions: props.answerOptions
      });
      props.answerOptions = { ...props.answerOptions, ...newOptions };
    },
    set bandwidth(bandwidth) {
      log.info("set bandwidth", {
        newBandwidth: bandwidth,
        bandwidth: props.bandwidth
      });
      if (props.bandwidth !== bandwidth) {
        props.bandwidth = bandwidth;
        negotiate();
      }
    },
    set negotiationNeeded(needed) {
      if (needed) {
        negotiate();
      }
    },
    getStats: async (selector) => await peer.getStats(selector),
    setLocalStream,
    warmup,
    changeDirection,
    setReference(key, value) {
      log.info("call setReference", {
        references: props.references,
        key,
        value
      });
      props.references[key] = value;
    },
    createDataChannel: (label, dataChannelDict) => {
      log.info("call createDataChannel", { label, dataChannelDict });
      return peer.createDataChannel(label, dataChannelDict);
    },
    createOffer: async (offerOptions) => {
      log.info("call createOffer", { param: offerOptions, peer });
      props.makingOffer = true;
      try {
        const sdp = await peer.createOffer({
          ...props.offerOptions,
          ...offerOptions
        });
        const sdpManager = new SdpTransformManager(sdp, {
          allow1080p: props.allow1080p,
          allow4kPreso: props.allow4kPreso,
          allowCodecSdpMunging: props.allowCodecSdpMunging,
          allowVP9: props.allowVP9,
          contentSlides: transceivers.preso.video?.mid ?? Boolean(props.transceiversDirection?.preso?.video),
          videoAS: props.bandwidth
        });
        let offer = sdpManager.getSdp();
        if (peer.signalingState !== "stable") {
          throw new Error("Ignore");
        }
        await peer.setLocalDescription(offer);
        if (!hasSetStreams) {
          associateStreamWithSDP("main", sdpManager);
          associateStreamWithSDP("preso", sdpManager);
        }
        offer = sdpManager.getSdp();
        log.info("setLocalDescription with offer success", {
          rawSDP: sdp,
          offer
        });
        props.localFingerprints = sdpManager.getFingerprints();
        await calculateSecureCheckCode();
        return offer;
      } finally {
        props.makingOffer = false;
      }
    },
    createAnswer: async (answerOptions) => {
      log.info("call createAnswer", { param: answerOptions });
      const sdp = await peer.createAnswer({
        ...props.answerOptions,
        ...answerOptions
      });
      const sdpManager = new SdpTransformManager(sdp, {
        allow4kPreso: props.allow4kPreso,
        allowCodecSdpMunging: props.allowCodecSdpMunging,
        allowVP9: props.allowVP9,
        contentSlides: transceivers.preso.video?.mid ?? Boolean(props.transceiversDirection?.preso?.video),
        videoAS: props.bandwidth
      });
      let answer = sdpManager.getSdp();
      await peer.setLocalDescription(answer);
      if (!hasSetStreams) {
        associateStreamWithSDP("main", sdpManager);
        associateStreamWithSDP("preso", sdpManager);
      }
      answer = sdpManager.getSdp();
      log.info("setLocalDescription with answer success", {
        rawSDP: sdp,
        answer
      });
      props.localFingerprints = sdpManager.getFingerprints();
      await calculateSecureCheckCode();
      return answer;
    },
    receiveAnswer: async (answer) => {
      log.info("call receiveAnswer", { answer });
      remoteStreams = [];
      remoteMediaLines = getMediaLines(answer.sdp);
      const sdpManager = new SdpTransformManager(answer, {
        allowCodecSdpMunging: props.allowCodecSdpMunging,
        allowVP9: props.allowVP9,
        videoAS: props.bandwidth,
        videoTIAS: props.bandwidth * 1e3
      });
      await peer.setRemoteDescription(sdpManager.getSdp());
      queueAndSyncTransceivers();
      log.debug("setRemoteDescription success", {
        currentTransceivers: peer.getTransceivers(),
        transceivers
      });
      props.remoteFingerprints = sdpManager.getFingerprints();
      await calculateSecureCheckCode();
      props.currentRemoteDescription = answer;
    },
    receiveOffer: async (offer) => {
      log.info("call receiveOffer", { offer });
      remoteStreams = [];
      remoteMediaLines = getMediaLines(offer.sdp);
      props.currentRemoteDescription = offer;
      props.remoteFingerprints = new SdpTransformManager(
        offer
      ).getFingerprints();
      await peer.setRemoteDescription(offer);
      if (peer.getTransceivers().length) {
        await syncTransceivers(remoteMediaLines);
      }
      await calculateSecureCheckCode();
    },
    receiveIceCandidate: async (candidate) => {
      log.info("call receiveIceCandidate", { candidate });
      await peer.addIceCandidate(candidate);
    },
    restartIce,
    getConfiguration: () => peer.getConfiguration(),
    setConfiguration: typeof peer.setConfiguration === "function" ? (rtcConfig) => peer.setConfiguration(rtcConfig) : void 0,
    close: () => {
      log.info("call close");
      Object.keys(timers).forEach((key) => {
        clearTimer(key);
      });
      peer.close();
      localMainStream = void 0;
      localPresentationStream = void 0;
      remoteStreams = [];
    },
    // Event handlers
    set onConnectionStateChange(handler) {
      peer.onconnectionstatechange = (event) => {
        clearTimer("connectionState", () => {
          log.debug("clears the connection state timer", { event });
        });
        if (peer.connectionState === "disconnected") {
          log.debug(
            "waits for emitting connection state change because it is disconnected",
            { event }
          );
          timers.connectionState = window.setTimeout(() => {
            log.debug(
              "emits connection state change after waiting",
              { event }
            );
            handler?.(event);
          }, 5e3 /* ConnectionState */);
        } else {
          handler?.(event);
        }
      };
    },
    set onDataChannel(handler) {
      peer.ondatachannel = handler;
    },
    set onIceCandidate(handler) {
      eventHandlers.onIceCandidate = handler;
    },
    set onIceCandidateError(handler) {
      peer.onicecandidateerror = handler;
    },
    set onIceConnectionStateChange(handler) {
      eventHandlers.iceConnectionStateChange = handler;
    },
    set onIceGatheringStateChange(handler) {
      peer.onicegatheringstatechange = handler ?? null;
    },
    set onNegotiationNeeded(handler) {
      eventHandlers.negotiationNeeded = handler;
    },
    set onSignalingStateChange(handler) {
      eventHandlers.signalingStateChange = handler;
    },
    set onTransceiverChange(handler) {
      eventHandlers.onTransceiverChange = handler;
    },
    set onSecureCheckCode(handler) {
      eventHandlers.onSecureCheckCode = handler;
    },
    set onTrack(handler) {
      eventHandlers.onTrack = handler;
    },
    set onRemoteStreams(handler) {
      eventHandlers.onRemoteStreams = handler;
    },
    set onRemoteContentStreams(handler) {
      eventHandlers.onRemoteContentStreams = handler;
    }
  };
}
function createMainPeerConnection(signals, options = {}) {
  const peer = createPeerConnection({
    transceiversDirection: {
      main: {
        audio: "sendrecv",
        video: "sendrecv"
      }
    },
    ...options
  });
  peer.setReference("module", "MainPeerConnection");
  const { onOfferRequired, ...restSignals } = signals;
  const log = createRefsLog(createGetRefs(peer));
  let subscriptions = [
    // Handler common signals
    ...withSignals(peer)(restSignals),
    onOfferRequired.add(async (stream) => {
      log.info("handle onOfferRequired signal", {
        localStream: peer.localStream,
        newStream: stream
      });
      if (stream) {
        try {
          await peer.setLocalStream(stream);
        } catch (error) {
          logger.error({ error }, "setLocalStream failed");
          peer.negotiationNeeded = true;
        }
      } else {
        if (peer.peer.getTransceivers().some((tran) => tran.currentDirection !== "stopped")) {
          peer.negotiationNeeded = true;
        } else {
          peer.warmup();
        }
      }
    })
  ];
  const cleanup = () => {
    subscriptions = subscriptions.flatMap((unsubscribe) => {
      unsubscribe();
      return [];
    });
  };
  return {
    get peer() {
      return peer.peer;
    },
    get connectionState() {
      return peer.connectionState;
    },
    get iceConnectionState() {
      return peer.iceConnectionState;
    },
    get iceGatheringState() {
      return peer.iceGatheringState;
    },
    get signalingState() {
      return peer.signalingState;
    },
    get localStream() {
      return peer.localStream;
    },
    get remoteStreams() {
      return peer.remoteStreams;
    },
    get senders() {
      return peer.senders;
    },
    get receivers() {
      return peer.receivers;
    },
    get hasICECandidates() {
      return peer.hasICECandidates;
    },
    get bandwidth() {
      return peer.bandwidth;
    },
    get references() {
      return peer.references;
    },
    set bandwidth(bandwidth) {
      peer.bandwidth = bandwidth;
    },
    get offerOptions() {
      return peer.offerOptions;
    },
    get answerOptions() {
      return peer.answerOptions;
    },
    set offerOptions(newOptions) {
      peer.offerOptions = newOptions;
    },
    set answerOptions(newOptions) {
      peer.answerOptions = newOptions;
    },
    // Methods
    setLocalStream: peer.setLocalStream,
    warmup: peer.warmup,
    changeDirection: peer.changeDirection,
    setReference(key, value) {
      peer.setReference(key, value);
    },
    getStats: (selector) => peer.getStats(selector),
    getTransceiver: (type, contentType) => peer.getTransceiver(type, contentType),
    createDataChannel: (label, dataChannelDict) => peer.createDataChannel(label, dataChannelDict),
    restartIce: peer.restartIce,
    getConfiguration: peer.getConfiguration,
    setConfiguration: peer.setConfiguration,
    close: () => {
      cleanup();
      peer.close();
    }
  };
}
export {
  REQUIRED_SIGNAL_KEYS,
  RecoveryTimeout,
  createGetRefs,
  createMainPeerConnection,
  createPCSignal,
  createPCSignals,
  createPeerConnection,
  createRefsLog,
  getCreateLoopbackConnectionFn,
  getPeerConnectionStates,
  getStatesAndProps,
  isMediaType,
  isSameStream,
  logReferences,
  setLogger,
  wirePeerConnectionEventHandler,
  wirePeerConnectionEvents,
  withSignals
};

import React from 'react';
import {useTranslation} from 'react-i18next';

import {CenterLayout, Section} from '@pexip/components';
import type {RouteMatch} from '@pexip/router';

import {BrandedFullSizeWindow} from '../viewModels/BrandedFullSizeWindow.viewModel';
import {Header} from '../viewModels/Header.viewModel';
import {TestId} from '../../test/testIds';

export const PageNotFound: React.FC<{match: RouteMatch}> = () => {
    const {t} = useTranslation();

    return (
        <BrandedFullSizeWindow
            padding="none"
            background="dark"
            flexDirection="column"
            justifyContent="flexStart"
        >
            <Header />
            <CenterLayout className="p-3">
                <Section
                    data-testid={TestId.NotFoundMessage}
                    title={t('error.not-found.title', 'Oops! 404 - Not Found')}
                    subtitle={t(
                        'error.not-found.subtitle',
                        "We can't seem to find the page you're looking for.",
                    )}
                />
            </CenterLayout>
        </BrandedFullSizeWindow>
    );
};

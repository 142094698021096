import React from 'react';
import {useTranslation} from 'react-i18next';

import {BadgeCounter, IconTypes, useLgDown} from '@pexip/components';
import {ResponsiveButton} from '@pexip/media-components';

import {TestId} from '../../../test/testIds';

export const ParticipantsPanelToggleView: React.FC<
    React.ComponentProps<'button'> & {
        inMeetingCount: number;
        isPanelOpen: boolean;
        participantsActionCount?: number;
        shouldAnimate?: boolean;
    }
> = ({
    inMeetingCount = 0,
    isPanelOpen,
    participantsActionCount = 0,
    shouldAnimate,
    ...props
}) => {
    const {t} = useTranslation();
    const isLgDown = useLgDown();
    return (
        <BadgeCounter
            data-testid={TestId.BadgeCounterParticipantPanelButton}
            isTruncated={participantsActionCount > 9}
            isVisible={!isPanelOpen && participantsActionCount > 0}
            number={participantsActionCount}
            shouldAnimate={shouldAnimate}
        >
            <ResponsiveButton
                aria-label={t(
                    'meeting.toggle-participants-panel',
                    'Toggle participants panel',
                )}
                data-testid={TestId.ButtonParticipants}
                iconSource={IconTypes.IconParticipant}
                isActive={isPanelOpen}
                hideChildren={isLgDown}
                {...props}
            >
                {inMeetingCount === 1
                    ? t('meeting.one-participant', '1 Participant')
                    : t('meeting.more-participants', {
                          defaultValue: '{{inMeetingCount}} Participants',
                          inMeetingCount,
                      })}
            </ResponsiveButton>
        </BadgeCounter>
    );
};

export type ParticipantsToggleButtonProps = React.ComponentProps<
    typeof ParticipantsPanelToggleView
>;

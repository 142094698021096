import React from 'react';

import {BrandedFullSizeWindow} from '../../viewModels/BrandedFullSizeWindow.viewModel';
import {Header} from '../../viewModels/Header.viewModel';
import {PreflightLayout} from '../../views/PreflightLayout/PreflightLayout.view';
import {BrandedInfoCard} from '../../viewModels/BrandedInfoCard.viewModel';
import {DisplayName} from '../../viewModels/DisplayName.viewModel';
import {TestId} from '../../../test/testIds';

export const UserName: React.FC = () => (
    <BrandedFullSizeWindow
        padding="none"
        background="image"
        flexDirection="column"
        justifyContent="flexStart"
        data-testid={TestId.UserNamePage}
    >
        <Header />
        <PreflightLayout
            startContent={<BrandedInfoCard />}
            endContent={<DisplayName />}
        />
    </BrandedFullSizeWindow>
);

export type UserNameProps = React.ComponentProps<typeof UserName>;

import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import type {ButtonProps} from '@pexip/components';
import {Button, onEnter} from '@pexip/components';

import styles from './JoinMeetingButton.module.scss';

export const JoinMeetingButton: React.FC<
    ButtonProps & {
        isDisabled?: boolean;
        spacing?: 'large' | 'medium';
        onClick: () => void;
    }
> = ({isDisabled, onClick, className, spacing = 'medium', ...props}) => {
    const {t} = useTranslation();

    return (
        <Button
            className={cx(
                styles.joinMeetingButtonWrapper,
                spacing === 'medium' ? 'mt-2' : 'mt-3',
                className,
            )}
            isDisabled={isDisabled}
            onClick={onClick}
            onKeyDown={onEnter(onClick)}
            size="large"
            type="submit"
            variant="success"
            {...props}
        >
            {t('common.join', 'Join now')}
        </Button>
    );
};

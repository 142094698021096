import React from 'react';
import cx from 'classnames';

import {CoreHeader, Group} from '@pexip/components';

import styles from '../styles/CoreHeader.module.scss';

export const HeaderView: React.FC<
    React.ComponentProps<typeof CoreHeader> & {
        userMenu: React.ReactElement;
    }
> = ({userMenu, ...props}) => (
    <CoreHeader
        enhancerFixedWidth
        enhancerEnd={
            <Group
                spacing="small"
                className={cx(styles.headerEnhancer, styles.enhancerEnd)}
            >
                {userMenu}
            </Group>
        }
        hasBottomBorder={false}
        {...props}
    />
);

export type HeaderViewProps = React.ComponentProps<typeof HeaderView>;

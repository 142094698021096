import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {PopOutWindow} from '@pexip/components';
import {useToggleFullscreen} from '@pexip/hooks';

export {ExternalVideo} from '../views/MeetingVideo/ExternalVideo.view';

import {StreamStatus} from '../types';
import {PoppedOutEmptyPresentation} from '../views/PoppedOutEmptyPresentation/PoppedOutEmptyPresentation.view';
import {ExternalVideo} from '../views/MeetingVideo/ExternalVideo.view';
import {DraggableMeetingVideo} from '../views/MeetingVideo/DraggableMeetingVideo.view';

export const PresentationMeetingVideo: React.FC<{
    status: StreamStatus;
    localIsPresenting: boolean;
    localMediaStream?: MediaStream;
    remoteIsPresenting: boolean;
    remoteMediaStream?: MediaStream;
    remotePopOut?: Window;
    handleVideoClick: () => void;
    setIsPresentationBrowserPip: React.Dispatch<React.SetStateAction<boolean>>;
    setIsPresentationPoppedOut: React.Dispatch<React.SetStateAction<boolean>>;
    isSidePanelVisible: boolean;
    floatRoot?: React.RefObject<HTMLDivElement>;
}> = ({
    status,
    localIsPresenting,
    localMediaStream,
    remoteIsPresenting,
    remoteMediaStream,
    remotePopOut,
    handleVideoClick,
    setIsPresentationBrowserPip,
    setIsPresentationPoppedOut,
    isSidePanelVisible,
    floatRoot,
}) => {
    const {t} = useTranslation();
    const toggleFullScreen = useToggleFullscreen();
    const isPresenting = remoteIsPresenting || localIsPresenting;
    const disableDualScreenMode = useCallback(() => {
        setIsPresentationPoppedOut(false);
    }, [setIsPresentationPoppedOut]);

    if (status === StreamStatus.External) {
        return (
            <PopOutWindow
                onUnload={disableDualScreenMode}
                remotePopOut={remotePopOut}
                title={t(
                    'media.presentation.pexip-presentation',
                    'Presentation Window',
                )}
            >
                {isPresenting ? (
                    <ExternalVideo
                        handleVideoClick={handleVideoClick}
                        isFullHeight
                        isFullWidth
                        isPresentation
                        mediaStream={
                            localIsPresenting
                                ? localMediaStream
                                : remoteIsPresenting
                                ? remoteMediaStream
                                : undefined
                        }
                        onPictureInPictureChange={setIsPresentationBrowserPip}
                        status={status}
                    />
                ) : (
                    <PoppedOutEmptyPresentation
                        onClose={disableDualScreenMode}
                    />
                )}
            </PopOutWindow>
        );
    }
    return (
        <DraggableMeetingVideo
            handleVideoClick={handleVideoClick}
            isPresentation
            mediaStream={
                localIsPresenting
                    ? localMediaStream
                    : remoteIsPresenting
                    ? remoteMediaStream
                    : undefined
            }
            onDoubleClick={toggleFullScreen}
            onPictureInPictureChange={setIsPresentationBrowserPip}
            status={status}
            isSidePanelVisible={isSidePanelVisible}
            floatRoot={floatRoot}
        />
    );
};

import {useCallback, useEffect, useRef} from 'react';

import {autoHideUIInterfacesStateSignal} from '@pexip/hooks';
import type {Signal} from '@pexip/signal';

import {initMeetingUIState} from '../utils/meetingUIState';
import type {
    AutoHideButtonCallbacks,
    EnableInMeetingUIAutoHideState,
} from '../types';
import {InMeetingUIElements} from '../types';

const meetingUIState = initMeetingUIState();

/**
 * Returns an callback update function for `InMeetingUIState`.
 */
export const useUpdateMeetingUIStateCallback = (
    enableAutoHide: (value: React.SetStateAction<boolean>) => void,
) =>
    useCallback(
        (isFocused: boolean, propName: string) => {
            meetingUIState.setMeetingUIState({
                ...meetingUIState.currentState,
                [propName]: isFocused,
            });

            enableAutoHide(meetingUIState.shouldEnableAutoHide());
        },
        [enableAutoHide],
    );

/**
 * Updates `InMeetingUIState` for any dialogs/user-menu and meeting panels state changes.
 *
 * Example: About dialog is open, Chat panel is open and etc.
 */
export const useMeetingComponentStateChanges = (
    updateUIState: (isFocused: boolean, propName: string) => void,
    showSidePanel: boolean,
    enableInMeetingUIAutoHide: Signal<EnableInMeetingUIAutoHideState>,
) => {
    useEffect(() => {
        updateUIState(showSidePanel, InMeetingUIElements.openedPanel);
    }, [showSidePanel, updateUIState]);

    useEffect(
        () =>
            enableInMeetingUIAutoHide.add(dialog => {
                updateUIState(dialog.isOpen, dialog.type);

                if (
                    dialog.type === InMeetingUIElements.userMenu &&
                    !dialog.isOpen
                ) {
                    updateUIState(false, InMeetingUIElements.focusedButton);
                }
            }),
        [enableInMeetingUIAutoHide, updateUIState],
    );
};

/**
 * Returns `AutoHideButtonCallbacks` with `onFocus/onBlur/onMouseEnter/onMouseLeave` events.
 */
export const useAutoHideButtonCallbacks = (
    updateUIState: (isFocused: boolean, propName: string) => void,
) => {
    const update = (isFocused: boolean) =>
        updateUIState(isFocused, 'focusedButton');

    return useRef({
        onMouseEnter: () => update(true),
        onFocus: () => update(true),
        onMouseLeave: () => update(false),
        onBlur: () => update(false),
    }).current;
};

/**
 * Encapsulates the logic of updating the `InMeetingUIState`.
 *
 * @returns `AutoHideButtonCallbacks`.
 */
export const useInMeetingUIState = (
    showSidePanel: boolean,
    enableAutoHide: (value: React.SetStateAction<boolean>) => void,
    enableInMeetingUIAutoHide: Signal<EnableInMeetingUIAutoHideState>,
): AutoHideButtonCallbacks => {
    const updateUIState = useUpdateMeetingUIStateCallback(enableAutoHide);

    const autoHideProps = useAutoHideButtonCallbacks(updateUIState);

    useMeetingComponentStateChanges(
        updateUIState,
        showSidePanel,
        enableInMeetingUIAutoHide,
    );

    useEffect(
        () =>
            autoHideUIInterfacesStateSignal.add(uiVisibilityState => {
                meetingUIState.setUIVisibilityState(uiVisibilityState);
            }),
        [],
    );

    return autoHideProps;
};

import type {PreviewStreamController, UserMediaStatus} from '@pexip/media';

import {
    useDeviceErrorMessage,
    useSubscribeToInputError,
    useDeviceErrorMessageState,
} from './useDeviceErrorMessage';

export const usePreviewErrorHandling = (
    controller: PreviewStreamController,
    streamStatus: UserMediaStatus,
) => {
    const {
        videoInputError,
        setVideoInputError,
        audioInputError,
        setAudioInputError,
    } = useDeviceErrorMessageState();

    useSubscribeToInputError(
        setAudioInputError,
        setVideoInputError,
        controller,
    );

    useDeviceErrorMessage(setAudioInputError, setVideoInputError, streamStatus);

    return {
        videoInputError,
        setVideoInputError,
        audioInputError,
        setAudioInputError,
    };
};

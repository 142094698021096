// FILE GENERATED BY packages/components/design-tokens/build-scripts/generate-icons.js DO NOT CHANGE

import IconAdd_16 from './icons/add-16.svg';
import IconAdd_24 from './icons/add-24.svg';
import IconAddSomeone_16 from './icons/add-someone-16.svg';
import IconAddSomeone_24 from './icons/add-someone-24.svg';
import IconAlert_16 from './icons/alert-16.svg';
import IconAlert_24 from './icons/alert-24.svg';
import IconAnalyticsChart_16 from './icons/analytics-chart-16.svg';
import IconAnalyticsChart_24 from './icons/analytics-chart-24.svg';
import IconAnalyticsProgress_16 from './icons/analytics-progress-16.svg';
import IconAnalyticsProgress_24 from './icons/analytics-progress-24.svg';
import IconAndroidLogo_16 from './icons/android-logo-16.svg';
import IconAndroidLogo_24 from './icons/android-logo-24.svg';
import IconAppleLogo_16 from './icons/apple-logo-16.svg';
import IconAppleLogo_24 from './icons/apple-logo-24.svg';
import IconArrowCornerDown_16 from './icons/arrow-corner-down-16.svg';
import IconArrowCornerDown_24 from './icons/arrow-corner-down-24.svg';
import IconArrowDown_16 from './icons/arrow-down-16.svg';
import IconArrowDown_24 from './icons/arrow-down-24.svg';
import IconArrowLeft_16 from './icons/arrow-left-16.svg';
import IconArrowLeft_24 from './icons/arrow-left-24.svg';
import IconArrowLeftRegular_16 from './icons/arrow-left-regular-16.svg';
import IconArrowLeftRegular_24 from './icons/arrow-left-regular-24.svg';
import IconArrowRight_16 from './icons/arrow-right-16.svg';
import IconArrowRight_24 from './icons/arrow-right-24.svg';
import IconArrowUp_16 from './icons/arrow-up-16.svg';
import IconArrowUp_24 from './icons/arrow-up-24.svg';
import IconBackgroundBlur_16 from './icons/background-blur-16.svg';
import IconBackgroundBlur_24 from './icons/background-blur-24.svg';
import IconBackspace_16 from './icons/backspace-16.svg';
import IconBackspace_24 from './icons/backspace-24.svg';
import IconBandwidth_16 from './icons/bandwidth-16.svg';
import IconBandwidth_24 from './icons/bandwidth-24.svg';
import IconBell_16 from './icons/bell-16.svg';
import IconBell_24 from './icons/bell-24.svg';
import IconBilling_16 from './icons/billing-16.svg';
import IconBilling_24 from './icons/billing-24.svg';
import IconBlock_16 from './icons/block-16.svg';
import IconBlock_24 from './icons/block-24.svg';
import IconCalendar_16 from './icons/calendar-16.svg';
import IconCalendar_24 from './icons/calendar-24.svg';
import IconChat_16 from './icons/chat-16.svg';
import IconChat_24 from './icons/chat-24.svg';
import IconCheckmark_16 from './icons/checkmark-16.svg';
import IconCheckmark_24 from './icons/checkmark-24.svg';
import IconCheckmarkRound_16 from './icons/checkmark-round-16.svg';
import IconCheckmarkRound_24 from './icons/checkmark-round-24.svg';
import IconChevronDown_16 from './icons/chevron-down-16.svg';
import IconChevronDown_24 from './icons/chevron-down-24.svg';
import IconChevronLeft_16 from './icons/chevron-left-16.svg';
import IconChevronLeft_24 from './icons/chevron-left-24.svg';
import IconChevronRight_16 from './icons/chevron-right-16.svg';
import IconChevronRight_24 from './icons/chevron-right-24.svg';
import IconChevronUp_16 from './icons/chevron-up-16.svg';
import IconChevronUp_24 from './icons/chevron-up-24.svg';
import IconChromeLogo_16 from './icons/chrome-logo-16.svg';
import IconChromeLogo_24 from './icons/chrome-logo-24.svg';
import IconClock_16 from './icons/clock-16.svg';
import IconClock_24 from './icons/clock-24.svg';
import IconClose_16 from './icons/close-16.svg';
import IconClose_24 from './icons/close-24.svg';
import IconConnectionIndicatorHigh_24 from './icons/connection-indicator-high-24.svg';
import IconConnectionIndicatorLow_24 from './icons/connection-indicator-low-24.svg';
import IconConnectionIndicatorMedium_24 from './icons/connection-indicator-medium-24.svg';
import IconConnectionIndicatorVeryLow_24 from './icons/connection-indicator-very-low-24.svg';
import IconDoubleChevronDown_16 from './icons/double-chevron-down-16.svg';
import IconDoubleChevronDown_24 from './icons/double-chevron-down-24.svg';
import IconDoubleChevronLeft_16 from './icons/double-chevron-left-16.svg';
import IconDoubleChevronLeft_24 from './icons/double-chevron-left-24.svg';
import IconDoubleChevronRight_16 from './icons/double-chevron-right-16.svg';
import IconDoubleChevronRight_24 from './icons/double-chevron-right-24.svg';
import IconDoubleChevronUp_16 from './icons/double-chevron-up-16.svg';
import IconDoubleChevronUp_24 from './icons/double-chevron-up-24.svg';
import IconDraggable_16 from './icons/draggable-16.svg';
import IconDraggable_24 from './icons/draggable-24.svg';
import IconDualScreen_16 from './icons/dual-screen-16.svg';
import IconDualScreen_24 from './icons/dual-screen-24.svg';
import IconEdit_16 from './icons/edit-16.svg';
import IconEdit_24 from './icons/edit-24.svg';
import IconEmoji_16 from './icons/emoji-16.svg';
import IconEmoji_24 from './icons/emoji-24.svg';
import IconExpand_16 from './icons/expand-16.svg';
import IconExpand_24 from './icons/expand-24.svg';
import IconExport_16 from './icons/export-16.svg';
import IconExport_24 from './icons/export-24.svg';
import IconFirefoxLogo_16 from './icons/firefox-logo-16.svg';
import IconFirefoxLogo_24 from './icons/firefox-logo-24.svg';
import IconFlipCamera_16 from './icons/flip-camera-16.svg';
import IconFlipCamera_24 from './icons/flip-camera-24.svg';
import IconFullscreenOff_16 from './icons/fullscreen-off-16.svg';
import IconFullscreenOff_24 from './icons/fullscreen-off-24.svg';
import IconFullscreenOn_16 from './icons/fullscreen-on-16.svg';
import IconFullscreenOn_24 from './icons/fullscreen-on-24.svg';
import IconGroup_16 from './icons/group-16.svg';
import IconGroup_24 from './icons/group-24.svg';
import IconHelpRound_16 from './icons/help-round-16.svg';
import IconHelpRound_24 from './icons/help-round-24.svg';
import IconHome_16 from './icons/home-16.svg';
import IconHome_24 from './icons/home-24.svg';
import IconImport_16 from './icons/import-16.svg';
import IconImport_24 from './icons/import-24.svg';
import IconInfoRound_16 from './icons/info-round-16.svg';
import IconInfoRound_24 from './icons/info-round-24.svg';
import IconLeave_16 from './icons/leave-16.svg';
import IconLeave_24 from './icons/leave-24.svg';
import IconLink_16 from './icons/link-16.svg';
import IconLink_24 from './icons/link-24.svg';
import IconLiveCaption_16 from './icons/live-caption-16.svg';
import IconLiveCaption_24 from './icons/live-caption-24.svg';
import IconLiveStream_16 from './icons/live-stream-16.svg';
import IconLiveStream_24 from './icons/live-stream-24.svg';
import IconLock_16 from './icons/lock-16.svg';
import IconLock_24 from './icons/lock-24.svg';
import IconMail_16 from './icons/mail-16.svg';
import IconMail_24 from './icons/mail-24.svg';
import IconMeetingRoom_16 from './icons/meeting-room-16.svg';
import IconMeetingRoom_24 from './icons/meeting-room-24.svg';
import IconMeetings_16 from './icons/meetings-16.svg';
import IconMeetings_24 from './icons/meetings-24.svg';
import IconMicrophoneOff_16 from './icons/microphone-off-16.svg';
import IconMicrophoneOff_24 from './icons/microphone-off-24.svg';
import IconMicrophoneOn_16 from './icons/microphone-on-16.svg';
import IconMicrophoneOn_24 from './icons/microphone-on-24.svg';
import IconMinus_16 from './icons/minus-16.svg';
import IconMinus_24 from './icons/minus-24.svg';
import IconMoreHorizontal_24 from './icons/more-horizontal-24.svg';
import IconMoreVertical_16 from './icons/more-vertical-16.svg';
import IconMoreVertical_24 from './icons/more-vertical-24.svg';
import IconOpenInNew_16 from './icons/open-in-new-16.svg';
import IconOpenInNew_24 from './icons/open-in-new-24.svg';
import IconParticipant_16 from './icons/participant-16.svg';
import IconParticipant_24 from './icons/participant-24.svg';
import IconPasswordHide_24 from './icons/password-hide-24.svg';
import IconPasswordShow_24 from './icons/password-show-24.svg';
import IconPauseRound_16 from './icons/pause-round-16.svg';
import IconPauseRound_24 from './icons/pause-round-24.svg';
import IconPexipLogo_24 from './icons/pexip-logo-24.svg';
import IconPhone_16 from './icons/phone-16.svg';
import IconPhone_24 from './icons/phone-24.svg';
import IconPlay_16 from './icons/play-16.svg';
import IconPlay_24 from './icons/play-24.svg';
import IconPlayRound_16 from './icons/play-round-16.svg';
import IconPlayRound_24 from './icons/play-round-24.svg';
import IconPlus_16 from './icons/plus-16.svg';
import IconPlus_24 from './icons/plus-24.svg';
import IconPresentationOff_16 from './icons/presentation-off-16.svg';
import IconPresentationOff_24 from './icons/presentation-off-24.svg';
import IconPresentationOn_16 from './icons/presentation-on-16.svg';
import IconPresentationOn_24 from './icons/presentation-on-24.svg';
import IconRaiseHand_16 from './icons/raise-hand-16.svg';
import IconRaiseHand_24 from './icons/raise-hand-24.svg';
import IconRefresh_16 from './icons/refresh-16.svg';
import IconRefresh_24 from './icons/refresh-24.svg';
import IconRemoteControl_24 from './icons/remote-control-24.svg';
import IconSearch_16 from './icons/search-16.svg';
import IconSearch_24 from './icons/search-24.svg';
import IconSend_16 from './icons/send-16.svg';
import IconSend_24 from './icons/send-24.svg';
import IconSettings_16 from './icons/settings-16.svg';
import IconSettings_24 from './icons/settings-24.svg';
import IconSpeakerDisabled_16 from './icons/speaker-disabled-16.svg';
import IconSpeakerDisabled_24 from './icons/speaker-disabled-24.svg';
import IconSpeakerOff_16 from './icons/speaker-off-16.svg';
import IconSpeakerOff_24 from './icons/speaker-off-24.svg';
import IconSpeakerOn_16 from './icons/speaker-on-16.svg';
import IconSpeakerOn_24 from './icons/speaker-on-24.svg';
import IconStar_16 from './icons/star-16.svg';
import IconStar_24 from './icons/star-24.svg';
import IconStopRound_16 from './icons/stop-round-16.svg';
import IconStopRound_24 from './icons/stop-round-24.svg';
import IconSupport_16 from './icons/support-16.svg';
import IconSupport_24 from './icons/support-24.svg';
import IconThumbsDown_24 from './icons/thumbs-down-24.svg';
import IconThumbsUp_24 from './icons/thumbs-up-24.svg';
import IconTranscript_16 from './icons/transcript-16.svg';
import IconTranscript_24 from './icons/transcript-24.svg';
import IconTrash_16 from './icons/trash-16.svg';
import IconTrash_24 from './icons/trash-24.svg';
import IconUnlock_16 from './icons/unlock-16.svg';
import IconUnlock_24 from './icons/unlock-24.svg';
import IconVideoOff_16 from './icons/video-off-16.svg';
import IconVideoOff_24 from './icons/video-off-24.svg';
import IconVideoOn_16 from './icons/video-on-16.svg';
import IconVideoOn_24 from './icons/video-on-24.svg';
import IconVideoSystems_16 from './icons/video-systems-16.svg';
import IconVideoSystems_24 from './icons/video-systems-24.svg';
import IconWarningRound_16 from './icons/warning-round-16.svg';
import IconWarningRound_24 from './icons/warning-round-24.svg';

export interface IconSource {
    size16: React.FC<React.ComponentProps<'svg'>>;
    size24: React.FC<React.ComponentProps<'svg'>>;
}

export interface IconCollection {
    IconAdd: IconSource;
    IconAddSomeone: IconSource;
    IconAlert: IconSource;
    IconAnalyticsChart: IconSource;
    IconAnalyticsProgress: IconSource;
    IconAndroidLogo: IconSource;
    IconAppleLogo: IconSource;
    IconArrowCornerDown: IconSource;
    IconArrowDown: IconSource;
    IconArrowLeft: IconSource;
    IconArrowLeftRegular: IconSource;
    IconArrowRight: IconSource;
    IconArrowUp: IconSource;
    IconBackgroundBlur: IconSource;
    IconBackspace: IconSource;
    IconBandwidth: IconSource;
    IconBell: IconSource;
    IconBilling: IconSource;
    IconBlock: IconSource;
    IconCalendar: IconSource;
    IconChat: IconSource;
    IconCheckmark: IconSource;
    IconCheckmarkRound: IconSource;
    IconChevronDown: IconSource;
    IconChevronLeft: IconSource;
    IconChevronRight: IconSource;
    IconChevronUp: IconSource;
    IconChromeLogo: IconSource;
    IconClock: IconSource;
    IconClose: IconSource;
    IconConnectionIndicatorHigh: IconSource;
    IconConnectionIndicatorLow: IconSource;
    IconConnectionIndicatorMedium: IconSource;
    IconConnectionIndicatorVeryLow: IconSource;
    IconDoubleChevronDown: IconSource;
    IconDoubleChevronLeft: IconSource;
    IconDoubleChevronRight: IconSource;
    IconDoubleChevronUp: IconSource;
    IconDraggable: IconSource;
    IconDualScreen: IconSource;
    IconEdit: IconSource;
    IconEmoji: IconSource;
    IconExpand: IconSource;
    IconExport: IconSource;
    IconFirefoxLogo: IconSource;
    IconFlipCamera: IconSource;
    IconFullscreenOff: IconSource;
    IconFullscreenOn: IconSource;
    IconGroup: IconSource;
    IconHelpRound: IconSource;
    IconHome: IconSource;
    IconImport: IconSource;
    IconInfoRound: IconSource;
    IconLeave: IconSource;
    IconLink: IconSource;
    IconLiveCaption: IconSource;
    IconLiveStream: IconSource;
    IconLock: IconSource;
    IconMail: IconSource;
    IconMeetingRoom: IconSource;
    IconMeetings: IconSource;
    IconMicrophoneOff: IconSource;
    IconMicrophoneOn: IconSource;
    IconMinus: IconSource;
    IconMoreHorizontal: IconSource;
    IconMoreVertical: IconSource;
    IconOpenInNew: IconSource;
    IconParticipant: IconSource;
    IconPasswordHide: IconSource;
    IconPasswordShow: IconSource;
    IconPauseRound: IconSource;
    IconPexipLogo: IconSource;
    IconPhone: IconSource;
    IconPlay: IconSource;
    IconPlayRound: IconSource;
    IconPlus: IconSource;
    IconPresentationOff: IconSource;
    IconPresentationOn: IconSource;
    IconRaiseHand: IconSource;
    IconRefresh: IconSource;
    IconRemoteControl: IconSource;
    IconSearch: IconSource;
    IconSend: IconSource;
    IconSettings: IconSource;
    IconSpeakerDisabled: IconSource;
    IconSpeakerOff: IconSource;
    IconSpeakerOn: IconSource;
    IconStar: IconSource;
    IconStopRound: IconSource;
    IconSupport: IconSource;
    IconThumbsDown: IconSource;
    IconThumbsUp: IconSource;
    IconTranscript: IconSource;
    IconTrash: IconSource;
    IconUnlock: IconSource;
    IconVideoOff: IconSource;
    IconVideoOn: IconSource;
    IconVideoSystems: IconSource;
    IconWarningRound: IconSource;
}

const collection: IconCollection = {
    IconAdd: {size16: IconAdd_16, size24: IconAdd_24},
    IconAddSomeone: {size16: IconAddSomeone_16, size24: IconAddSomeone_24},
    IconAlert: {size16: IconAlert_16, size24: IconAlert_24},
    IconAnalyticsChart: {
        size16: IconAnalyticsChart_16,
        size24: IconAnalyticsChart_24,
    },
    IconAnalyticsProgress: {
        size16: IconAnalyticsProgress_16,
        size24: IconAnalyticsProgress_24,
    },
    IconAndroidLogo: {size16: IconAndroidLogo_16, size24: IconAndroidLogo_24},
    IconAppleLogo: {size16: IconAppleLogo_16, size24: IconAppleLogo_24},
    IconArrowCornerDown: {
        size16: IconArrowCornerDown_16,
        size24: IconArrowCornerDown_24,
    },
    IconArrowDown: {size16: IconArrowDown_16, size24: IconArrowDown_24},
    IconArrowLeft: {size16: IconArrowLeft_16, size24: IconArrowLeft_24},
    IconArrowLeftRegular: {
        size16: IconArrowLeftRegular_16,
        size24: IconArrowLeftRegular_24,
    },
    IconArrowRight: {size16: IconArrowRight_16, size24: IconArrowRight_24},
    IconArrowUp: {size16: IconArrowUp_16, size24: IconArrowUp_24},
    IconBackgroundBlur: {
        size16: IconBackgroundBlur_16,
        size24: IconBackgroundBlur_24,
    },
    IconBackspace: {size16: IconBackspace_16, size24: IconBackspace_24},
    IconBandwidth: {size16: IconBandwidth_16, size24: IconBandwidth_24},
    IconBell: {size16: IconBell_16, size24: IconBell_24},
    IconBilling: {size16: IconBilling_16, size24: IconBilling_24},
    IconBlock: {size16: IconBlock_16, size24: IconBlock_24},
    IconCalendar: {size16: IconCalendar_16, size24: IconCalendar_24},
    IconChat: {size16: IconChat_16, size24: IconChat_24},
    IconCheckmark: {size16: IconCheckmark_16, size24: IconCheckmark_24},
    IconCheckmarkRound: {
        size16: IconCheckmarkRound_16,
        size24: IconCheckmarkRound_24,
    },
    IconChevronDown: {size16: IconChevronDown_16, size24: IconChevronDown_24},
    IconChevronLeft: {size16: IconChevronLeft_16, size24: IconChevronLeft_24},
    IconChevronRight: {
        size16: IconChevronRight_16,
        size24: IconChevronRight_24,
    },
    IconChevronUp: {size16: IconChevronUp_16, size24: IconChevronUp_24},
    IconChromeLogo: {size16: IconChromeLogo_16, size24: IconChromeLogo_24},
    IconClock: {size16: IconClock_16, size24: IconClock_24},
    IconClose: {size16: IconClose_16, size24: IconClose_24},
    IconConnectionIndicatorHigh: {
        size16: IconConnectionIndicatorHigh_24,
        size24: IconConnectionIndicatorHigh_24,
    },
    IconConnectionIndicatorLow: {
        size16: IconConnectionIndicatorLow_24,
        size24: IconConnectionIndicatorLow_24,
    },
    IconConnectionIndicatorMedium: {
        size16: IconConnectionIndicatorMedium_24,
        size24: IconConnectionIndicatorMedium_24,
    },
    IconConnectionIndicatorVeryLow: {
        size16: IconConnectionIndicatorVeryLow_24,
        size24: IconConnectionIndicatorVeryLow_24,
    },
    IconDoubleChevronDown: {
        size16: IconDoubleChevronDown_16,
        size24: IconDoubleChevronDown_24,
    },
    IconDoubleChevronLeft: {
        size16: IconDoubleChevronLeft_16,
        size24: IconDoubleChevronLeft_24,
    },
    IconDoubleChevronRight: {
        size16: IconDoubleChevronRight_16,
        size24: IconDoubleChevronRight_24,
    },
    IconDoubleChevronUp: {
        size16: IconDoubleChevronUp_16,
        size24: IconDoubleChevronUp_24,
    },
    IconDraggable: {size16: IconDraggable_16, size24: IconDraggable_24},
    IconDualScreen: {size16: IconDualScreen_16, size24: IconDualScreen_24},
    IconEdit: {size16: IconEdit_16, size24: IconEdit_24},
    IconEmoji: {size16: IconEmoji_16, size24: IconEmoji_24},
    IconExpand: {size16: IconExpand_16, size24: IconExpand_24},
    IconExport: {size16: IconExport_16, size24: IconExport_24},
    IconFirefoxLogo: {size16: IconFirefoxLogo_16, size24: IconFirefoxLogo_24},
    IconFlipCamera: {size16: IconFlipCamera_16, size24: IconFlipCamera_24},
    IconFullscreenOff: {
        size16: IconFullscreenOff_16,
        size24: IconFullscreenOff_24,
    },
    IconFullscreenOn: {
        size16: IconFullscreenOn_16,
        size24: IconFullscreenOn_24,
    },
    IconGroup: {size16: IconGroup_16, size24: IconGroup_24},
    IconHelpRound: {size16: IconHelpRound_16, size24: IconHelpRound_24},
    IconHome: {size16: IconHome_16, size24: IconHome_24},
    IconImport: {size16: IconImport_16, size24: IconImport_24},
    IconInfoRound: {size16: IconInfoRound_16, size24: IconInfoRound_24},
    IconLeave: {size16: IconLeave_16, size24: IconLeave_24},
    IconLink: {size16: IconLink_16, size24: IconLink_24},
    IconLiveCaption: {size16: IconLiveCaption_16, size24: IconLiveCaption_24},
    IconLiveStream: {size16: IconLiveStream_16, size24: IconLiveStream_24},
    IconLock: {size16: IconLock_16, size24: IconLock_24},
    IconMail: {size16: IconMail_16, size24: IconMail_24},
    IconMeetingRoom: {size16: IconMeetingRoom_16, size24: IconMeetingRoom_24},
    IconMeetings: {size16: IconMeetings_16, size24: IconMeetings_24},
    IconMicrophoneOff: {
        size16: IconMicrophoneOff_16,
        size24: IconMicrophoneOff_24,
    },
    IconMicrophoneOn: {
        size16: IconMicrophoneOn_16,
        size24: IconMicrophoneOn_24,
    },
    IconMinus: {size16: IconMinus_16, size24: IconMinus_24},
    IconMoreHorizontal: {
        size16: IconMoreHorizontal_24,
        size24: IconMoreHorizontal_24,
    },
    IconMoreVertical: {
        size16: IconMoreVertical_16,
        size24: IconMoreVertical_24,
    },
    IconOpenInNew: {size16: IconOpenInNew_16, size24: IconOpenInNew_24},
    IconParticipant: {size16: IconParticipant_16, size24: IconParticipant_24},
    IconPasswordHide: {
        size16: IconPasswordHide_24,
        size24: IconPasswordHide_24,
    },
    IconPasswordShow: {
        size16: IconPasswordShow_24,
        size24: IconPasswordShow_24,
    },
    IconPauseRound: {size16: IconPauseRound_16, size24: IconPauseRound_24},
    IconPexipLogo: {size16: IconPexipLogo_24, size24: IconPexipLogo_24},
    IconPhone: {size16: IconPhone_16, size24: IconPhone_24},
    IconPlay: {size16: IconPlay_16, size24: IconPlay_24},
    IconPlayRound: {size16: IconPlayRound_16, size24: IconPlayRound_24},
    IconPlus: {size16: IconPlus_16, size24: IconPlus_24},
    IconPresentationOff: {
        size16: IconPresentationOff_16,
        size24: IconPresentationOff_24,
    },
    IconPresentationOn: {
        size16: IconPresentationOn_16,
        size24: IconPresentationOn_24,
    },
    IconRaiseHand: {size16: IconRaiseHand_16, size24: IconRaiseHand_24},
    IconRefresh: {size16: IconRefresh_16, size24: IconRefresh_24},
    IconRemoteControl: {
        size16: IconRemoteControl_24,
        size24: IconRemoteControl_24,
    },
    IconSearch: {size16: IconSearch_16, size24: IconSearch_24},
    IconSend: {size16: IconSend_16, size24: IconSend_24},
    IconSettings: {size16: IconSettings_16, size24: IconSettings_24},
    IconSpeakerDisabled: {
        size16: IconSpeakerDisabled_16,
        size24: IconSpeakerDisabled_24,
    },
    IconSpeakerOff: {size16: IconSpeakerOff_16, size24: IconSpeakerOff_24},
    IconSpeakerOn: {size16: IconSpeakerOn_16, size24: IconSpeakerOn_24},
    IconStar: {size16: IconStar_16, size24: IconStar_24},
    IconStopRound: {size16: IconStopRound_16, size24: IconStopRound_24},
    IconSupport: {size16: IconSupport_16, size24: IconSupport_24},
    IconThumbsDown: {size16: IconThumbsDown_24, size24: IconThumbsDown_24},
    IconThumbsUp: {size16: IconThumbsUp_24, size24: IconThumbsUp_24},
    IconTranscript: {size16: IconTranscript_16, size24: IconTranscript_24},
    IconTrash: {size16: IconTrash_16, size24: IconTrash_24},
    IconUnlock: {size16: IconUnlock_16, size24: IconUnlock_24},
    IconVideoOff: {size16: IconVideoOff_16, size24: IconVideoOff_24},
    IconVideoOn: {size16: IconVideoOn_16, size24: IconVideoOn_24},
    IconVideoSystems: {
        size16: IconVideoSystems_16,
        size24: IconVideoSystems_24,
    },
    IconWarningRound: {
        size16: IconWarningRound_16,
        size24: IconWarningRound_24,
    },
};

export default collection;

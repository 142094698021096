import React from 'react';

import {HeaderView} from '../views/Header/Header.view';

import {UserMenu} from './UserMenu.viewModel';

export const Header: React.FC<
    Omit<React.ComponentProps<typeof HeaderView>, 'userMenu'>
> = ({...props}) => (
    <HeaderView {...props} userMenu={<UserMenu variant="bordered" />} />
);

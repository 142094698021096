import React from 'react';
import cx from 'classnames';

import type {ColorScheme} from '../../../types/variants';
import {ThemeConsumer} from '../../../themes/ThemeContext';

import styles from './Divider.module.scss';

export const dividerDirectionValues = ['horizontal', 'vertical'] as const;
export type DividerDirection = (typeof dividerDirectionValues)[number];

export const Divider: React.FC<
    React.ComponentProps<'div'> & {
        colorScheme?: ColorScheme;
        direction?: DividerDirection;
    }
> = ({colorScheme, direction = 'horizontal', className, ...props}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => (
            <div
                className={cx(
                    styles.divider,
                    styles[colorScheme ?? defaultColorScheme],
                    styles[direction],
                    className,
                )}
                {...props}
            />
        )}
    </ThemeConsumer>
);

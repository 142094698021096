import React, {useCallback} from 'react';

import {withColorScheme} from '@pexip/components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import type {AnalyzerNodeInit} from '@pexip/media-processor';

import type {PreviewControllerHandler} from '../hooks/usePreviewControllerHandler';
import {DevicesList} from '../views/DevicesList/DevicesList.view';
import {MediaControlSettings} from '../views/MediaControlSettings/MediaControlSettings.view';
import {TestId} from '../../test/testIds';
import type {Preview} from '../hooks/usePreview';
import type {PreviewMediaInput, StreamQuality} from '../types';

import {AudioMeter} from './AudioMeter.viewModel';

const SecondaryAudioMeter = withColorScheme(AudioMeter, 'light');

export const MediaControlHandler: React.FC<
    React.PropsWithChildren<{
        audioInput: PreviewMediaInput;
        audioOutput: Preview<MediaDeviceInfoLike | undefined>;
        closeModal: () => void;
        denoise: Preview<boolean>;
        devices: MediaDeviceInfoLike[];
        previewController: PreviewControllerHandler;
        streamQuality: Preview<StreamQuality>;
        videoInput: PreviewMediaInput;
        audioAnalyzer?: AnalyzerNodeInit;
    }>
> = ({
    audioInput,
    audioOutput,
    closeModal,
    children: outputAudioTester,
    denoise,
    devices,
    previewController,
    streamQuality,
    videoInput,
    audioAnalyzer,
}) => {
    const handleDenoise = useCallback(() => {
        denoise.setPreview(previewDenoise => !previewDenoise);
    }, [denoise]);

    const deviceList = (
        <DevicesList
            devices={devices}
            videoInputError={videoInput.error}
            audioInputError={audioInput.error}
            videoInput={videoInput.preview}
            audioInput={audioInput.preview}
            audioOutput={audioOutput.preview}
            onAudioInputChange={audioInput.setPreview}
            onAudioOutputChange={audioOutput.setPreview}
            onVideoInputChange={videoInput.setPreview}
            isLoading={previewController.isSaving}
        />
    );

    const audioMeter = !!previewController.previewMedia?.audioInput &&
        !!audioAnalyzer && (
            <SecondaryAudioMeter
                analyzer={audioAnalyzer}
                width={155}
                data-testid={TestId.AudioMeterSettings}
            />
        );

    return (
        <MediaControlSettings
            inputAudioTester={audioMeter}
            allowToSave={
                !previewController.isSaving &&
                !videoInput.error.title &&
                !audioInput.error.title
            }
            deviceList={deviceList}
            outputAudioTester={outputAudioTester}
            handleCancel={closeModal}
            handleNoiseSuppression={handleDenoise}
            handleSave={previewController.handleSave}
            isSaving={
                previewController.isSaving || previewController.updatingPreview
            }
            noiseSuppression={denoise.preview}
            previewStream={previewController.previewMedia?.stream}
            previewStreamQuality={streamQuality.preview}
            setPreviewStreamQuality={streamQuality.setPreview}
        />
    );
};

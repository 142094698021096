// src/hooks/usePromise.ts
import { useCallback, useEffect, useRef, useState } from "react";
var usePromise = (getPromise, cb, failCb) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const active = useRef(true);
  const handleAction = useCallback(() => {
    setError("");
    setLoading(true);
    return getPromise().then((data) => {
      if (active.current) {
        cb?.(data);
      }
    }).catch((error2) => {
      if (active.current) {
        setError(error2.message);
        failCb?.(error2.message);
      }
    }).finally(() => {
      if (active.current) {
        setLoading(false);
      }
    });
  }, [cb, failCb, getPromise]);
  useEffect(
    () => () => {
      active.current = false;
    },
    []
  );
  return { error, loading, handleAction };
};

// src/hooks/useAnimationFrame.ts
import { useEffect as useEffect2 } from "react";
var poorManRequestAnimationFrame = (callback) => setTimeout(callback, performance.now());
var poorManCancelAnimationFrame = (handle) => clearTimeout(handle);
var requestAnimationFrame = window.requestAnimationFrame || poorManRequestAnimationFrame;
var cancelAnimationFrame = window.cancelAnimationFrame || poorManCancelAnimationFrame;
var useAnimationFrame = (callback, shouldStop = false) => {
  useEffect2(() => {
    let requestRef = void 0;
    let previousTimeRef = void 0;
    let ignore = shouldStop;
    const stopAnimate = () => {
      ignore = true;
      if (requestRef) {
        cancelAnimationFrame(requestRef);
      }
      requestRef = void 0;
      previousTimeRef = void 0;
    };
    const animate = (time) => {
      if (ignore) {
        return;
      }
      if (previousTimeRef !== void 0) {
        const deltaTime = time - previousTimeRef;
        callback(deltaTime);
      }
      previousTimeRef = time;
      requestRef = requestAnimationFrame(animate);
    };
    if (ignore) {
      stopAnimate();
    } else {
      requestRef = requestAnimationFrame(animate);
    }
    return stopAnimate;
  }, [callback, shouldStop]);
};

// src/hooks/useInterval.ts
import { useRef as useRef2, useEffect as useEffect3 } from "react";
var useInterval = (callback, delay) => {
  const savedCallback = useRef2(null);
  useEffect3(() => {
    savedCallback.current = callback;
  });
  useEffect3(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (delay) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

// src/hooks/useSizeObserver.ts
import { useState as useState2, useEffect as useEffect4, useCallback as useCallback2 } from "react";
var useSizeObserver = (element) => {
  const [size, setSize] = useState2({
    width: element.offsetWidth,
    height: element.offsetHeight
  });
  const handleResize = useCallback2(
    (entries) => {
      const [firstEntry] = entries;
      if (!firstEntry) {
        return;
      }
      const borderBoxSize = firstEntry.borderBoxSize?.[0];
      if (firstEntry.target.tagName !== element.tagName) {
        return;
      }
      if (borderBoxSize) {
        setSize({
          width: borderBoxSize.inlineSize,
          height: borderBoxSize.blockSize
        });
        return;
      }
      if (firstEntry.contentRect) {
        setSize({
          width: firstEntry.contentRect.width,
          height: firstEntry.contentRect.height
        });
      }
    },
    [element]
  );
  useEffect4(() => {
    const observer = new ResizeObserver(handleResize);
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element, handleResize]);
  return size;
};

// src/hooks/useAutoHideUIInterfaces.ts
import { useCallback as useCallback3, useEffect as useEffect5, useState as useState3 } from "react";
import { throttle } from "@pexip/utils";
import { createSignal } from "@pexip/signal";
var UI_INACTIVITY_TIMEOUT = 5e3;
var autoHideUIInterfacesStateSignal = createSignal({
  name: "autoHideUIInterfaces:state"
});
var useAutoHideUIInterfaces = (isStreamReady, shouldDisplay) => {
  const [enabled, setEnabled] = useState3(true);
  const enableAutoHideConstraintBased = useCallback3(
    (constraint) => {
      if (!constraint) {
        setEnabled(true);
      }
    },
    [setEnabled]
  );
  useEffect5(() => {
    let ignore = false;
    let timeout;
    const timeoutHandler = () => {
      if (!ignore) {
        shouldDisplay?.(false);
        document.body.dataset.uiHidden = "";
        autoHideUIInterfacesStateSignal.emit("hidden");
        document.addEventListener("keydown", tabKeydown, { once: true });
      }
    };
    const onMouseMoveCallback = () => {
      clearTimeout(timeout);
      if (!ignore) {
        shouldDisplay?.(true);
        delete document.body.dataset.uiHidden;
        autoHideUIInterfacesStateSignal.emit("visible");
      }
      timeout = setTimeout(timeoutHandler, UI_INACTIVITY_TIMEOUT);
    };
    const throttled = throttle(onMouseMoveCallback, 500);
    const tabKeydown = (e) => {
      if (e.code === "Tab") {
        onMouseMoveCallback();
      }
    };
    if (isStreamReady && enabled) {
      timeout = setTimeout(timeoutHandler, UI_INACTIVITY_TIMEOUT);
      document.addEventListener("mousemove", throttled);
    }
    return () => {
      ignore = true;
      delete document.body.dataset.uiHidden;
      autoHideUIInterfacesStateSignal.emit("visible");
      clearTimeout(timeout);
      document.removeEventListener("keydown", tabKeydown);
      document.removeEventListener("mousemove", throttled);
    };
  }, [shouldDisplay, enabled, isStreamReady]);
  return { enableAutoHide: setEnabled, enableAutoHideConstraintBased };
};

// src/hooks/useHotKey.ts
import { useEffect as useEffect6 } from "react";
import { createSignal as createSignal2 } from "@pexip/signal";
var keydownSignal = createSignal2({
  allowEmittingWithoutObserver: true,
  name: "useHotKey:keydownEvent"
});
var excludedElements = ["INPUT", "TEXTAREA"];
var emitKeydownSignal = (event) => keydownSignal.emit(event);
var defaultDisallowedKeys = { metaKey: true, ctrlKey: true };
var useHotKey = (key, handler, disallowedKeys = defaultDisallowedKeys) => {
  if (key.length !== 1) {
    throw new Error("Hotkey length is limited to 1 currently");
  }
  useEffect6(() => {
    document.addEventListener("keydown", emitKeydownSignal);
    return () => document.removeEventListener("keydown", emitKeydownSignal);
  }, []);
  useEffect6(
    () => keydownSignal.add((event) => {
      if (event.repeat) {
        return;
      }
      if (excludedElements.includes(
        document.activeElement?.tagName || ""
      )) {
        return;
      }
      let k;
      for (k in disallowedKeys) {
        if (disallowedKeys[k] && event[k]) {
          return;
        }
      }
      const capsLockIsOn = event.getModifierState ? event.getModifierState("CapsLock") : false;
      if (capsLockIsOn) {
        const pressedKey = event.shiftKey ? event.key.toUpperCase() : event.key.toLowerCase();
        if (key === pressedKey) {
          return handler();
        }
      } else if (key === event.key) {
        return handler();
      }
    }),
    [key, handler, disallowedKeys]
  );
};

// src/hooks/useFullscreenApi.ts
import { useMemo } from "react";
var standard = {
  exitFullscreen: "exitFullscreen",
  fullscreenchange: "fullscreenchange",
  fullscreenElement: "fullscreenElement",
  fullscreenEnabled: "fullscreenEnabled",
  requestFullscreen: "requestFullscreen"
};
var webkit = {
  exitFullscreen: "webkitExitFullscreen",
  fullscreenchange: "webkitfullscreenchange",
  fullscreenElement: "webkitFullscreenElement",
  fullscreenEnabled: "webkitFullscreenEnabled",
  requestFullscreen: "webkitRequestFullscreen"
};
var moz = {
  exitFullscreen: "mozCancelFullScreen",
  fullscreenchange: "mozfullscreenchange",
  fullscreenElement: "mozFullScreenElement",
  fullscreenEnabled: "mozFullScreenEnabled",
  requestFullscreen: "mozRequestFullScreen"
};
var browserApi = standard.fullscreenEnabled in document && standard || webkit.fullscreenEnabled in document && webkit || moz.fullscreenEnabled in document && moz || {};
var getBrowserAPIMethodName = (methodName) => browserApi[methodName];
var useFullscreenApi = () => useMemo(() => {
  const fullscreenEnabled = getBrowserAPIMethodName(
    standard.fullscreenEnabled
  );
  const exitFullscreen = getBrowserAPIMethodName(
    standard.exitFullscreen
  );
  const fullscreenElement = getBrowserAPIMethodName(
    standard.fullscreenElement
  );
  const requestFullscreen = getBrowserAPIMethodName(standard.requestFullscreen);
  return {
    fullscreenEnabled: document[fullscreenEnabled],
    exitFullscreen: document[exitFullscreen]?.bind(
      document
    ),
    get fullscreenElement() {
      return document[fullscreenElement];
    },
    requestFullscreen: (element = document.documentElement, options) => element[requestFullscreen]?.bind(element)(options),
    fullscreenchange: browserApi[standard.fullscreenchange]
  };
}, []);

// src/hooks/useToggleFullscreen.ts
import { useCallback as useCallback4, useEffect as useEffect7 } from "react";
import { createSignal as createSignal3 } from "@pexip/signal";
var fullscreenSignal = createSignal3({
  name: "fullscreen:state"
});
var isFullscreen = (fullscreenElement) => fullscreenElement !== null ? true : false;
var emitFullscreenState = (fullscreenElement) => {
  if (fullscreenElement !== null) {
    fullscreenSignal.emit(true);
  } else {
    fullscreenSignal.emit(false);
  }
};
var useToggleFullscreen = () => {
  const fullscreenApi = useFullscreenApi();
  const toggleFullscreen = useCallback4(() => {
    if (isFullscreen(fullscreenApi.fullscreenElement)) {
      void fullscreenApi.exitFullscreen?.();
    } else {
      void fullscreenApi?.requestFullscreen();
    }
  }, [fullscreenApi]);
  useEffect7(() => {
    const callback = () => emitFullscreenState(fullscreenApi.fullscreenElement);
    document.addEventListener(fullscreenApi.fullscreenchange, callback);
    return () => document.removeEventListener(
      fullscreenApi.fullscreenchange,
      callback
    );
  }, [fullscreenApi.fullscreenElement, fullscreenApi.fullscreenchange]);
  return toggleFullscreen;
};

// src/hooks/useIsInWindowBottomHalf.ts
import { useCallback as useCallback5, useState as useState4 } from "react";
var useIsInWindowBottomHalf = () => {
  const [isInBottomHalf, setIsInBottomHalf] = useState4(false);
  const update = useCallback5((el) => {
    const bottomHalf = el.offsetTop + el.offsetHeight / 2 > window.innerHeight / 2;
    setIsInBottomHalf(bottomHalf);
  }, []);
  return { isInBottomHalf, update };
};

// src/hooks/useIsInWindowRightHalf.ts
import { useCallback as useCallback6, useState as useState5 } from "react";
var useIsInWindowRightHalf = () => {
  const [isInRightHalf, setIsInRightHalf] = useState5(true);
  const update = useCallback6((el) => {
    const rightHalf = el.offsetLeft + el.offsetWidth / 2 > window.innerWidth / 2;
    setIsInRightHalf(rightHalf);
  }, []);
  return { isInRightHalf, update };
};

// src/hooks/useCountDown.ts
import { useCallback as useCallback7, useEffect as useEffect8, useRef as useRef3, useState as useState6 } from "react";
var useCountDown = (state, onCountDownFinished) => {
  const { startCountFrom = 5, msInterval = 1e3, enabled } = state;
  const [shouldStart, setShouldStart] = useState6(false);
  const [currentCount, setCurrentCount] = useState6(startCountFrom);
  const intervalRef = useRef3();
  const startCountDown = useCallback7(() => {
    setCurrentCount(startCountFrom);
    setShouldStart(true);
  }, [startCountFrom]);
  useEffect8(() => {
    const intervalCallback = () => {
      setCurrentCount((countDown) => countDown - 1);
    };
    if (shouldStart && enabled) {
      intervalRef.current = window.setInterval(
        intervalCallback,
        msInterval
      );
    }
    if (!enabled) {
      setCurrentCount(startCountFrom);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [msInterval, enabled, shouldStart, startCountFrom]);
  useEffect8(() => {
    if (currentCount <= 0) {
      clearInterval(intervalRef.current);
      setShouldStart(false);
      onCountDownFinished?.();
    }
  }, [currentCount, onCountDownFinished]);
  const cleanup = () => {
    clearInterval(intervalRef.current);
    setShouldStart(false);
    setCurrentCount(startCountFrom);
  };
  return {
    startCountDown,
    currentCount,
    cleanup
  };
};

// src/hooks/usePrevious.ts
import { useEffect as useEffect9, useRef as useRef4 } from "react";
var usePrevious = (value) => {
  const ref = useRef4();
  useEffect9(() => {
    ref.current = value;
  });
  return ref.current;
};

// src/hooks/useWideContainerObserver.ts
import { useEffect as useEffect10, useRef as useRef5, useState as useState7 } from "react";
var WIDE_CONTAINER_ASPECT_RATIO = 16 / 9;
var useWideContainerObserver = () => {
  const [isContainerWide, setIsContainerWide] = useState7(false);
  const containerRef = useRef5(null);
  const containerSize = useSizeObserver(
    containerRef.current || document.createElement("div")
  );
  useEffect10(() => {
    const aspectRatio = containerSize.width / containerSize.height;
    if (aspectRatio > WIDE_CONTAINER_ASPECT_RATIO) {
      setIsContainerWide(true);
    } else {
      setIsContainerWide(false);
    }
  }, [containerSize]);
  return { containerRef, isContainerWide };
};

// src/hooks/useMatchMedia.ts
import { useEffect as useEffect11, useState as useState8, useCallback as useCallback8, useMemo as useMemo2 } from "react";
var getForceMatchMedia = (mediaQuery) => "__STORYBOOK_FORCE_MEDIA_QUERY__" in window && window.__STORYBOOK_FORCE_MEDIA_QUERY__ ? mediaQuery.includes(window.__STORYBOOK_FORCE_MEDIA_QUERY__) : void 0;
var useMatchMedia = (mediaQuery) => {
  const hasForcedMql = getForceMatchMedia(mediaQuery);
  const mql = useMemo2(() => {
    if ("matchMedia" in window) {
      return window.matchMedia(mediaQuery);
    }
  }, [mediaQuery]);
  const [matches, setMatches] = useState8(mql?.matches ?? false);
  const handleQueryChange = useCallback8(
    (e) => setMatches(e.matches),
    [setMatches]
  );
  useEffect11(() => {
    mql?.addEventListener("change", handleQueryChange);
    return () => {
      mql?.removeEventListener("change", handleQueryChange);
    };
  }, [mql, handleQueryChange]);
  return hasForcedMql ?? matches;
};

// src/hooks/useIsMounted.ts
import { useRef as useRef6, useEffect as useEffect12, useCallback as useCallback9 } from "react";
var useIsMounted = () => {
  const isMounted = useRef6(false);
  useEffect12(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return useCallback9(() => isMounted.current, []);
};

// src/hooks/useOnClickOutside.ts
import { useEffect as useEffect13, useLayoutEffect, useRef as useRef7 } from "react";
function useOnClickOutside(fn) {
  const fnRef = useRef7(fn);
  useLayoutEffect(() => {
    fnRef.current = fn;
  }, [fn]);
  const ref = useRef7(null);
  const refCallback = (element) => {
    ref.current = element;
  };
  useEffect13(() => {
    const handleOutside = (event) => {
      if (!ref.current || !event.target) {
        return;
      }
      if (ref.current.contains(event.target)) {
        return;
      }
      fnRef.current();
    };
    document.addEventListener("click", handleOutside, { capture: true });
    document.addEventListener("touchstart", handleOutside, { capture: true });
    return () => {
      document.removeEventListener("click", handleOutside, {
        capture: true
      });
      document.removeEventListener("touchstart", handleOutside, {
        capture: true
      });
    };
  }, []);
  return refCallback;
}
export {
  UI_INACTIVITY_TIMEOUT,
  autoHideUIInterfacesStateSignal,
  fullscreenSignal,
  isFullscreen,
  useAnimationFrame,
  useAutoHideUIInterfaces,
  useCountDown,
  useFullscreenApi,
  useHotKey,
  useInterval,
  useIsInWindowBottomHalf,
  useIsInWindowRightHalf,
  useIsMounted,
  useMatchMedia,
  useOnClickOutside,
  usePrevious,
  usePromise,
  useSizeObserver,
  useToggleFullscreen,
  useWideContainerObserver
};

// src/index.ts
import { createSignal } from "@pexip/signal";

// ../../shared/baseLogger.ts
var LogLevels = ((LogLevels2) => {
  LogLevels2[LogLevels2["trace"] = 10] = "trace";
  LogLevels2[LogLevels2["debug"] = 20] = "debug";
  LogLevels2[LogLevels2["info"] = 30] = "info";
  LogLevels2[LogLevels2["warn"] = 40] = "warn";
  LogLevels2[LogLevels2["error"] = 50] = "error";
  LogLevels2[LogLevels2["fatal"] = 60] = "fatal";
  LogLevels2[LogLevels2["silent"] = Number.MAX_SAFE_INTEGER] = "silent";
  return LogLevels2;
})(LogLevels || {});
function createConsoleLogger() {
  return Object.freeze({
    /* eslint-disable no-console -- set logger to console */
    fatal: (meta, message) => console.error(message, meta),
    error: (meta, message) => console.error(message, meta),
    warn: (meta, message) => console.warn(message, meta),
    info: (meta, message) => console.info(message, meta),
    debug: (meta, message) => console.debug(message, meta),
    trace() {
    },
    // Noop
    silent() {
    },
    // Noop
    redact() {
    }
    // Noop
    /* eslint-enable no-console -- set logger to console */
  });
}

// src/logger.ts
var logger = createConsoleLogger();
function setLogger(newLogger) {
  logger = newLogger;
}

// src/typeGuards.ts
function isEvent(data) {
  return Boolean(
    data && typeof data === "object" && "chanId" in data && "event" in data
  );
}
function isRPCCall(data) {
  if (data && typeof data === "object" && "chanId" in data && "rpc" in data) {
    return true;
  }
  return false;
}
function isRPCReply(data) {
  if (data && typeof data === "object" && "chanId" in data && "replyTo" in data) {
    return true;
  }
  return false;
}
function isRPCCallType(type, data) {
  if (typeof data !== "object" || !data) {
    return false;
  }
  return data.rpc === type && typeof data.id === "string" && "payload" in data;
}
var isFormSelectElement = (element) => {
  return element.type === "select";
};
var isFormChecklistElement = (element) => {
  return element.type === "checklist";
};

// src/utils.ts
import { v4 } from "uuid";
function generateId(readableName) {
  return `${readableName ? `${readableName}-` : ""}${v4()}`;
}
function createPopupManager() {
  const popups = /* @__PURE__ */ new Map();
  const intervals = /* @__PURE__ */ new Set();
  window.onbeforeunload = () => {
    intervals.forEach((value) => clearInterval(value));
    intervals.clear();
  };
  const add = (id, shouldOpen) => {
    if (popups.has(id)) {
      logger.warn(
        `A popup with with ID:'${id}' has already been registered`
      );
      return;
    }
    popups.set(id, { popup: null, shouldOpen });
  };
  const remove = (id) => {
    popups.delete(id);
  };
  const open = (id, openParams, context) => {
    if (!popups.has(id)) {
      popups.set(id, { popup: null });
    }
    const popupCtx = popups.get(id);
    if (popupCtx?.popup && !popupCtx.popup.closed) {
      logger.warn(`Popup with ID:'${id}' is currently opened`);
      return;
    }
    if (popups.get(id)?.shouldOpen && !popups.get(id)?.shouldOpen?.(context ?? {})) {
      return;
    }
    const popup = window.open(...openParams);
    if (popupCtx) {
      popupCtx.popup = popup;
    }
    if (popup) {
      const intervalId = window.setInterval(() => {
        if (popup.closed) {
          const popupWrapper = popups.get(id);
          if (popupWrapper) {
            popupWrapper.popup = null;
          }
          clearInterval(intervalId);
          intervals.delete(intervalId);
        }
      }, 500);
      intervals.add(intervalId);
    }
  };
  const get = (id) => {
    return popups.get(id)?.popup;
  };
  return { add, remove, open, get };
}

// src/channel.ts
var Channel = class {
  /**
   * RPC communication based on window postMessage
   * @param target - essentially a window obj we want to send messages to
   * @param chanId - Channel id. Allows one frame to contain multiple plugins.
   */
  constructor(target, chanId) {
    this.target = target;
    this.chanId = chanId;
    globalThis.addEventListener("message", this.onMessage);
  }
  pendingCalls = /* @__PURE__ */ new Map();
  eventListeners = /* @__PURE__ */ new Set();
  addEventListener(listener) {
    this.eventListeners.add(listener);
  }
  removeEventListener(listener) {
    this.eventListeners.delete(listener);
  }
  // TODO: Should app->plugin events have to be explicitly registered for? We
  // likely already need some routing for e.g. plugin button interactions,
  // which should probably only go to the plugin it's from
  emitEvent(event) {
    this.target.postMessage({ chanId: this.chanId, ...event }, "*");
  }
  callRPC(method, payload, transfer) {
    const id = generateId();
    logger.debug(
      { payload, id },
      `'${method}' called for channel ${this.chanId}`
    );
    return new Promise((resolve, reject) => {
      this.pendingCalls.set(id, [resolve, reject]);
      this.target.postMessage(
        {
          rpc: method,
          payload,
          id,
          chanId: this.chanId
        },
        "*",
        transfer
      );
    });
  }
  replyRPC(event) {
    this.emitEvent(event);
  }
  sendEvent(event) {
    this.emitEvent(event);
  }
  unregister() {
    globalThis.removeEventListener("message", this.onMessage);
  }
  onMessage = (evt) => {
    if (evt.data.chanId !== this.chanId) {
      return;
    }
    const data = evt.data;
    logger.debug({ evt }, `Message received for channel ${this.chanId}`);
    if (isRPCReply(data)) {
      const [resolve, _reject] = this.pendingCalls.get(data.replyTo) ?? [];
      if (!resolve) {
        logger.debug({ evt }, "Resolve fn doesnt exist");
        return;
      }
      this.pendingCalls.delete(data.replyTo);
      resolve(data.payload);
    }
    if (isEvent(data)) {
      this.eventListeners.forEach((listener) => {
        void listener(data);
      });
    }
  };
  get targetWindow() {
    return this.target;
  }
};

// src/index.ts
async function registerPlugin(meta) {
  window.plugin = { popupManager: createPopupManager() };
  const channel = new Channel(window.parent, generateId(meta.id));
  const reply = await channel.callRPC("syn", meta);
  if (!reply.ack) {
    throw new Error(`Can't register a plugin. ${JSON.stringify(reply)}`);
  }
  const buttons = /* @__PURE__ */ new Map();
  const participants = /* @__PURE__ */ new Map();
  const forms = /* @__PURE__ */ new Map();
  const prompts = /* @__PURE__ */ new Map();
  const authenticatedWithConference = createSignal({
    allowEmittingWithoutObserver: true
  });
  const conferenceStatus = createSignal({
    allowEmittingWithoutObserver: true
  });
  const connected = createSignal({
    allowEmittingWithoutObserver: true
  });
  const disconnected = createSignal({
    allowEmittingWithoutObserver: true
  });
  const me = createSignal({
    allowEmittingWithoutObserver: true
  });
  const infinityParticipants = createSignal({
    allowEmittingWithoutObserver: true
  });
  const participantJoined = createSignal({
    allowEmittingWithoutObserver: true
  });
  const participantLeft = createSignal({
    allowEmittingWithoutObserver: true
  });
  const raiseHand = createSignal({
    allowEmittingWithoutObserver: true
  });
  const message = createSignal({
    allowEmittingWithoutObserver: true
  });
  const directMessage = createSignal(
    {
      allowEmittingWithoutObserver: true
    }
  );
  const applicationMessage = createSignal({
    allowEmittingWithoutObserver: true
  });
  const transfer = createSignal({
    allowEmittingWithoutObserver: true
  });
  const stage = createSignal({
    allowEmittingWithoutObserver: true
  });
  const presentationConnectionStateChange = createSignal({
    allowEmittingWithoutObserver: true
  });
  const layoutUpdate = createSignal({
    allowEmittingWithoutObserver: true
  });
  channel.addEventListener((event) => {
    switch (event.event) {
      case "ui:button:click":
        buttons.get(event.payload.buttonId)?.onClick.emit(event.payload.input);
        break;
      case "ui:form:input":
        forms.get(event.payload.modalId)?.onInput.emit(event.payload.input);
        break;
      case "ui:prompt:input":
        prompts.get(event.payload.modalId)?.onInput.emit(event.payload.input);
        break;
      case "participant:disconnected":
        participants.delete(event.payload.participantUuid);
        participants.get(event.payload.participantUuid)?.onDisconnect.emit();
        break;
      case "event:conferenceStatus":
        conferenceStatus.emit(event.payload);
        break;
      case "event:connected":
        connected.emit(event.payload);
        break;
      case "event:disconnected":
        disconnected.emit(event.payload);
        break;
      case "event:me":
        me.emit(event.payload);
        break;
      case "event:message":
        message.emit(event.payload);
        break;
      case "event:directMessage":
        directMessage.emit(event.payload);
        break;
      case "event:applicationMessage":
        applicationMessage.emit(event.payload);
        break;
      case "event:transfer":
        transfer.emit(event.payload);
        break;
      case "event:stage":
        stage.emit(event.payload);
        break;
      case "event:participants":
        infinityParticipants.emit(event.payload);
        break;
      case "event:participantLeft":
        participantLeft.emit(event.payload);
        break;
      case "event:participantJoined":
        participantJoined.emit(event.payload);
        break;
      case "event:raiseHand":
        raiseHand.emit(event.payload);
        break;
      case "event:presentationConnectionStateChange":
        presentationConnectionStateChange.emit(event.payload);
        break;
      case "event:layoutUpdate":
        layoutUpdate.emit(event.payload);
        break;
      case "event:conference:authenticated":
        authenticatedWithConference.emit(event.payload);
        break;
    }
  });
  const removeElement = async (id, successCb) => {
    const result = await channel.callRPC("ui:removeElement", { id });
    if (result.status === "failed") {
      return Promise.reject({ reason: result.reason });
    }
    if (successCb) {
      successCb();
    }
  };
  return {
    ui: {
      addButton: async (payload) => {
        const btnMeta = await channel.callRPC("ui:button:add", payload);
        if (btnMeta.status === "failed") {
          return Promise.reject({ reason: btnMeta.reason });
        }
        const buttonElement = {
          onClick: createSignal({ allowEmittingWithoutObserver: true }),
          update: async (payload2) => {
            const result = await channel.callRPC(
              "ui:button:update",
              {
                ...payload2,
                id: btnMeta.id
              }
            );
            if (result.status === "failed") {
              return Promise.reject({ reason: result.reason });
            }
            return result.data;
          },
          remove: () => removeElement(
            btnMeta.id,
            () => buttons.delete(btnMeta.id)
          )
        };
        buttons.set(btnMeta.id, buttonElement);
        return buttonElement;
      },
      addForm: async (payload) => {
        const formMeta = await channel.callRPC("ui:form:open", payload);
        if (formMeta.status === "failed") {
          return Promise.reject({ reason: formMeta.reason });
        }
        const form = {
          onInput: createSignal(),
          remove: () => removeElement(
            formMeta.id,
            () => forms.delete(formMeta.id)
          )
        };
        forms.set(formMeta.id, form);
        return form;
      },
      showForm: async (payload) => {
        const formMeta = await channel.callRPC("ui:form:open", payload);
        if (formMeta.status === "failed") {
          return Promise.reject({ reason: formMeta.reason });
        }
        return new Promise((resolve) => {
          const eventListener = (event) => {
            if (event.event === "ui:form:input" && event.payload.modalId === formMeta.id) {
              resolve(event.payload.input);
              void removeElement(formMeta.id);
              channel.removeEventListener(eventListener);
            }
          };
          channel.addEventListener(eventListener);
        });
      },
      addPrompt: async (payload) => {
        const promptMeta = await channel.callRPC(
          "ui:prompt:open",
          payload
        );
        if (promptMeta.status === "failed") {
          return Promise.reject({ reason: promptMeta.reason });
        }
        const prompt = {
          onInput: createSignal(),
          remove: () => removeElement(
            promptMeta.id,
            () => prompts.delete(promptMeta.id)
          )
        };
        prompts.set(promptMeta.id, prompt);
        return prompt;
      },
      showPrompt: async (payload) => {
        const promptMeta = await channel.callRPC(
          "ui:prompt:open",
          payload
        );
        if (promptMeta.status === "failed") {
          return Promise.reject({ reason: promptMeta.reason });
        }
        return new Promise((resolve) => {
          const eventListener = (event) => {
            if (event.event === "ui:prompt:input" && event.payload.modalId === promptMeta.id) {
              resolve(event.payload.input);
              void removeElement(promptMeta.id);
              channel.removeEventListener(eventListener);
            }
          };
          channel.addEventListener(eventListener);
        });
      },
      showToast: async (payload) => {
        const toastMeta = await channel.callRPC(
          "ui:toast:show",
          payload
        );
        if (toastMeta.status === "failed") {
          return Promise.reject({ reason: toastMeta.reason });
        }
      }
    },
    conference: {
      dialOut: async (payload) => {
        let result = void 0;
        const joinedParticipants = /* @__PURE__ */ new Map();
        let detachParticipantJoinedObserver = () => void 0;
        const participantJoinedPromise = new Promise((resolve) => {
          detachParticipantJoinedObserver = participantJoined.add(
            (participant2) => {
              if (!result) {
                joinedParticipants.set(
                  participant2.uuid,
                  participant2
                );
              } else if (result.data.result.includes(
                participant2.uuid
              )) {
                resolve(participant2);
              }
            }
          );
        });
        result = await channel.callRPC("conference:dialOut", payload);
        const participantUuid = result?.data.result[0];
        if (!participantUuid) {
          throw Error("Could not dial out to specified uri");
        }
        const dialedParticipant = joinedParticipants.get(participantUuid) ?? await participantJoinedPromise;
        detachParticipantJoinedObserver();
        const participant = {
          ...dialedParticipant,
          onDisconnect: createSignal({
            allowEmittingWithoutObserver: true
          }),
          disconnect: async () => {
            participants.delete(participantUuid);
            return channel.callRPC("participant:disconnect", {
              participantUuid
            });
          }
        };
        participants.set(participantUuid, participant);
        return participant;
      },
      sendMessage: async (payload) => {
        return await channel.callRPC("conference:sendMessage", payload);
      },
      sendApplicationMessage: async (payload) => {
        return await channel.callRPC(
          "conference:sendApplicationMessage",
          payload
        );
      },
      lock: async (payload) => {
        return await channel.callRPC("conference:lock", payload);
      },
      muteAllGuests: async (payload) => {
        return await channel.callRPC(
          "conference:muteAllGuests",
          payload
        );
      },
      setBandwidth: async (payload) => {
        return await channel.callRPC(
          "conference:setBandwidth",
          payload
        );
      },
      setLayout: async (payload) => {
        return await channel.callRPC("conference:setLayout", payload);
      },
      disconnectAll: async (payload) => {
        return await channel.callRPC(
          "conference:disconnectAll",
          payload
        );
      },
      sendRequest: async (payload) => {
        return await channel.callRPC("conference:sendRequest", payload);
      },
      transfer: async (payload) => {
        return await channel.callRPC("participant:transfer", payload);
      },
      mute: async (payload) => {
        return await channel.callRPC("participant:mute", payload);
      },
      muteVideo: async (payload) => {
        return await channel.callRPC("participant:muteVideo", payload);
      },
      spotlight: async (payload) => {
        return await channel.callRPC("participant:spotlight", payload);
      },
      admit: async (payload) => {
        return await channel.callRPC("participant:admit", payload);
      },
      raiseHand: async (payload) => {
        return await channel.callRPC("participant:raiseHand", payload);
      },
      setRole: async (payload) => {
        return await channel.callRPC("participant:setRole", payload);
      },
      setTextOverlay: async (payload) => {
        return await channel.callRPC(
          "participant:setTextOverlay",
          payload
        );
      },
      sendDTMF: async (payload) => {
        return await channel.callRPC("participant:sendDTMF", payload);
      },
      setParticipantRoom: async (payload) => {
        return await channel.callRPC("participant:setRoom", payload);
      },
      disconnect: async (payload) => {
        const participantUuid = payload.participantUuid;
        participants.delete(participantUuid);
        return await channel.callRPC("participant:disconnect", {
          participantUuid
        });
      }
    },
    events: {
      authenticatedWithConference,
      conferenceStatus,
      connected,
      disconnected,
      me,
      message,
      directMessage,
      applicationMessage,
      transfer,
      stage,
      participants: infinityParticipants,
      participantJoined,
      participantLeft,
      raiseHand,
      presentationConnectionStateChange,
      layoutUpdate
    }
  };
}
export {
  Channel,
  isEvent,
  isFormChecklistElement,
  isFormSelectElement,
  isRPCCall,
  isRPCCallType,
  isRPCReply,
  registerPlugin,
  setLogger
};

import React from 'react';
import cx from 'classnames';

import type {ExtendedSizeModifier} from '../../../types/sizes';
import type {ColorScheme, ShadowModifier} from '../../../types/variants';
import {sizeToPadding} from '../../../utils/helpers';
import {ThemeConsumer} from '../../../themes/ThemeContext';

import styles from './Box.module.scss';

export const Box = React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<'div'> & {
        hasBackground?: boolean;
        bgColor?: string;
        isRounded?: boolean;
        isWithBorder?: boolean;
        isWithShadow?: boolean;
        shadowModifier?: ShadowModifier;
        padding?: ExtendedSizeModifier;
        colorScheme?: ColorScheme;
    }
>(
    (
        {
            hasBackground = true,
            bgColor,
            children,
            className,
            isRounded = true,
            isWithBorder = false,
            isWithShadow = true,
            shadowModifier = 'dark',
            padding = 'none',
            style,
            colorScheme,
            ...props
        },
        ref,
    ) => (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <div
                    className={cx(
                        styles[sizeToPadding(padding)],
                        hasBackground &&
                            styles[colorScheme ?? defaultColorScheme],
                        {
                            [styles.shadowDark]:
                                isWithShadow && shadowModifier === 'dark',
                            [styles.shadowLight]:
                                isWithShadow && shadowModifier === 'light',
                            [styles.rounded]: isRounded,
                            [styles.border]: isWithBorder,
                        },
                        className,
                    )}
                    style={
                        hasBackground && bgColor
                            ? {
                                  ...style,
                                  backgroundColor: bgColor,
                              }
                            : style
                    }
                    ref={ref}
                    {...props}
                >
                    {children}
                </div>
            )}
        </ThemeConsumer>
    ),
);

Box.displayName = 'Box';

export type BoxProps = React.ComponentProps<typeof Box>;

import type {RefObject} from 'react';
import React, {useEffect} from 'react';

import type {IScrollbars} from '@pexip/components';
import {scrollToLastMessage} from '@pexip/media-components';

export interface WithScrollToLastMessageProps {
    shouldScrollToLstMessage?: boolean;
    scrollbarRef: RefObject<IScrollbars>;
}

/**
 * Adds scroll to last message behavior to a `Scrollbars` based component.
 */
export function withScrollToLastMessage<P>(Component: React.ComponentType<P>) {
    return function ScrollToLastMessage(
        props: P & WithScrollToLastMessageProps,
    ) {
        const {shouldScrollToLstMessage, scrollbarRef, ...restProps} =
            props as WithScrollToLastMessageProps;

        useEffect(() => {
            if (scrollbarRef && shouldScrollToLstMessage) {
                scrollToLastMessage(scrollbarRef, false);
            }
        }, [shouldScrollToLstMessage, scrollbarRef]);

        return <Component {...(restProps as P)} scrollbarRef={scrollbarRef} />;
    };
}

import React, {useState, useMemo} from 'react';
import type {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import type {SelectProps} from '@pexip/components';
import {List, IconTypes, Row} from '@pexip/components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import {
    findAudioInputDevices,
    findAudioOutputDevices,
    findVideoInputDevices,
} from '@pexip/media-control';

import {TestId} from '../../../test/testIds';
import {DeviceSelect} from '../DeviceSelect/DeviceSelect.view';
import {MissingDeviceAlert} from '../DeviceSelect/MissingDeviceAlert.view';
import type {DeviceError} from '../../types';

import styles from './DevicesList.module.scss';

export const DevicesList: React.FC<
    {
        videoInput?: MediaDeviceInfoLike;
        audioInput?: MediaDeviceInfoLike;
        audioOutput?: MediaDeviceInfoLike;
        devices: MediaDeviceInfoLike[];
        videoInputError: DeviceError;
        audioInputError: DeviceError;
        onAudioInputChange: (device: MediaDeviceInfoLike) => void;
        onAudioOutputChange: (device: MediaDeviceInfoLike) => void;
        onVideoInputChange: (device: MediaDeviceInfoLike) => void;
        isLoading?: boolean;
        inputAudioTester?: ReactNode;
        outputAudioTester?: ReactNode;
        learnHowToGrantAccessURL?: string;
        setShowHelpVideo?: React.Dispatch<React.SetStateAction<boolean>>;
        showTooltip?: boolean;
    } & Pick<SelectProps, 'sizeModifier'>
> = ({
    audioInput,
    audioInputError,
    audioOutput,
    devices,
    isLoading = false,
    onAudioInputChange,
    onAudioOutputChange,
    onVideoInputChange,
    inputAudioTester,
    outputAudioTester,
    videoInput,
    videoInputError,
    learnHowToGrantAccessURL,
    sizeModifier,
    setShowHelpVideo,
    showTooltip = false,
}) => {
    const {t} = useTranslation();
    const [showErrorTooltip, setShowErrorTooltip] = useState({
        audio: false,
        video: false,
    });

    const videoTooltipClick = (visible: boolean) => {
        setShowErrorTooltip({
            audio: false,
            video: visible,
        });
    };

    const audioTooltipClick = (visible: boolean) => {
        setShowErrorTooltip({
            audio: visible,
            video: false,
        });
    };
    const videoInputs = useMemo(() => {
        return findVideoInputDevices(devices);
    }, [devices]);
    const audioInputs = useMemo(() => {
        return findAudioInputDevices(devices);
    }, [devices]);
    const audioOutputs = useMemo(() => {
        return findAudioOutputDevices(devices);
    }, [devices]);

    const shouldShowMissingVideoAlert =
        videoInputError.deniedDevice ||
        (videoInputError.title && !videoInputs.length);
    const shouldShowMissingAudioAlert =
        audioInputError.deniedDevice ||
        (audioInputError.title && !audioInputs.length);
    const shouldShowAudioOutput =
        audioOutputs.length > 0 && !audioInputError?.title;

    return (
        <List spacing="none" className={styles.deviceListWrapper}>
            {shouldShowMissingVideoAlert ? (
                <MissingDeviceAlert
                    className="mb-1"
                    title={videoInputError.title}
                    tooltip={videoInputError.description}
                    deniedDevice={videoInputError.deniedDevice}
                    learnHowToGrantAccessURL={learnHowToGrantAccessURL}
                    data-testid={TestId.MissingVideoDeviceAlert}
                    setIsTooltipVisible={videoTooltipClick}
                    isTooltipVisible={showErrorTooltip.video}
                    setShowHelpVideo={setShowHelpVideo}
                    showTooltip={showTooltip}
                />
            ) : (
                <DeviceSelect
                    isDisabled={isLoading}
                    label={t(
                        'settings.device-select.select-camera',
                        'Select camera',
                    )}
                    data-testid={TestId.SelectVideoInput}
                    errorText={videoInputError.title}
                    errorTextTestId={TestId.SelectVideoInputErrorText}
                    hasError={!!videoInputError.title}
                    onDeviceChange={onVideoInputChange}
                    iconType={IconTypes.IconVideoOn}
                    mediaDeviceInfoLike={videoInput}
                    devices={videoInputs}
                    sizeModifier={sizeModifier}
                />
            )}
            {shouldShowMissingAudioAlert ? (
                <MissingDeviceAlert
                    className="mb-1"
                    title={audioInputError.title}
                    tooltip={audioInputError.description}
                    deniedDevice={audioInputError.deniedDevice}
                    learnHowToGrantAccessURL={learnHowToGrantAccessURL}
                    data-testid={TestId.MissingAudioDeviceAlert}
                    setIsTooltipVisible={audioTooltipClick}
                    isTooltipVisible={showErrorTooltip.audio}
                    setShowHelpVideo={setShowHelpVideo}
                    showTooltip={showTooltip}
                />
            ) : (
                <DeviceSelect
                    isDisabled={isLoading}
                    label={t(
                        'settings.device-select.select-microphone',
                        'Select microphone',
                    )}
                    data-testid={TestId.SelectAudioInput}
                    errorText={audioInputError.title}
                    errorTextTestId={TestId.SelectAudioInputErrorText}
                    hasError={!!audioInputError.title}
                    onDeviceChange={onAudioInputChange}
                    iconType={IconTypes.IconMicrophoneOn}
                    mediaDeviceInfoLike={audioInput}
                    devices={audioInputs}
                    sizeModifier={sizeModifier}
                />
            )}
            {inputAudioTester && !audioInputError?.title && (
                <Row
                    className={cx(
                        'p-0',
                        'mb-1',
                        shouldShowAudioOutput
                            ? 'justify-content-end'
                            : 'justify-content-space-between',
                    )}
                >
                    {!shouldShowAudioOutput && outputAudioTester}
                    {inputAudioTester}
                </Row>
            )}
            {shouldShowAudioOutput && (
                <>
                    <DeviceSelect
                        isDisabled={isLoading}
                        label={t(
                            'settings.device-select.select-speaker',
                            'Select speaker',
                        )}
                        data-testid={TestId.SelectAudioOutput}
                        onDeviceChange={onAudioOutputChange}
                        iconType={IconTypes.IconSpeakerOn}
                        mediaDeviceInfoLike={audioOutput}
                        devices={audioOutputs}
                        sizeModifier={sizeModifier}
                        className="m-0"
                    />
                    {outputAudioTester && (
                        <Row className={'p-0 mt-1 justify-content-end'}>
                            {outputAudioTester}
                        </Row>
                    )}
                </>
            )}
        </List>
    );
};

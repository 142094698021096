import React, {forwardRef, useEffect, useRef} from 'react';
import type SimpleBarCore from 'simplebar';
import SimpleBar from 'simplebar-react';

import './Scrollbars.scss';

// FIXME: Optional: implement `AutoHideBehavior` as it was in https://kingsora.github.io/OverlayScrollbars/#!documentation/options
export type AutoHideBehavior = 'never' | 'scroll' | 'leave' | 'move';

export const Scrollbars = forwardRef<
    SimpleBar,
    Omit<SimpleBarCore.Options, 'autoHide'> & {
        children?: React.ReactNode;
        autoHide?: AutoHideBehavior;
        onScroll?: (e: Event) => void;
        className?: string;
    }
>(({autoHide, children, className, onScroll, ...props}, ref) => {
    const localRef = useRef<HTMLElement>(null);

    useEffect(() => {
        const localRefCopy = localRef;

        const scroll = (e: Event) => {
            onScroll?.(e);
        };

        localRefCopy.current?.addEventListener('scroll', scroll);

        return () =>
            localRefCopy.current?.removeEventListener('scroll', scroll);
    }, [localRef, onScroll]);

    return (
        <SimpleBar
            className={className}
            ref={ref}
            scrollableNodeProps={{ref: localRef}}
            autoHide={autoHide !== 'never'}
            {...props}
        >
            {children}
        </SimpleBar>
    );
});

Scrollbars.displayName = 'Scrollbars';

type IScrollbars = SimpleBar;

export type {IScrollbars};

import React from 'react';
import cx from 'classnames';

import {Button, DropContent, Icon, IconTypes} from '@pexip/components';
import type {
    DropContentPosition,
    DropContentType,
    ExtendedSizeModifier,
    ButtonModifiersValues,
} from '@pexip/components';

import type {DropContentProps} from '../DropContent/DropContent';

import styles from './KebabMenuButton.module.scss';

export const KebabMenuButton: React.FC<
    React.PropsWithChildren<
        Omit<DropContentProps, 'content'> & {
            className?: string;
            direction?: 'horizontal' | 'vertical';
            isDisabled?: boolean;
            label?: string;
            menuComponent: DropContentType;
            menuPosition?: DropContentPosition;
            buttonSize?: ExtendedSizeModifier;
            buttonModifier?: ButtonModifiersValues;
        }
    >
> = ({
    className,
    direction = 'vertical',
    isDisabled,
    label = 'Toggle the menu',
    menuComponent,
    menuPosition = 'bottomRight',
    buttonSize,
    buttonModifier,
    ...props
}) => {
    return (
        <DropContent
            buttonLabel={label}
            className={styles.wrapper}
            content={menuComponent}
            data-testid="kebab-menu"
            interactiveElement={(props, isContentVisible) => (
                <Button
                    {...props}
                    className={cx(
                        props.className,
                        styles.button,
                        {
                            [styles.active]: isContentVisible,
                        },
                        className,
                    )}
                    isDisabled={isDisabled}
                    variant="transparent"
                    size={buttonSize}
                    modifier={buttonModifier}
                >
                    <Icon
                        source={
                            direction === 'vertical'
                                ? IconTypes.IconMoreVertical
                                : IconTypes.IconMoreHorizontal
                        }
                        className={cx(styles.icon)}
                        data-testid={`kebab-menu-icon-${direction}`}
                    />
                </Button>
            )}
            isDisabled={isDisabled}
            position={menuPosition}
            {...props}
        />
    );
};

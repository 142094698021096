import React, {useCallback} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {
    InteractiveElement,
    SvgImage,
    Text,
    ThemeConsumer,
} from '@pexip/components';
import type {ColorScheme} from '@pexip/components';

import {VideoLayoutOptionValues} from '../../types';
import {TestId} from '../../../test/testIds';

import adaptiveComposition from './svgs/AdaptiveComposition.svg';
import speaker7 from './svgs/Speaker7.svg';
import speaker21 from './svgs/Speaker21.svg';
import speaker221 from './svgs/Speaker221.svg';
import speaker33 from './svgs/Speaker33.svg';
import highlight from './svgs/Highlight.svg';
import equal22 from './svgs/Equal22.svg';
import equal33 from './svgs/Equal33.svg';
import equal44 from './svgs/Equal44.svg';
import equal55 from './svgs/Equal55.svg';

import styles from './LayoutThumbnail.module.scss';

/*
t('settings.layout[adaptive-composition]', 'Adaptive Composition')
t('settings.layout[speaker7]', 'Speaker + 7')
t('settings.layout[speaker21]', 'Speaker + 21')
t('settings.layout[speaker221]', '2 Speaker + 21')
t('settings.layout[speaker33]', 'Speaker + 33')
t('settings.layout[highlight]', 'Highlight')
t('settings.layout[equal22]', '2 x 2')
t('settings.layout[equal33]', '3 x 3')
t('settings.layout[equal44]', '4 x 4')
t('settings.layout[equal55]', '5 x 5')
*/

type LayoutDetails = {
    source: React.ElementType;
    key?:
        | 'adaptive-composition'
        | 'speaker7'
        | 'speaker21'
        | 'speaker33'
        | 'equal22'
        | 'equal33'
        | 'equal44'
        | 'equal55'
        | 'highlight';
};

function getLayoutDetails(layout: VideoLayoutOptionValues): LayoutDetails {
    switch (layout) {
        case VideoLayoutOptionValues.AdaptiveComposition:
            return {
                source: adaptiveComposition,
                key: 'adaptive-composition',
            };
        case VideoLayoutOptionValues.Speaker7:
            return {source: speaker7, key: 'speaker7'};
        case VideoLayoutOptionValues.Speaker21:
            return {source: speaker21, key: 'speaker21'};
        case VideoLayoutOptionValues.Speaker221:
            return {source: speaker221, key: 'speaker21'};
        case VideoLayoutOptionValues.Speaker33:
            return {source: speaker33, key: 'speaker33'};
        case VideoLayoutOptionValues.Equal22:
            return {source: equal22, key: 'equal22'};
        case VideoLayoutOptionValues.Equal33:
            return {source: equal33, key: 'equal33'};
        case VideoLayoutOptionValues.Equal44:
            return {source: equal44, key: 'equal44'};
        case VideoLayoutOptionValues.Equal55:
            return {source: equal55, key: 'equal55'};
        default:
            return {source: highlight, key: 'highlight'};
    }
}

export const LayoutThumbnail: React.FC<
    React.ComponentProps<'svg'> & {
        active?: boolean;
        colorScheme?: ColorScheme;
        layout: VideoLayoutOptionValues;
        selectLayout: (layout: VideoLayoutOptionValues) => void;
    }
> = ({active, className, colorScheme, layout, selectLayout, ...props}) => {
    const {t} = useTranslation();
    const {source, key} = getLayoutDetails(layout);
    const selectThisLayout = useCallback(
        () => selectLayout(layout),
        [layout, selectLayout],
    );
    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <div className={cx(styles.container, className)}>
                    <InteractiveElement
                        onClick={selectThisLayout}
                        className="mb-2"
                        data-testid={`${TestId.Layout}-${layout}`}
                    >
                        <SvgImage
                            source={source}
                            className={cx(
                                styles.thumbnail,
                                styles[colorScheme ?? defaultColorScheme],
                                {
                                    [styles.active]: !!active,
                                },
                            )}
                            {...props}
                        />
                    </InteractiveElement>
                    {key && (
                        <Text
                            className={styles.caption}
                            colorScheme={colorScheme ?? defaultColorScheme}
                            htmlTag="span"
                            data-testid={`${TestId.LayoutLabel}-${layout}`}
                        >
                            {t(`settings.layout[${key}]`)}
                        </Text>
                    )}
                </div>
            )}
        </ThemeConsumer>
    );
};

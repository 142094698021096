// src/Backoff.ts
var Backoff = class {
  attempts = 0;
  ms = 100;
  max = 1e4;
  factor = 2;
  jitter = 0;
  //https://en.m.wikipedia.org/wiki/Thundering_herd_problem
  constructor(opts) {
    if (!opts) {
      return;
    }
    if (opts.min !== void 0) {
      this.ms = opts.min;
    }
    if (opts.max !== void 0) {
      this.max = opts.max;
    }
    if (opts.factor !== void 0) {
      this.factor = opts.factor;
    }
    if (opts.jitter !== void 0 && opts.jitter > 0 && opts.jitter <= 1) {
      this.jitter = opts.jitter;
    }
    this.duration = this.duration.bind(this);
    this.reset = this.reset.bind(this);
  }
  duration() {
    let ms = this.ms * Math.pow(this.factor, this.attempts++);
    if (this.jitter) {
      const rand = Math.random();
      const deviation = Math.floor(rand * this.jitter * ms);
      ms = (Math.floor(rand * 10) & 1) === 0 ? ms - deviation : ms + deviation;
    }
    return Math.min(ms, this.max) | 0;
  }
  promise() {
    return new Promise((res) => setTimeout(res, this.duration()));
  }
  reset() {
    this.attempts = 0;
  }
};

// src/isEmpty.ts
var isEmpty = (obj) => {
  if (Array.isArray(obj)) {
    return obj.length === 0;
  }
  if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).length === 0;
  }
  return !obj;
};

// src/debounce.ts
var debounce = (fn, waitDuration) => {
  let timeoutId;
  const promiseRejects = [];
  const cancel = () => {
    promiseRejects.forEach((reject) => reject());
    promiseRejects.splice(0);
  };
  const debounced = function(...args) {
    clearTimeout(timeoutId);
    const invoke = (resolve) => {
      timeoutId = window.setTimeout(() => {
        const results = fn.apply(this, args);
        resolve(results);
        cancel();
      }, waitDuration);
    };
    return new Promise((resolve, reject) => {
      promiseRejects.push(reject);
      invoke(resolve);
    });
  };
  debounced.cancel = cancel;
  return debounced;
};

// src/identity.ts
var identity = (x) => x;

// src/isDefined.ts
function isDefined(t) {
  if (t === void 0) {
    throw new Error(`typeof t = ${typeof t}, expected value`);
  }
  return t;
}

// src/noop.ts
var noop = () => void 0;

// src/nth.ts
function nthThrows(list, index) {
  return isDefined(list[index >= 0 ? index : list.length + index]);
}
function nthOr(list, index, defaultValue) {
  try {
    return nthThrows(list, index);
  } catch {
    return defaultValue;
  }
}
function firstOr(list, defaultValue) {
  return nthOr(list, 0, defaultValue);
}
function lastOr(list, defaultValue) {
  return nthOr(list, -1, defaultValue);
}

// src/pipe.ts
var pipe = (fn1, ...fns) => {
  const piped = fns.reduce(
    (prevFn, nextFn) => (value) => nextFn(prevFn(value)),
    identity
  );
  return (...args) => piped(fn1(...args));
};

// src/throttle.ts
var throttle = (fn, wait = 0) => {
  let lastFunc;
  let lastRan;
  return function(...args) {
    if (!lastRan) {
      fn.apply(this, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = window.setTimeout(() => {
        if (Date.now() - lastRan >= wait) {
          fn.apply(this, args);
          lastRan = Date.now();
        }
      }, wait - (Date.now() - lastRan));
    }
  };
};

// src/queue.ts
var QUEUE_SIZE = 5;
var QUEUE_DROP_LAST = false;
var createQueue = (maxSize = QUEUE_SIZE, initial = [], dropLast = QUEUE_DROP_LAST) => {
  if (initial.length > maxSize || 0 >= maxSize) {
    throw new Error("InvalidQueueSize");
  }
  let queue = initial;
  const enqueue = (item) => {
    if (queue.length >= maxSize) {
      if (dropLast) {
        queue.splice(-1, 1, item);
      } else {
        queue.shift();
        queue.push(item);
      }
      return queue.length;
    }
    return queue.push(item);
  };
  const enqueueAt = (start, item, replace = false) => {
    if (replace) {
      queue.splice(start, 1, item);
    } else {
      if (queue.length >= maxSize) {
        if (dropLast) {
          queue.splice(-1, 1);
        } else {
          queue.shift();
        }
      }
      queue.splice(start, 0, item);
    }
    return queue.length;
  };
  const dequeue = () => {
    return queue.shift();
  };
  const dequeueAll = () => {
    const tmp = queue;
    queue = [];
    return tmp;
  };
  const empty = () => {
    queue = [];
  };
  return {
    get size() {
      return queue.length;
    },
    get maxSize() {
      return maxSize;
    },
    empty,
    enqueue,
    enqueueAt,
    dequeue,
    dequeueAll
  };
};

// src/asyncQueue.ts
var QUEUE_THROTTLE_IN_MS = 100;
var QUEUE_DELAY_IN_MS = 0;
var createAsyncQueue = ({
  throttleInMS = QUEUE_THROTTLE_IN_MS,
  delayInMS = QUEUE_DELAY_IN_MS,
  size = QUEUE_SIZE,
  dropLast = QUEUE_DROP_LAST
} = {}) => {
  if (size < 1) {
    throw new Error("InvalidQueueSize");
  }
  const queue = createQueue(size, [], dropLast);
  const props = {
    currentJob: void 0,
    processId: 0,
    lastOperatingTime: 0
  };
  const clearTimeout2 = () => {
    if (props.processId) {
      window.clearTimeout(props.processId);
      props.processId = 0;
    }
  };
  const execute = async () => {
    if (queue.size && !props.currentJob) {
      props.currentJob = queue.dequeue();
      if (props.currentJob) {
        const now = performance.now();
        try {
          await props.currentJob();
        } catch (error) {
          if (error instanceof Error) {
            throw error;
          }
        } finally {
          const delay = Math.min(performance.now() - now, delayInMS);
          props.currentJob = void 0;
          if (queue.size > 0) {
            await new Promise((resolve, reject) => {
              props.processId = window.setTimeout(() => {
                execute().then(resolve).catch(reject);
              }, delay);
            });
          }
        }
      }
    }
  };
  return {
    get busy() {
      return !!props.currentJob;
    },
    execute,
    enqueue: (job, runImmediately = true) => {
      clearTimeout2();
      if (throttleInMS > 0) {
        const now = window.performance.now();
        const diff = now - props.lastOperatingTime;
        if (diff <= throttleInMS) {
          queue.enqueueAt(-1, job, true);
        } else {
          props.lastOperatingTime = now;
          queue.enqueue(job);
        }
      } else {
        queue.enqueue(job);
      }
      if (runImmediately) {
        void execute();
      }
    }
  };
};

// src/hasOwnProperty.ts
var hasOwnProperty = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);

// src/cancellablePromise.ts
var cancellablePromise = (callback, cleanup) => {
  let cancelResolve;
  const wrap = (...args) => {
    let cancelled = false;
    const cancelPromise = new Promise((resolve) => {
      cancelResolve = resolve;
    });
    return Promise.race([
      callback(...args).then((result) => {
        if (cancelled && cleanup) {
          return cleanup(result);
        }
        return result;
      }),
      cancelPromise.then(() => {
        cancelled = true;
      })
    ]);
  };
  const cancel = () => {
    cancelResolve?.();
  };
  return [wrap, cancel];
};

// src/endsWithAnyOf.ts
var endsWithAnyOf = (subject, possibleMatches) => (subject === "" ? possibleMatches : possibleMatches.filter((possibleMatch) => possibleMatch !== "")).some((possibleMatch) => subject.endsWith(possibleMatch));

// src/getBaseURI.ts
var getBaseURI = (node = document) => new URL(node.baseURI).pathname;
export {
  Backoff,
  QUEUE_DELAY_IN_MS,
  QUEUE_DROP_LAST,
  QUEUE_SIZE,
  QUEUE_THROTTLE_IN_MS,
  cancellablePromise,
  createAsyncQueue,
  createQueue,
  debounce,
  endsWithAnyOf,
  firstOr,
  getBaseURI,
  hasOwnProperty,
  identity,
  isDefined,
  isEmpty,
  lastOr,
  noop,
  nthOr,
  nthThrows,
  pipe,
  throttle
};

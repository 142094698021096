import {isDeviceGranted} from '@pexip/media-control';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import type {SelectProps} from '@pexip/components';

export interface MapDevicesToOptions {
    devices: MediaDeviceInfoLike[];
    permissionLabel: string;
    label: string;
    selectedDeviceId: string | undefined;
}

/**
 * Map MediaDeviceInfo to SelectProps['options']
 */
export const mapDevicesToOptions = ({
    devices,
    permissionLabel,
    label,
    selectedDeviceId,
}: MapDevicesToOptions): SelectProps['options'] => {
    if (!devices.some(isDeviceGranted)) {
        return [{label: permissionLabel, id: ''}];
    }
    const deviceOptions = devices.map(device => ({
        id: device.deviceId,
        label: device.label,
    }));
    if (!selectedDeviceId) {
        return [{label, id: ''}, ...deviceOptions];
    }
    return deviceOptions;
};

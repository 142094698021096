import React from 'react';

import {JoinMeetingButton} from '@pexip/media-components';

import styles from './StepByStepJoinMeetingButton.module.scss';

export const StepByStepJoinMeetingButton: React.FC<{
    isDisabled: boolean;
    onClick: () => void;
}> = ({isDisabled, onClick, ...props}) => (
    <JoinMeetingButton
        isDisabled={isDisabled}
        onClick={onClick}
        spacing="large"
        className={styles.joinMeetingButtonWrapper}
        {...props}
    />
);

export type StepByStepJoinMeetingButtonProps = React.ComponentProps<
    typeof StepByStepJoinMeetingButton
>;

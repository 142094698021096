import React from 'react';

import {BrandedFullSizeWindow} from '../../viewModels/BrandedFullSizeWindow.viewModel';
import {MobileGetStarted as MobileGetStartedViewModel} from '../../viewModels/MobileGetStarted.viewModel';
import {Header} from '../../viewModels/Header.viewModel';

export const MobileGetStarted: React.FC = () => (
    <BrandedFullSizeWindow
        padding="none"
        background="image"
        flexDirection="column"
        justifyContent="flexStart"
    >
        <Header />
        <MobileGetStartedViewModel />
    </BrandedFullSizeWindow>
);

export type MobileGetStartedProps = React.ComponentProps<
    typeof MobileGetStarted
>;

import type {RPCCall, Channel} from '@pexip/plugin-api';

import {infinityService} from '../services/InfinityClient.service';

import type {PluginContext} from './types';

export const handleInfinityCall = ({
    data,
    channel,
    chanId,
    setPluginsElements,
}: {
    data: RPCCall;
    channel: Channel;
    chanId: string;
    setPluginsElements: (value: React.SetStateAction<PluginContext>) => void;
}) => {
    if (data.rpc === 'conference:dialOut') {
        void infinityService.dial(data.payload).then(result => {
            if (!result || result.data.result.length === 0) {
                return;
            }
            setPluginsElements(pluginsElements => {
                const pluginElements = pluginsElements[chanId];

                const participant = {
                    ...data.payload,
                    participantUuids: result.data.result,
                    id: data.id,
                    chanId: data.chanId,
                };
                if (!pluginElements) {
                    pluginsElements[chanId] = {
                        buttons: [],
                        forms: [],
                        participants: [participant],
                        prompts: [],
                    };
                } else if (
                    pluginElements.participants.find(
                        participant => participant.id === data.id,
                    ) === undefined
                ) {
                    pluginElements.participants.push(participant);
                    delete pluginsElements[chanId];
                    return {
                        ...pluginsElements,
                        [chanId]: pluginElements,
                    };
                }
                return pluginsElements;
            });
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:sendMessage') {
        void infinityService.sendMessage(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:sendApplicationMessage') {
        void infinityService
            .sendApplicationMessage(data.payload)
            .then(result => {
                channel.replyRPC({
                    rpc: data.rpc,
                    replyTo: data.id,
                    payload: result,
                });
            });
    } else if (data.rpc === 'conference:lock') {
        void infinityService.lock(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:muteAllGuests') {
        void infinityService.muteAllGuests(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:setBandwidth') {
        infinityService.setBandwidth(data.payload);
        channel.replyRPC({
            rpc: data.rpc,
            replyTo: data.id,
            payload: undefined,
        });
    } else if (data.rpc === 'conference:setLayout') {
        void infinityService.setLayout(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:disconnectAll') {
        void infinityService.disconnectAll(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:sendRequest') {
        void infinityService
            .sendConferenceRequest(data.payload)
            .then(result => {
                channel.replyRPC({
                    rpc: data.rpc,
                    replyTo: data.id,
                    payload: result,
                });
            });
    } else if (data.rpc === 'participant:transfer') {
        void infinityService.transfer(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:mute') {
        void infinityService.mute(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:muteVideo') {
        void infinityService.muteVideo(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:spotlight') {
        void infinityService.spotlight(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:admit') {
        void infinityService.admit(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:raiseHand') {
        void infinityService.raiseHand(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:setRole') {
        void infinityService.setRole(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:setTextOverlay') {
        void infinityService.setTextOverlay(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:sendDTMF') {
        void infinityService.sendDTMF(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:setRoom') {
        void infinityService.setParticipantRoom(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:disconnect') {
        void infinityService.kick(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    }
};

import React, {
    createContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import type {RPCCall, SelectElement} from '@pexip/plugin-api';
import {isRPCCall, Channel} from '@pexip/plugin-api';
import {IconTypes, notificationToastSignal} from '@pexip/components';

import {useBranding} from '../branding/Context';
import {logger} from '../logger';
import {infinityClientSignals} from '../signals/InfinityClient.signals';
import {callSignals} from '../signals/Call.signals';

import type {PluginContext} from './types';
import {handleInfinityCall} from './infinityCalls';

export const Plugins = createContext<PluginContext>({});

export const Channels = createContext<
    React.MutableRefObject<Map<string, Channel>> | undefined
>(undefined);

export const PluginManager: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const plugins = useBranding('plugins');
    const activePlugins = useMemo(
        () =>
            (plugins ?? []).map(plugin => (
                // eslint-disable-next-line jsx-a11y/iframe-has-title -- the plugins are only logical and not visible
                <iframe
                    key={`plugin:${plugin.src}`}
                    sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                    src={plugin.src}
                    aria-hidden
                />
            )),
        [plugins],
    );
    const channels = useRef(new Map<string, Channel>());
    const [pluginsElements, setPluginsElements] = useState<PluginContext>({});

    useEffect(() => {
        const detachSignals = [
            infinityClientSignals.onParticipants.add(participants => {
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: 'event:participants',
                        payload: participants,
                    });
                });
            }),
            infinityClientSignals.onParticipantLeft.add(leftParticipant => {
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: 'event:participantLeft',
                        payload: leftParticipant,
                    });
                });
                Object.entries(pluginsElements).forEach(
                    ([chanId, elements]) => {
                        elements.participants
                            .flatMap(
                                participant => participant.participantUuids,
                            )
                            .forEach(uuid => {
                                if (leftParticipant.uuid === uuid) {
                                    channels.current.get(chanId)?.sendEvent({
                                        event: 'participant:disconnected',
                                        payload: {participantUuid: uuid},
                                    });
                                }
                            });
                    },
                );
            }),
            infinityClientSignals.onParticipantJoined.add(joinedParticipant => {
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: 'event:participantJoined',
                        payload: joinedParticipant,
                    });
                });
            }),
            infinityClientSignals.onRaiseHand.add(participant => {
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: 'event:raiseHand',
                        payload: participant,
                    });
                });
            }),
            infinityClientSignals.onConferenceStatus.add(conferenceStatus => {
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: 'event:conferenceStatus',
                        payload: conferenceStatus,
                    });
                });
            }),
            infinityClientSignals.onAuthenticatedWithConference.add(context => {
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: 'event:conference:authenticated',
                        payload: context,
                    });
                });
            }),
            infinityClientSignals.onConnected.add(connected => {
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: 'event:connected',
                        payload: connected,
                    });
                });
            }),
            infinityClientSignals.onDisconnected.add(disconnected => {
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: 'event:disconnected',
                        payload: disconnected,
                    });
                });
            }),
            infinityClientSignals.onMe.add(me => {
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: 'event:me',
                        payload: me,
                    });
                });
            }),
            infinityClientSignals.onMessage.add(msg => {
                const {direct, ...payload} = msg;
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: direct ? 'event:directMessage' : 'event:message',
                        payload,
                    });
                });
            }),
            infinityClientSignals.onApplicationMessage.add(
                applicationMessage => {
                    channels.current.forEach(channel => {
                        channel.sendEvent({
                            event: 'event:applicationMessage',
                            payload: applicationMessage,
                        });
                    });
                },
            ),
            infinityClientSignals.onTransfer.add(({alias}) => {
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: 'event:transfer',
                        payload: {alias},
                    });
                });
            }),
            infinityClientSignals.onStage.add(stage => {
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: 'event:stage',
                        payload: stage,
                    });
                });
            }),
            callSignals.onPresentationConnectionChange.add(state => {
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: 'event:presentationConnectionStateChange',
                        payload: state,
                    });
                });
            }),
            infinityClientSignals.onLayoutUpdate.add(layout => {
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: 'event:layoutUpdate',
                        payload: layout,
                    });
                });
            }),
        ];

        return () => {
            detachSignals.forEach(detachSignal => detachSignal());
        };
    });

    useEffect(() => {
        const onMessage = ({source, data}: MessageEvent<RPCCall>) => {
            if (!isRPCCall(data)) {
                return;
            }
            const chanId = data.chanId;
            if (data.rpc === 'syn') {
                logger.debug({data}, 'Registering new plugin');
                const newChannel = new Channel(source as Window, chanId);
                channels.current.set(chanId, newChannel);
                newChannel.replyRPC({
                    rpc: data.rpc,
                    replyTo: data.id,
                    payload: {ack: true},
                });
                return;
            }

            const channel = channels.current.get(chanId);
            if (channel) {
                logger.debug({data}, 'Emit reply for plugin message');
                switch (data.rpc) {
                    case 'ui:button:add': {
                        if (
                            data.payload.position === 'toolbar' &&
                            typeof data.payload.icon === 'string' &&
                            IconTypes[
                                data.payload.icon as keyof typeof IconTypes
                            ] === undefined
                        ) {
                            channel.replyRPC({
                                rpc: data.rpc,
                                replyTo: data.id,
                                payload: {
                                    status: 'failed',
                                    reason: 'Invalid Icon name',
                                    id: data.id,
                                },
                            });
                            return;
                        }
                        setPluginsElements(pluginsElements => {
                            const pluginElements = pluginsElements[chanId];
                            const button = {
                                ...data.payload,
                                id: data.id,
                                chanId: data.chanId,
                            };
                            if (!pluginElements) {
                                pluginsElements[chanId] = {
                                    buttons: [button],
                                    participants: [],
                                    forms: [],
                                    prompts: [],
                                };
                            } else if (
                                pluginElements.buttons.find(
                                    btn => btn.id === data.id,
                                ) === undefined
                            ) {
                                pluginElements.buttons.push(button);
                                delete pluginsElements[chanId];
                                return {
                                    ...pluginsElements,
                                    [chanId]: pluginElements,
                                };
                            }
                            return pluginsElements;
                        });
                        channel.replyRPC({
                            rpc: data.rpc,
                            replyTo: data.id,
                            payload: {
                                status: 'ok',
                                id: data.id,
                                data: undefined,
                            },
                        });
                        break;
                    }
                    case 'ui:button:update': {
                        if (
                            data.payload.position === 'toolbar' &&
                            typeof data.payload.icon === 'string' &&
                            IconTypes[
                                data.payload.icon as keyof typeof IconTypes
                            ] === undefined
                        ) {
                            channel.replyRPC({
                                rpc: data.rpc,
                                replyTo: data.id,
                                payload: {
                                    status: 'failed',
                                    reason: 'Invalid Icon name',
                                    id: data.id,
                                },
                            });
                            return;
                        }
                        setPluginsElements(pluginsElements => {
                            const pluginElements = pluginsElements[chanId];
                            if (!pluginElements) {
                                return pluginsElements;
                            }
                            const btnIndex = pluginElements.buttons.findIndex(
                                btn => btn.id === data.payload.id,
                            );
                            if (
                                btnIndex > -1 &&
                                typeof data.payload.id === 'string'
                            ) {
                                pluginElements.buttons[btnIndex] = {
                                    ...data.payload,
                                    id: data.payload.id,
                                    chanId: data.chanId,
                                };
                            }
                            delete pluginsElements[chanId];
                            return {
                                ...pluginsElements,
                                [chanId]: pluginElements,
                            };
                        });
                        break;
                    }
                    case 'ui:form:open':
                        if (
                            Object.values(data.payload.form.elements)
                                .filter(element => element.type === 'select')
                                .find(
                                    element =>
                                        (element as SelectElement).options
                                            .length === 0,
                                )
                        ) {
                            channel.replyRPC({
                                rpc: data.rpc,
                                replyTo: data.id,
                                payload: {
                                    status: 'failed',
                                    reason: 'The select elements in the form must have at least one option',
                                    id: data.id,
                                },
                            });
                            return;
                        }
                        setPluginsElements(pluginsElements => {
                            const pluginElements = pluginsElements[chanId];
                            const form = {
                                ...data.payload,
                                id: data.id,
                                chanId: data.chanId,
                            };
                            if (!pluginElements) {
                                pluginsElements[chanId] = {
                                    buttons: [],
                                    participants: [],
                                    forms: [form],
                                    prompts: [],
                                };
                            } else if (
                                pluginElements.forms.find(
                                    form => form.id === data.id,
                                ) === undefined
                            ) {
                                pluginElements.forms.push(form);
                                delete pluginsElements[chanId];
                                return {
                                    ...pluginsElements,
                                    [chanId]: pluginElements,
                                };
                            }
                            return pluginsElements;
                        });
                        channel.replyRPC({
                            rpc: data.rpc,
                            replyTo: data.id,
                            payload: {
                                status: 'ok',
                                id: data.id,
                                data: undefined,
                            },
                        });
                        break;
                    case 'ui:toast:show': {
                        notificationToastSignal.emit([
                            {message: data.payload.message},
                        ]);
                        break;
                    }
                    case 'ui:prompt:open': {
                        setPluginsElements(pluginsElements => {
                            const pluginElements = pluginsElements[chanId];
                            const prompt = {
                                ...data.payload,
                                id: data.id,
                                chanId: data.chanId,
                            };
                            if (!pluginElements) {
                                pluginsElements[chanId] = {
                                    buttons: [],
                                    forms: [],
                                    participants: [],
                                    prompts: [prompt],
                                };
                            } else if (
                                pluginElements.prompts.find(
                                    prompt => prompt.id === data.id,
                                ) === undefined
                            ) {
                                pluginElements.prompts.push(prompt);
                                delete pluginsElements[chanId];
                                return {
                                    ...pluginsElements,
                                    [chanId]: pluginElements,
                                };
                            }
                            return pluginsElements;
                        });
                        channel.replyRPC({
                            rpc: data.rpc,
                            replyTo: data.id,
                            payload: {
                                status: 'ok',
                                id: data.id,
                                data: undefined,
                            },
                        });
                        break;
                    }
                    case 'ui:removeElement': {
                        setPluginsElements(pluginsElements => {
                            const pluginElements = pluginsElements[chanId];
                            if (!pluginElements) {
                                return pluginsElements;
                            }
                            Object.values(pluginElements).forEach(group => {
                                const elIndex = group.findIndex(
                                    el => el.id === data.payload.id,
                                );
                                if (elIndex > -1) {
                                    group = group.splice(elIndex, 1);
                                }
                                pluginsElements = {
                                    ...pluginsElements,
                                    [chanId]: pluginElements,
                                };
                            });
                            return pluginsElements;
                        });
                        channel.replyRPC({
                            rpc: data.rpc,
                            replyTo: data.id,
                            payload: {
                                status: 'ok',
                                id: data.id,
                                data: undefined,
                            },
                        });
                        break;
                    }
                    case 'conference:dialOut':
                    case 'conference:sendMessage':
                    case 'conference:sendApplicationMessage':
                    case 'conference:lock':
                    case 'conference:muteAllGuests':
                    case 'conference:setBandwidth':
                    case 'conference:setLayout':
                    case 'conference:disconnectAll':
                    case 'conference:sendRequest':
                    case 'participant:transfer':
                    case 'participant:mute':
                    case 'participant:muteVideo':
                    case 'participant:disconnect':
                    case 'participant:spotlight':
                    case 'participant:admit':
                    case 'participant:setRole':
                    case 'participant:raiseHand':
                    case 'participant:setTextOverlay':
                    case 'participant:sendDTMF':
                    case 'participant:setRoom':
                        handleInfinityCall({
                            data,
                            channel,
                            chanId,
                            setPluginsElements,
                        });
                        break;
                }
            }
        };
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, []);

    return (
        <>
            <Channels.Provider value={channels}>
                <Plugins.Provider value={pluginsElements}>
                    {children}
                </Plugins.Provider>
            </Channels.Provider>
            {activePlugins}
        </>
    );
};
